import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useNavigate, } from 'react-router-dom'
import { GreenButton, GreenOutButton, RedOutButton, } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { CrossRedIcon, DoubleCheckIcon, Spinner, TestTopBackArrowIcon, TestTopDotIcon, } from '../../components/icons/Icon'
import styles from "./Dealer.module.css"
import Modal from '../../components/Modal'
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper'
import { ArroIcon, WhatAppIcon, } from '../../components/icons/Icon2'
import SlidingFilter from '../../components/updatedComponents/Dealers/SlidingFilter'
import DealerStatusCard from '../../components/updatedComponents/Dealers/DealerStatusCard'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import DropDown from "../../components/updatedComponents/FormComponents/DropDown"
import AdduserModel from '../../components/updatedComponents/models/AdduserModel'
import FilterCard from '../../components/updatedComponents/MobilCard/FilterCard'
import DealerCartMobil from '../../components/updatedComponents/MobilCard/DealerCartMobil'
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation"
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import fetchData from '../../APIsControll/apiControll'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
const DealerInfoCard = lazy(() => import("../../components/updatedComponents/Dealers/DealerInfoCard"));
const TempletSent = lazy(() => import("../../components/updatedComponents/Dealers/TempletSent"));
const Dealers = () => {
  const { mode } = useAuth()
  const { show } = useToast()
  const navigate = useNavigate();
  // const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
  const [page, setPage] = useState(1)

  const [dealerData, setDealerData] = useState([])
  const [dealerNumber, setDealerNumber] = useState([])
  const { managerId } = useAuth()
  const [loading, setLoading] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);
  const [showMessengerModal, setShowMessengerModal] = useState(false)
  const [sendTemplateModal, setSendTemplateModal] = useState(false)
  const [sendTemplateText, setSendTemplateText] = useState('')
  const [allTemplate, setAllTemplate] = useState([])
  const [singleTemplate, setSingleTemplate] = useState({})
  const [messengerTemplate, setMessengerTemplate] = useState()
  const [dealerNames, setDealerNames] = useState([]);
  const [showDealerNames, setShowDealerNames] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState([]);
  // const [headerType, setHeaderType] = useState('document');
  // const [templateData, setTemplateData] = useState();
  const [templateError, setTemplateError] = useState();
  const [templateDataLoading, setTemplateDataLoading] = useState(false);
  const [sendStatus, setSendStatus] = useState({});
  const [templatePage, setTemplatePage] = useState(1)
  const [totalPage, setTotalPage] = useState(3)
  const [optionshow, setOptionshow] = useState(false)
  const [allfilter, setAllFilter] = useState([])

  const [page1, setPage1] = useState(1);
  const [pages1, setPages1] = useState(1);
  const [loadingis, setloadingis] = useState(false)



  const sliceData = (data, page) => {
    const pageSize = dealerNumber.length;

    if (page === 1) {
      // return data.slice(0, pageSize);
    } else if (page === 2) {
      return data.slice(pageSize, pageSize * 2);
    } else if (page === 3) {
      return data.slice(pageSize * 2, pageSize * 3);
    } else if (page === 4) {
      return data.slice(pageSize * 3);
    } else {
      return [];
    }
  };

  const slicedData = sliceData(dealerNumber, templatePage);



  






  // useEffect(() => {
  
  //   // if (managerId) {
  //   //   setloadingis(true);
  //   //   const myHeaders = new Headers();
  //   //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //   //   myHeaders.append("Content-Type", "application/json");

  //   //   const raw = JSON.stringify({
  //   //     "managerId": managerId
  //   //   });

  //   //   const requestOptions = {
  //   //     method: "POST",
  //   //     headers: myHeaders,
  //   //     body: raw,
  //   //     redirect: "follow"
  //   //   };

  //   //   // fetch(process.env.REACT_APP_URL + `wallikonChat/templateList?page=${page1}&&limit=${pages1}`, requestOptions)
  //   //   fetch(process.env.REACT_APP_URL + `wallikonChat/templateList?page=1&&limit=10`, requestOptions)
  //   //     .then((response) => response.json())
  //   //     .then((result) => {
  //   //       if (result.status === 'success') {
  //   //         setAllTemplate(result.data);
  //   //         setAllTemplate([...allTemplate, ...result.data]);
  //   //         // setPage1(result.page);
  //   //         setPages1(result.pages);

  //   //       }
  //   //     })
  //   //     .catch((error) => console.error(error))
  //   //     .finally(() => {
  //   //       setloadingis(false);
  //   //     })
  //   // }
  // }, [page1, managerId])


  const handleSelectAllNumber = () => {
    setSelectedNumber(dealerNumber.map(number => number.whatsapp));
  };


  useEffect(() => {
    window.addEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
    window.removeEventListener('resize', () => {
      return setWidth(window.innerWidth);
    })
  }, [])

  const [searchDealerData, setSearchDealerData] = useState({
    searchDealerName: '',
    sortByStatus: '',
    sortKey: '',
    dateFilter: '',
    district: '',
    status: '',
  })
  const [pages, setPages] = useState(1);


  // function debounce(func, delay) {
  //   let timeoutId;
  //   return (...args) => {
  //     if (timeoutId) clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => func(...args), delay);
  //   };
  // }


  useEffect(() => {
    if (managerId) {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        managerId,
        keyword: searchDealerData.searchDealerName,
        sortKey: searchDealerData.sortByStatus,
        dateFilter: searchDealerData.dateFilter,
        status: searchDealerData.status
      });
      // if (searchDealerData) {
      //   setPage(1)
      // }

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + `kyc/dealers?page=${page}&limit=${10}`, requestOptions)
        // fetch("http://localhost:5052/" + `kyc/dealers?page=${page}&limit=${5}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === "success") {
            if (result.data) {
              setLoading(false);
              setAllFilter(result.statusCounts);
              setDealerData((prevDealerData) => {
                let newData = result.data;
                if (searchDealerData.dateFilter || searchDealerData.searchDealerName || searchDealerData.sortByStatus || searchDealerData.status) {
                  return newData;
                }
                const mergedData = [...prevDealerData, ...newData];
                const uniqueData = mergedData.filter(
                  (item, index, self) => index === self.findIndex((t) => t.dealerId === item.dealerId)
                );
                return uniqueData;
              });
              setPages(result.pages || 1);
            }
          }
        })
        .catch(error => {
          // console.log('error', error);
          setLoading(false);
        });
    }
  }, [managerId, page, searchDealerData]);


  const dealerNumberData = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "managerId": managerId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_URL + "kyc/dealerWhatsapp", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setDealerNumber(result.dealerWhatsapp)
        }
      })
      .catch((error) => console.error(error));
  }
  // console.log(dealersData);

  useEffect(() => {
    if (managerId) {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(process.env.REACT_APP_URL + "kyc/dealerWhatsapp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setDealerNumber(result.dealerWhatsapp)
          }
        })
        .catch((error) => console.error(error));
    }
  }, [managerId])

  // console.log('selectedNumber', selectedNumber);
  // console.log('dealerNumber', dealerNumber);
  useEffect(() => {
    if (dealerData.dealerId) {
      navigate("/dealers/" + dealerData.dealerId)
    }
  }, [dealerData.dealerId, navigate])

  const sendMessage = async () => {
    /* by sir  */
    // if (singleTemplate.headerType === 'document') {
    //   setTemplateDataLoading(true)
    //   const myHeaders = new Headers();
    //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //   myHeaders.append("Content-Type", "application/json");

    //   const raw = JSON.stringify({
    //     "managerId": managerId,
    //     "dealerPhoneNumber": selectedNumber,
    //     "templateName": singleTemplate.templateName,
    //     "languageCode": singleTemplate.language,
    //     "headerType": singleTemplate.headerType,
    //     "headerData": singleTemplate.header,
    //     "documentFilename": singleTemplate.documentName
    //   });

    //   const requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: "follow",
    //   };

    //   fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, requestOptions)
    //     .then((response) => response.json())
    //     .then(result => {
    //       if (result.status === 'failed') {
    //         alert(result.msg)
    //         setTemplateError(result.msg)
    //       }
    //       setTemplateDataLoading(false)
    //       processResult(result.results)
    //       setSendStatus(result.response)
    //       if (templatePage < totalPage)
    //         setTemplatePage(prevPage => prevPage + 1);
    //       setTotalPage(result.response.totalPages)
    //     })
    //     .catch((error) => console.error(error));
    // }


    if (singleTemplate.headerType === 'document') {
      setTemplateDataLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "headerId": singleTemplate.headerId,
        "documentFilename": singleTemplate.documentName
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const controller = new AbortController();
      const signal = controller.signal;

      // 5 minutes timeout (300000 milliseconds)
      const timeoutId = setTimeout(() => controller.abort(), 300000);

      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, {
        ...requestOptions,
        signal: signal
      })
        .then((response) => response.json())
        .then(result => {
          clearTimeout(timeoutId); // Clear the timeout when the request is complete

          if (result.status === 'failed') {
            alert(result.msg);
            setTemplateError(result.msg);
          }

          setTemplateDataLoading(false);
          processResult(result.results);
          setSendStatus(result.response);

          if (templatePage < totalPage) {
            setTemplatePage(prevPage => prevPage + 1);
          }

          setTotalPage(result.response.totalPages);
        })
        .catch((error) => {
          clearTimeout(timeoutId); // Clear the timeout on error
          if (error.name === 'AbortError') {
            console.error("Request timeout, it took too long");
          } else {
            console.error(error);
          }
        });
    }

    else if (singleTemplate.headerType === 'video') {
      setTemplateDataLoading(true)
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "headerId": singleTemplate.headerId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };


      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, requestOptions)
        .then((response) => response.json())
        .then(result => {
          if (result.status === 'failed') {
            alert(result.msg)
            setTemplateError(result.msg)
          }
          setTemplateDataLoading(false)
          processResult(result.results)
          setSendStatus(result.response)
          if (templatePage < totalPage)
            setTemplatePage(prevPage => prevPage + 1);
          setTotalPage(result.response.totalPages)
        })
        .catch((error) => console.error(error));
    }

    else if (singleTemplate.headerType === 'image') {
      setTemplateDataLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "headerId": singleTemplate.headerId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const controller = new AbortController();
      const signal = controller.signal;

      // 5 minutes timeout (300000 milliseconds)
      const timeoutId = setTimeout(() => controller.abort(), 300000);

      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate?page=" + templatePage, {
        ...requestOptions,
        signal: signal
      })
        .then((response) => response.json())
        .then(result => {
          clearTimeout(timeoutId); // Clear the timeout when the request is complete

          if (result.status === 'failed') {
            alert(result.msg);
            setTemplateError(result.msg);
          }

          setTemplateDataLoading(false);
          processResult(result.results);
          setSendStatus(result.response);

          if (templatePage < totalPage) {
            setTemplatePage(prevPage => prevPage + 1);
          }

          setTotalPage(result.response.totalPages);
        })
        .catch((error) => {
          clearTimeout(timeoutId); // Clear the timeout on error
          if (error.name === 'AbortError') {
            console.error("Request timeout, it took too long");
          } else {
            console.error(error);
          }
        });
    }

    else if (singleTemplate.headerType === 'location') {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "managerId": managerId,
        "dealerPhoneNumber": selectedNumber,
        "templateName": singleTemplate.templateName,
        "languageCode": singleTemplate.language,
        "headerType": singleTemplate.headerType,
        "headerId": singleTemplate.headerId,
        "latitude": "26.8428008",
        "longitude": "80.9359821",
        "locationName": "Wallicon Private Limited",
        "locationAddress": "Wallicon, Burlington, Aminabad Road, Lucknow",
        "buttonIndex": "1",
        "buttonDataPayload": "https://dealer.wallicon.in/payment-order/Wal54LhjZqiN14"
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const controller = new AbortController();
      const signal = controller.signal;

      // 5 minutes timeout (300000 milliseconds)
      const timeoutId = setTimeout(() => controller.abort(), 300000);

      fetch(process.env.REACT_APP_URL + "wallikonChat/sendTemplate", {
        ...requestOptions,
        signal: signal
      })
        .then((response) => response.json())
        .then(result => {
          clearTimeout(timeoutId); // Clear the timeout when the request is complete
          setTemplateDataLoading(false);
          processResult(result.results);
        })
        .catch((error) => {
          clearTimeout(timeoutId); // Clear the timeout on error
          if (error.name === 'AbortError') {
            console.error("Request timeout, it took too long");
          } else {
            console.error(error);
          }
        });
    }

    else {
      alert('Please select file type')
    }
  };

  const processResult = (results) => {
    const newStatusArray = slicedData.map(dealer => {
      const foundResult = results.find(result => {
        const input = result?.data?.contacts?.[0]?.input || result?.data?.to;
        return input && (input.toString() === dealer.whatsapp.toString());
      });

      if (foundResult) {
        return {
          whatsapp: dealer.whatsapp,
          name: dealer.name,
          status: foundResult.status
        };
      }
      else {
        return {
          whatsapp: dealer.whatsapp,
          name: dealer.name,
          status: 'failed'
        };
      }
    });

    // You can now use this array as needed
    setDealerNames(newStatusArray)
    handleAddTemplateRecord(newStatusArray)
    // console.log(newStatusArray);
  };

  const handleAddTemplateRecord = (statusInfo) => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "managerId": managerId,
      "templateName": messengerTemplate,
      "statusInfo": statusInfo
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(process.env.REACT_APP_URL + "wallikonChat/addUpTempstatus", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'success') {
          // setTemplateData(result.data)
        }
      })
      .catch((error) => console.error(error));
  }

  // console.log("dealerNames");


  // const sendMessage = async () => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", "Bearer EABljmRig1VcBO8EokWI5QUZBu8tsUfEsZADtERQitatMhA3WOn7ajgrcRuQ0fQbZA5uAiP5RXamzt1BZCaWJkGGBZBobhZAa91WB8BCORGvJwvZBuDSBccs3ZAWSDPH5STTYyThu0OYbGKE6979SuzCreXjRpZBR5pFZBT6sFeLzUE4yKQZA8ZC5jLay14ZBkH9PGSkA80HJSZC4bVJTS3OsgB");
  //   myHeaders.append("Cookie", "ps_l=1; ps_n=1");
  //   const newWaIds = []
  //   const templatePayload = {
  //     "messaging_product": "whatsapp",
  //     "type": "template",
  //     "template": {
  //       "name": messengerTemplate,
  //       "language": {
  //         "code": "en"
  //       },
  //       "components": [
  //         {
  //           "type": "header",
  //           "parameters": [
  //             {
  //               "type": "document",
  //               "document": {
  //                 "link": "https://dealer.wallicon.in/assets/fauna_doc.pdf",
  //                 "filename": "FAUNA WALLPAPER CATALOG WALLIKON 2024"
  //               }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   };
  //   const newDealerNames = [];
  //   for (const number of selectedNumber) {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: JSON.stringify({
  //         ...templatePayload,
  //         "to": number.whatsapp
  //       }),
  //       redirect: 'follow'
  //     };

  //     try {
  //       const response = await fetch("https://graph.facebook.com/v19.0/161149970425453/messages", requestOptions);
  //       const result = await response.json();

  //       if (result.messages && result.messages.length > 0) {
  //         const waId = result.contacts[0].wa_id;
  //         newWaIds.push(waId);

  //         // Find the corresponding dealerNumber object
  //         const matchingDealerNumber = dealerNumber.find(dealer => dealer.whatsapp === number.whatsapp);

  //         // If a match is found, store the name in newDealerNames array
  //         if (matchingDealerNumber) {
  //           newDealerNames.push(matchingDealerNumber.name);

  //           // Update the state with the newDealerNames array
  //           setDealerNames(prevDealerNames => [...prevDealerNames, matchingDealerNumber.name]);
  //         }
  //       }
  //     } catch (error) {
  //       console.log('error', error);
  //     }
  //   }

  //   // Here, you can still set the state with the final array if needed
  //   // setDealerNames(newDealerNames);
  // };



  // const handleChange = (e) => {
  // const handleChange = (selectedValue) => {
  //   setOptionshow(!optionshow);
  //   setMessengerTemplate(selectedValue);
  //   const selectedTemplate = allTemplate.find(item => item.templateName === selectedValue);
  //   // console.log("selectedTemplate", selectedTemplate);
  //   if (selectedTemplate) {
  //     setSingleTemplate(selectedTemplate);
  //   } else if (selectedValue === 'custom_value') {
  //     setSingleTemplate({ templateName: 'custom_value' });
  //   }
  // };
  // const handellodMore = () => {
  //   setPage1(1);
  // }

  const [addDealerModelOpen, setAddDealerModelOpen] = useState(false)
  const addDelalerModel = () => {
    setAddDealerModelOpen(!addDealerModelOpen)
  }
  const TempletSentModel = () => {
    setShowMessengerModal(!showMessengerModal)
  }
  const itemList = [" All Dealer", "Today", "Yesterday", "This Month", "Last 30 Days"];
  const routeName = "Dealers";

  // console.log("allfilter-< ", allfilter);

  const totalCount = Object.values(allfilter).reduce((sum, count) => sum + count, 0);

  // Output the result
  // console.log("Total count:", allfilter["New"]);



  const dealerStatusData = [
    { statusText: "All", statusItemCount: totalCount || 0 },
    { statusText: "New", statusItemCount: allfilter["New"] || 0 },
    { statusText: "Verified", statusItemCount: allfilter["Verified"] || 0 },
    { statusText: "Pending", statusItemCount: allfilter["Pending"] || 0 },
    { statusText: "Update", statusItemCount: allfilter["Update"] || 0 },
    { statusText: "Rejected", statusItemCount: allfilter["Rejected"] || 0 },
    { statusText: "Deleted", statusItemCount: allfilter["Deleted"] || 0 },
    { statusText: "Blacklisted", statusItemCount: allfilter["Blacklisted"] || 0 },
  ];

  const updateDateFilter = (selectedDate) => {
    setSearchDealerData((prevData) => ({
      ...prevData,
      dateFilter: selectedDate,
    }));
  };
  // const ITEMS_PER_PAGE = 10;
  const containerRef = useRef(null);

  // useEffect(() => {
  //   loadMoreData();
  // }, [page]);

  // const loadMoreData = () => {
  //   if (loading) return;
  //   setLoading(true);

  //   const startIndex = (page - 1) * ITEMS_PER_PAGE;
  //   const newItems = dealerInfoData.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  //   setDisplayedData(prevData => [...prevData, ...newItems]);
  //   setLoading(false);
  // };

  const handleScroll = () => {
    const bottom = containerRef.current.scrollHeight === containerRef.current.scrollTop + containerRef.current.clientHeight;
    if (bottom && !loading) {
      if (page >= pages) {
        return
      } else {
        setPage(prevPage => {
          const num = Number(prevPage)
          return num + 1
        });
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [loading]);


  async function getTemplates() {
    if (managerId) {
      const res = await fetchData(`wallikonChat/templateList?page=${page1}&&limit=10`, {
        "managerId": managerId
      });
      if (res?.status === 'success') {
        // setAllTemplate(res?.data);
        setAllTemplate([...allTemplate, ...res?.data]);
        // setPage1(res.page);
        setPages1(res?.pages);
        return
      }
      if (res?.status === 'failed') {
        show(`${res?.msg}`, "error");
        return
      } else {
        show(`Error`, "error");
      }
    }

  }
  useEffect(()=>{
    getTemplates();
  },[])


  const templist = [
    { name: "Templist Data 1" },
    { name: "Templist Data 2" },
    { name: "Templist Data 3" },
    { name: "Templist Data 4" },
    { name: "Templist Data 5" },
    { name: "Templist Data 6" },
    { name: "Templist Data 7" },
    { name: "Templist Data 8" },
    { name: "Templist Data 9" },
  ]

  const [selectTemp, setSelectTemp] = useState("Select Template");
  const [showSelectTemp, setShowSelectTemp] = useState(false);
  const [isloading , setIsLoading] = useState({

  })

  return (
    <React.Fragment>
      <ModalWrapper
        closeBtn={showMessengerModal}
        text={"Hello "}
        isOpen={showMessengerModal} onClose={TempletSentModel}>
        <Suspense fallback={
          <div className={styles.TempContiner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "30%" }}>
                <Skeleton lines={1} thickness={3} />
              </div>
              <div style={{ width: "90%", alignSelf: "center", marginTop: "10px" }}>
                <Skeleton lines={1} thickness={5} />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                  <div style={{ width: "30%" }} > <Skeleton lines={1} thickness={3} />  </div>
                  <div style={{ width: "30%" }} > <Skeleton lines={1} thickness={3} />  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "48%" }} > <Skeleton lines={1} thickness={5} />  </div>
                  <div style={{ width: "48%" }} > <Skeleton lines={1} thickness={5} />  </div>
                </div>
              </div>
            </div>

          </div>
        }>
          <TempletSent
            selectTemp={selectTemp}
            setSelectTemp={setSelectTemp}
            setShowSelectTemp={setShowSelectTemp}
            showSelectTemp={showSelectTemp}
            templist={templist}

            key={"1"}
          />
        </Suspense>
      </ModalWrapper>









      {/* <Modal
        show={showMessengerModal}
        close={() => { setSelectedNumber([]); setShowMessengerModal(); setMessengerTemplate() }}
        closeBtn={true}
        heading="Send Message"
        content={
          <div className={styles.text_center}>
            <div className={styles.choose_template}>
              <div style={{ width: "100%", }} onClick={() => setOptionshow(!optionshow)}  >
                {messengerTemplate ? <span>{messengerTemplate}</span> : <span>Choose Template</span>}
              </div>
              {optionshow &&
                <div className={styles.optionPosion}  >
                  <div>
                    {allTemplate && allTemplate.map((item, index) => (
                      item.category === "MARKETING" ? (
                        <p key={index} value={item.templateName} className={styles.optionP} onClick={() => handleChange(item.templateName)}  >
                          {item.templateName && item.templateName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                        </p>
                      ) : null
                    ))}
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "40px" }} >
                    {
                      loadingis ?
                        <div className={styles.loaderContainer}>
                          <span className={styles.loaderSpan}></span>
                        </div> : (page1 === pages1) ? null :
                          <span style={{ fontSize: "26px" }} onClick={handellodMore}>+</span>
                    } */}
      {/* <button className={styles.btnn} >Previous</button>
                    <button className={styles.btnn}  >Next</button> */}
      {/* </div> */}

      {/* <ScrellList  onScroll={onScroll} listInnerRef={listInnerRef} userList={allTemplate}  /> */}
      {/* </div> */}
      {/* } */}

      {/* <select value={messengerTemplate} onChange={handleChange}>
                <option value='' hidden>Choose Template</option>
                {allTemplate && allTemplate.map((item, index) => (
                  item.category === "MARKETING" ? (
                    <option key={index} value={item.templateName}>
                      {item.templateName && item.templateName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </option>
                  ) : null
                ))}
              </select> */}

      {/* 

            </div>
            <div className={styles.all_contacts_div}>
              <div className='d-flex'>
                All Contacts
                <div
                  role='button'
                  className='border rounded p-1 d-flex justify-content-center align-items-center ms-4'
                  onClick={() => {
                    if (selectedNumber.length === dealerNumber.length) {
                      setSelectedNumber([]);
                    } else {
                      handleSelectAllNumber();
                    }
                  }}
                >
                  {selectedNumber.length !== 0 && (selectedNumber.length === dealerNumber.length) ? <CheckGreenIcon /> : <span className='p-2'></span>}
                </div>
              </div>
              <div>Total - {selectedNumber.length}</div>
            </div>

            <div className={styles.massageModal_btn}>
              <RedOutButton title="CANCEL" handleSubmit={() => { setSelectedNumber([]); setShowMessengerModal(false); setMessengerTemplate() }} />
              {selectedNumber && selectedNumber.length !== 0 && messengerTemplate
                ? */}
      {/* // <GreenButton title="SEND" handleSubmit={() => { sendMessage(); setShowMessengerModal(false); setShowDealerNames(true) }} />
                <GreenButton title="SEND" handleSubmit={() => { setShowMessengerModal(false); setSendTemplateModal(true) }} />
                :
                <BlackOutButton title="SEND" handleSubmit={() => { }} />
              }
            </div>
          </div>
        }
      /> */}
      <Modal
        show={sendTemplateModal}
        close={() => { setSendTemplateModal(); setSelectedNumber([]); setMessengerTemplate() }}
        closeBtn={true}
        heading={messengerTemplate && (messengerTemplate.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))}
        content={
          <div className={styles.text_center}>
            <div className='d-flex flex-column'>
              <p className={`${styles.message} mt-1 mb-3`}>Price of Template sending is <span className='text-danger'>{selectedNumber.length * (singleTemplate.category === 'MARKETING' ? 0.73 : singleTemplate.category === 'UTILITY' ? 0.30 : 0)}</span></p>
              <p className={`${styles.message} mt-1 mb-3`}>To send! type <span className='text-danger'>send</span> then click the send button.</p>
              <input type='text' maxLength={6} className={styles.input_delete} value={sendTemplateText} placeholder='Enter' onChange={(e) => setSendTemplateText(e.target.value)} />
            </div>

            <div className={styles.massageModal_btn}>
              <RedOutButton title="CANCEL" handleSubmit={() => { setSendTemplateModal(false); setSelectedNumber([]); setMessengerTemplate() }} />
              <GreenButton disabled={sendTemplateText.toUpperCase() === 'SEND' ? (templatePage < totalPage && false) : true} title="SEND" handleSubmit={() => { sendMessage(); setShowMessengerModal(false); setSendTemplateModal(false); setShowDealerNames(true) }} />
            </div>
          </div>
        }
      />

      <Modal
        show={showDealerNames}
        close=''
        closeBtn={false}
        heading=''
        content={
          <div className={styles.text_center}>
            <div className='d-flex justify-content-between'>
              <h2 className={styles.template_name}>{messengerTemplate + ' Template Sent'}</h2>
              <div className={styles.modal_close_button} onClick={() => { setShowDealerNames(false); setSelectedNumber([]); setMessengerTemplate(); setSendTemplateText('') }}>&times;</div>
            </div>
            {/* <div className='d-flex justify-content-between'>
              <div>Total - {dealerNames.length}</div>
              <div className={styles.success_color}>Success - {templateData && templateData.successCount}</div>
              <div className='text-danger fw-bold'>Failed - {templateData && templateData.failedCount}</div>
            </div> */}
            <div className={styles.dealerNames_div}>
              {dealerNames && dealerNames.map((item, index) => <div key={index} className={styles.dealerNames}><span>{index + 1}&nbsp;</span><span className='me-1'>{item.name}-{item.whatsapp}</span><span>{item.status === 'success' ? <DoubleCheckIcon /> : item.status === 'not send' ? 'not send' : <CrossRedIcon />}</span></div>)}
            </div>
            {/* {templateDataLoading && <SpinnerWhite size="15" />} */}
            {templateError ?
              <p className='text-center'>{templateError}</p>
              :
              <>
                {templateDataLoading ? <Spinner css={styles.spinnerWhite} size="24" /> : <div className='d-flex justify-content-between'>
                  <div className='text-primary fw-bold'>Total - {sendStatus.total && sendStatus.total}</div>
                  <div className={styles.success_color}>Success - {sendStatus.successful && sendStatus.successful}</div>
                  <div className='text-danger fw-bold'>Failed - {sendStatus.failed && sendStatus.failed}</div>
                </div>}
              </>
            }
            <div className={styles.dealerNameModal_btn}>
              <GreenOutButton title="CLOSE" handleSubmit={() => { setShowDealerNames(false); setSelectedNumber([]); setMessengerTemplate(); setSendTemplateText('') }} />
            </div>
          </div>
        }
      />


      {/* <AddDealerModal
        show={showAddDealer}
        close={setShowAddDealer}
        setData={setDealerData}
      /> */}

      <ModalWrapper isOpen={addDealerModelOpen} onClose={addDelalerModel} text={"Add Dealer"} closeBtn={true} >
        <div  >
          <div style={{ height: "10px" }}></div>
          <AdduserModel close={addDelalerModel} />
        </div>
      </ModalWrapper>
      <div className={styles.right_main}>
        <div className={width > 380 ? (mode ? styles.Dealer_main_container : styles.Dealer_main_container_white) : 'default-class'}>
          <div className={styles.addDealers}>
            {
              width < 1130 ? null :
                <div className={mode ? styles.dealers : styles.dealers_white} >
                  <div className={styles.test_top}>
                    <TestTopBackArrowIcon />
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        color: mode ? "#FFFFFF" : "#4164E3",
                      }}
                    >
                      Go Back
                    </p>
                    <TestTopDotIcon />
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "27px",
                        color: mode ? "#E4E7EB" : "#646B88",
                      }}
                    >
                      {routeName}
                    </p>
                  </div>
                </div>
            }
            <div className={styles.btnContiner} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
              <div style={{ display: "flex", alignItems: "center", gap: "6px", /* flexWrap: "wrap", */ justifyContent: "flex-start", marginLeft: "6px" }} >
                <div>
                  <SlidingFilter
                    itemList={itemList}
                    setFilter={updateDateFilter}
                  />
                </div>
                <div style={{ display: "flex", gap: "8px" }} >
                  {
                    width > 947 ?
                      <div>
                        <button className={styles.btnAdddelaer1} onClick={() => { setShowMessengerModal(true); dealerNumberData() }}>
                          <span><WhatAppIcon /> </span> <span> Messenger</span>
                        </button>
                      </div> : null
                  }
                  <div>
                    <button className={styles.btnAdddelaer} onClick={addDelalerModel} >{"+"} Add Dealer</button>
                  </div>
                </div>

              </div>

              {/* <GreenOutButton btnType="button" title={<><AddDealerIcon />&nbsp;&nbsp;ADD DEALER</>} css={styles.addDealerButton} handleSubmit={() => { setShowAddDealer(!showAddDealer) }} /> */}
            </div>
          </div>


          {/* 
          {
            width < 843 ? null : */}
          <div
            className={styles.test_middle}
            style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
          >
            <Suspense fallback={<div>Loading..</div>}>
              <div style={{ width: "100%", display: "flex", overflowX: "scroll" }} className={styles.scrollable}>
                {dealerStatusData.map((status, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "6px",
                      minWidth: "180px",
                      cursor: "pointer"
                    }}
                    key={index}
                    onClick={() => setSearchDealerData((pre) => ({
                      ...pre, status: status.statusText === "All" ? "" : status.statusText
                    }))}
                  >
                    <DealerStatusCard
                      dealerStatus={status.statusText}
                      statusText={status.statusText}
                      statusItemCount={status.statusItemCount}
                    />
                  </div>
                ))}
              </div>
            </Suspense>
          </div>
          {/* } */}

          <div className={styles.dealer_header}>
            {/* <div
              className={styles.dealer_search_div}>
              <input type="search"
                placeholder='Search'
                className={styles.dealer_search}
                value={searchDealerData.searchDealerName}
                onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })}
              />
              <Search color="#ffffff" css={styles.dealer_search_icon} />
            </div> */}
            {/* <div className={styles.dealer_header_right}>

              <div className={styles.whatsappMessenger} onClick={() => { setShowMessengerModal(true); dealerNumberData() }}>
                <PackingWhatsappIcon />&nbsp; Messenger
              </div>

              <div className={styles.dealer_Sort_By}>
                <label className={styles.dealer_sortBy_txt}>Sort By </label>
                <select
                  className={styles.dealer_sortBy_select}
                  value={searchDealerData.sortByStatus}
                  onChange={(e) => setSearchDealerData({ ...searchDealerData, sortByStatus: e.target.value })}
                >
                  <option className={styles.dealer_sortBy_color} value="">View All</option>
                  <option className={styles.dealer_sortBy_color} value="Referral">Referral</option>
                  <option className={styles.dealer_sortBy_color} value="Widthout OTP">Widthout OTP</option>
                  <option className={styles.dealer_sortBy_color} value="Managers Created">Managers Created</option>
                  <option className={styles.dealer_sortBy_color} value="Self Created">Self Created</option>
                  <option className={styles.dealer_sortBy_color} value="New">New</option>
                  <option className={styles.dealer_sortBy_color} value="newestfirst">Newest First</option>
                  <option className={styles.dealer_sortBy_color} value="Pending">Not Verified</option>
                  <option className={styles.dealer_sortBy_color} value="Verified">Verified</option>
                  <option className={styles.dealer_sortBy_color} value="Ascending">Ascending</option>
                  <option className={styles.dealer_sortBy_color} value="Descending">Descending</option>
                </select>
              </div>
              <div className={styles.dealer_header_select_days}>
                <div className={`${styles.dealer_days_select} ${styles.days_active}`}>All</div>
                <div className={styles.dealer_days_select}>15 Days</div>
                <div className={styles.dealer_days_select}>15-30 Days</div>
                <div className={styles.dealer_days_select}>30 Days</div>
              </div>
    
            </div> */}
          </div>
          {/* <div className={styles.dealer_main_div}>
            {
              loading ? <div className={'loadingMainDealer'}>
                <img src='/wallicon.gif' alt='walliconGIF' />
              </div> :
                dealersData && dealersData.map((item, index) =>
                  <div className={styles.dealer_card} key={index}>
                    <div className={styles.dealer_card_div}>
                      <div onClick={() => { navigate("/dealers/" + item.dealerId) }} className={styles.cursor_pointer} >
                        <div className={styles.dealer_status} >
                          {item.status === "Rejected" ?
                            <Ban color="#F93E3E" css={styles.dealer_status_icon} />
                            : item.status === "Verified" ? <Checked color="#1FFC33" css={styles.dealer_status_icon} />
                              : item.status === "Pending" ? <Clock color="yellow" css={styles.dealer_status_icon} />
                                : item.status === "Update" ? <div className={styles.newIcon_div}> <UpdateIcon color="#f8f9fa" css={styles.newIcon} /></div>
                                  : <div className={styles.newIcon_div}>
                                    <div className={styles.newIcon}>New</div>
                                  </div>
                          }
                        </div>
                        {!item.profileImage ?
                          <div className={styles.name_char}><span className={styles.name_char_mb}>{item && item.name.charAt(0).toUpperCase()}</span></div>
                          :
                          <img src={process.env.REACT_APP_S3URL + item.profileImage} className={styles.dealer_image} alt='dealer_image' />
                        }

                        <h4 className={styles.dealer_name}>{item.name}</h4>
                        <div className={styles.dealeStore_owner}>{item.designation}</div>
                        <div className={item.storeName ? "" : styles.storeName_hide}>
                          <div className={styles.dealeStore_owner}>at</div>
                          <div className={styles.dealeStore_owner}>{item.storeName}</div>
                        </div>
                      </div>
                      <div className={styles.dealer_btn_div}>
                        <button className={styles.dealer_Btn}>
                          <Messanger color="#ffffff" width={16} />
                        </button>
                        <a href={'tel:+91' + item.mobile} className={styles.dealer_Btn}>
                          <Call color="#ffffff" width={16} />
                        </a>
                        <a target="_blank" rel="noreferrer" href={"https://wa.me/+91" + item.whatsapp} className={styles.dealer_Btn}>
                          <WhatsApp width={16} />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
          </div> */}
          <div
            className={styles.test_bottom}
            style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
          >
            {
              width < 669 ? null :
                <div className={styles.test_bottom_top}>
                  <Suspense fallback={<div>Loading..</div>}>
                    <div className={styles.filterContiner}>
                      <div className={styles.filterserch} >
                        <SearchBar onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })} />
                      </div>
                      <div className={styles.test_bottom_top_right}>
                        {/* <DropDown label={"State"} options={[{ label: "name" }, { label: "Age" }]} /> */}
                        <DropDown label={"Pin Code"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"State"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"District"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"City"} options={[{ label: "name" }, { label: "Age" }]} />
                        <DropDown label={"Sort By"} options={[
                          { label: "Referral" },
                          { label: "Widthout OTP" },
                          { label: "Managers Created" },
                          { label: "Self Created" },
                          { label: "New" },
                          { label: "newestfirst" },
                          { label: "Pending" },
                          { label: "Verified" },
                          { label: "Ascending" },
                          { label: "Descending" },
                        ]} />
                      </div>
                    </div>
                  </Suspense>
                </div>
            }
            <div className={/* width < 500 ? styles.test_bottom_bottom1 : */ styles.test_bottom_bottom} ref={containerRef}>
              <Suspense fallback={<div style={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "100%", justifyContent: "center" }}>
                {/* Render 5 Skeletons, mimicking the dealer card layout */}
                loading ...
              </div>}>
                {dealerData.length === 0 ? <div>
                  {
                    loading ? <span style={mode ? { color: "white" } :
                      { color: "black" }}>loading..</span> :
                      <span style={mode ? { color: "white" } :
                        { color: "black" }}>  No Data Found.</span>
                  }

                </div> :
                  dealerData.map((dealer, index) => (
                    <div key={index} style={{ width: "16%", justifyContent: "space-evenly", minWidth: "247px" }}  >
                      <DealerInfoCard
                        whatappNo={dealer.whatsapp}
                        dealerName={dealer.name}
                        imageUri={dealer.profileImage}
                        dealerStatus={dealer.status}
                        dealerDesignation={dealer.designation}
                        dealerLocation={dealer.storeName}
                      />
                    </div>
                  ))}
                {loading && <div>Loading more...</div>}
                {/* <h1>hello</h1> */}
              </Suspense>
            </div>
          </div>
          <div style={{ height: "5vh", width: "100%", /* border:"1px solid red", */ display: "flex", alignItems: "center", }} >
            <div style={{ width: "50%", /* border:"1px solid red", */ }} >
              <div style={{ width: "50%",/* border:"1px solid red", */ display: "flex", justifyContent: "flex-start" }} >
                <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
              </div>
            </div>
            <div style={{ width: "50%",/* border:"1px solid red", */ display: "flex", justifyContent: "flex-end" }} ><Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} /></div>
          </div>

          {/* <Pagination
            total={pages}
            current={page}
          /> */}
        </div>
      </div>

      <div className={mode ? styles.md_Main_continer : styles.md_Main_continer_dark}>
        <div className={mode ? styles.Md_continer : styles.Md_continer_dark}>
          {
            dealerStatusData.map((item, i) => {
              return (<div style={{
                display: "flex",
                width: "32%",
              }}
                onClick={() => setSearchDealerData((pre) => ({
                  ...pre, status: item.statusText === "All" ? "" : item.statusText
                }))} key={i}>
                <FilterCard name={item.statusText} number={item.statusItemCount} key={i} />
              </div>
              )
            })
          }
        </div>
        <div className={styles.SearchFilter} >
          <div className={styles.SearchCon}>
            <div className={mode ? styles.SearchInput_dark : styles.SearchInput} >
              <input type="text" placeholder='Search..' className={mode ? styles.inptage_dark : styles.inptage} onChange={(e) => setSearchDealerData({ ...searchDealerData, searchDealerName: e.target.value })} />
            </div>
            <div style={{
              minWidth: "40px",
              minHeight: "40px",
              border: "1px solid #EEEEE",
              justifyContent: "center",
              display: "flex",
              // margin: "10px",
              borderRadius: "6px",
              background: "#E4E7EB",
              width: "15%",
              height: "43px",
            }}>
              <span className={styles.arroIcon}>
                <ArroIcon mode={mode} />
              </span>
            </div>
            <button className={styles.addbtnmd} onClick={addDelalerModel} >{"+ Add Dealer"}</button>
          </div>
          <div>
          </div>
        </div>

        <div className={styles.md_card_Continer} >
          {dealerData.length === 0 ? <div>
            {loading ?
              <span style={mode ? { color: "white" } : { color: "black" }}>loading..</span> :
              <span style={mode ? { color: "white" } : { color: "black" }}>  No Data Found.</span>}

          </div> :
            dealerData.map((dealer, index) => (
              <div key={index} style={{ width: "100%", marginBottom: "10px", minWidth: "360px" }}>
                <DealerCartMobil
                  dealerName={dealer.name}
                  dealerStatus={
                    dealer.status === "New" ? "new" :
                      dealer.status === "Update" ? "active" :
                        dealer.status === "Pending" ? "pending" :
                          dealer.status === "Verified" ? "active" :
                            dealer.status === "Rejected" ? "rejected" :
                              dealer.status === "Deleted" ? "rejected" : null
                  }
                  dealerDesignation={dealer.designation}
                  dealerLocation={dealer.storeName}
                  imgUri={dealer.profileImage}
                />
              </div>
            ))}
          <div>
          </div>
        </div>


      </div>
    </React.Fragment>
  )
}

export default Dealers

