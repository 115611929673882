import React, { useState, useEffect, useRef, Suspense, lazy } from 'react'
import styles from './Templates.module.css'
import { useNavigate, } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
import io from 'socket.io-client';
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import { CreateTem, Refresh, TemplateIcon, } from '../../components/icons/Icon2'
import Button from '../../components/updatedComponents/Dealers/Button'
import InputTypedate from '../../components/updatedComponents/FormComponents/InputTypedate'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage';
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation"
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import fetchData from '../../APIsControll/apiControll';
import { useToast } from '../../components/updatedComponents/Toaster/Toaster';
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton';
const TampletTable = lazy(() => import("../../components/updatedComponents/Tanolet/Table/TampletTable"));


const SOCKET_SERVER_URL = process.env.REACT_APP_URL;

const Templates = () => {
    const { mode } = useAuth();
    const { show } = useToast()
    const navigate = useNavigate();
    const { managerId } = useAuth()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState({
        search: '',
        From: '',
        To: '',

    })
    const HandealRefresh = () => {
        setPage(1)
        setFilter({
            search: '',
            From: '',
            To: '',
        })
    }

    const getAlltemplate = async () => {
        setLoading(true)
        const response = await fetchData(`wallikonChat/templateList?page=${page}`, {
            "managerId": managerId,
            "keyword": filter?.search.replace(/ /g, '_').toLowerCase() || '',
            "startDate": filter?.From,
            "endDate": filter?.To,
        })
        if (response.status === 'success') {
            setData(response?.data);
            setPages(response.pages);
            setLoading(false)
            return
        }
        if (response.status === 'failed') {
            setData(response?.data || []);
            setPages(response.pages || 1);
            setLoading(false)
            return
        }
        else {
            show("Error", "error");
            setLoading(false)
            return
        }
    }


    useEffect(() => {
        const socket = io(SOCKET_SERVER_URL);
        socket.on('templateList', (updatedTemplates) => {
            return setData(updatedTemplates);
        });
        getAlltemplate();
        return () => {
            socket.disconnect();
        };
    }, [managerId, page, filter]);


    const markReadRef = useRef(null);
    const handleClickOutsideOptions = (event) => {
        if (markReadRef.current && !markReadRef.current.contains(event.target)) {
            // setShowOptions()
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutsideOptions);
        return () => {
            document.removeEventListener("click", handleClickOutsideOptions);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={styles.main_continer} >
                <div className={styles.Conitner} style={mode ? { backgroundColor: "#2C2E33", border: "1px solid #232529" } :
                    { border: "1px solid #E4E7EB", backgroundColor: "#F8F9FC" }} >
                    <div className={styles.Secand_Coninter} style={mode ? { backgroundColor: "#2C2E33", /* border: "1px solid #232529" */ } :
                        { /* border: "1px solid #E4E7EB", */ backgroundColor: "#F8F9FC", }} >

                        <TopTabBar text={"Campaigns / Template"} />
                        <div className={styles.btn_coninter} >
                            <div className={styles.btn_coninter_left} >
                                <div className={styles.SerachContiner} >
                                    <SearchBar
                                        onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                        value={filter?.search}
                                    />
                                </div>
                                <div className={styles.AroBtn} style={mode ? { backgroundColor: "#1B1D21" } : { backgroundColor: "#fafafafa", border: "1px solid #E4E7EB" }} >
                                    <TemplateIcon mode={mode} />
                                </div>
                            </div>
                            <div className={styles.btn_coninter_Right} >
                                <div>
                                    <InputTypedate
                                        text={"From"}
                                        onChange={(e) => setFilter({ ...filter, From: e.target.value })}
                                        value={filter?.From}

                                    />
                                </div>
                                <div>
                                    <InputTypedate
                                        onChange={(e) => setFilter({ ...filter, To: e.target.value })}
                                        value={filter?.To}
                                        text={"To"} />
                                </div>
                                <div>
                                    <Button
                                        buttonBgColor={"#000000"}
                                        buttonIcon={<Refresh />}
                                        buttonTextColor={"#fafafa"}
                                        buttonText={"Refresh"}
                                        buttonClick={HandealRefresh}
                                    />
                                </div>
                                {/* <div>
                                    <Button
                                        buttonBgColor={"#000000"}
                                        buttonIcon={<Import />}
                                        buttonTextColor={"#fafafa"}
                                        buttonText={"Import Template"} />
                                </div> */}
                                <div>
                                    <Button
                                        buttonBgColor={"#4164E3"}
                                        buttonIcon={<CreateTem />}
                                        buttonTextColor={"#fafafa"}
                                        buttonText={"Create Template"}
                                        buttonClick={() => navigate("/templates/template")}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={styles.Secand_Coninter1} style={mode ? { backgroundColor: "#2C2E33",/*  border: "1px solid #232529" */ } :
                        { /*  border: "1px solid #E4E7EB",  */ backgroundColor: "#F8F9FC", }} >
                        <Suspense fallback={<div>
                            <Skeleton lines={8} width="100%" thickness={8} height='400px' />
                        </div>}>
                            <TampletTable data={data} loading={loading} />
                        </Suspense>
                    </div>
                    <div className={styles.Secand_Coninter2} style={mode ? { backgroundColor: "#2C2E33", } :
                        { backgroundColor: "#F8F9FC", }} >
                        <div style={{ width: "100%", display: "flex", alignItems: "center", }} >
                            <div style={{ width: "50%", }} >
                                <div style={{ width: "50%", display: "flex", justifyContent: "flex-start" }} >
                                    <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                                </div>
                            </div>
                            <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }} >
                                <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} /></div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Templates
