
import React, { useEffect, useState } from "react";
import styles from "./globalModel.module.css";
import InputTag from "../FormComponents/InputTag";
import { useAuth } from "../../context/Auth";
import fetchData from "../../../APIsControll/apiControll";
import VerticalDropDown from "../../updatedComponents/FormComponents/VerticalDropDown";
import Button from "../Dealers/Button";
import { Block } from "../../icons/Icon2";
import { validateForm } from "../../../validation/AddDealer"; // Import the validation function
import { CrossIcon } from "../../icons/Icon";

const AdduserModel = ({ close }) => {
  const { mode, managerId, setIsLoading, isLoading } = useAuth();
  const [manager, setManager] = useState([]);
  const [SelectManger, setSelectManger] = useState("");
  const [Designation, setDesignation] = useState("");
  const [formData, setFormData] = useState({
    pin: "",
    selectManager: "",
    fullName: "",
    mobileNumber: "",
    storeName: "",
    whatsAppNumber: "",
    shippingAddress: "",
    state: "",
    district: "",
    city: "",
    gstUin: "",
  });
  console.log(SelectManger);

  const [errors, setErrors] = useState({}); 

  useEffect(() => {
    const getLocation = async () => {
      if (formData.pin.length === 6) {
        setIsLoading(true);
        const res = await fetchData("locations/locations", {
          keyword: formData.pin,
        });
        setIsLoading(false);
        setFormData((prevState) => ({
          ...prevState,
          district: res.districts[0] ? res.districts[0].toUpperCase() : "",
          city: res.SubDistName[0] ? res.SubDistName[0] : "",
          state: res.states[0] ? res.states[0] : "",
        }));
      }
    };

    getLocation();
  }, [formData.pin]);

  useEffect(() => {
    const addDealer = async () => {
      const res = await fetchData("order/ordermanagers", { managerId });
      if (res.status === "success") {
        setManager(res.data);
      }
    };

    addDealer();
  }, [managerId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm(formData, Designation, SelectManger);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      mobile: formData.mobileNumber,
      refcode: managerId,
      name: formData.fullName,
      street: formData.shippingAddress,
      pincode: formData.pin,
      city: formData.city,
      district: formData.district,
      state: formData.state,
      country: "India",
      designation: Designation ? Designation.label : "",
      gstuin: formData.gstUin,
      storeName: formData.storeName,
      whatsapp: formData.whatsAppNumber,
      managerId: SelectManger ? SelectManger.value : "",
    };

    const res = await fetchData("order/addDealer", payload);

    if (res.status === "success") {
      close();
    } else {
      alert(res.msg);
    }
  };

  return (
    <div className={mode ? styles.mainContiner : styles.mainContiner_light}>
      <div className={styles.dealer_form_header}>
        <p style={mode ? { color: "#fff" } : {}}>Add Dealer</p>
        <div onClick={() => close()} style={{ cursor: "pointer" }}>
          <CrossIcon color={mode ? "#fff" : "#1B1D21"} />
        </div>
      </div>
      <div className={styles.formContiner}>
        <div className={styles.inputbox}>
          <InputTag
            name="fullName"
            labelText={"Full Name"}
            placeholderText={"Name"}
            placeholderType={"text"}
            required={true}
            value={formData.fullName}
            onChange={handleChange}
            maxLength={100}
            star={true}
          />
          {errors.fullName && (
            <span className={styles.error}>{errors.fullName}</span>
          )}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="mobileNumber"
            labelText={"Mobile Number"}
            placeholderText={"+91 916104XXX"}
            placeholderType={"number"}
            required={true}
            value={formData.mobileNumber}
            onChange={handleChange}
            maxLength={10}
            star={true}
          />
          {errors.mobileNumber && (
            <span className={styles.error}>{errors.mobileNumber}</span>
          )}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="storeName"
            labelText={"Store / Company name"}
            placeholderText={"Wallicon Pvt Ltd"}
            placeholderType={"text"}
            required={true}
            value={formData.storeName}
            onChange={handleChange}
            maxLength={200}
            star={true}
          />
          {errors.storeName && (
            <span className={styles.error}>{errors.storeName}</span>
          )}
        </div>
        <div className={styles.inputbox}>
          <VerticalDropDown
            dropDownText={"--Select--"}
            value={Designation}
            onchange={setDesignation}
            label={"Designation"}
            options={[
              { label: "Interior Designer" },
              { label: "Store Owner" },
              { label: "Contractor" },
              { label: "Architect" },
              { label: "Others" },
            ]}
            star={true}
          />
          {errors.designation && (
            <span className={styles.error}>{errors.designation}</span>
          )}
        </div>
        <div className={styles.inputbox}>
          <VerticalDropDown
            dropDownText={"--Select--"}
            label={"Assign Order Manager"}
            value={SelectManger}
            onchange={setSelectManger}
            options={manager.map((e) => ({
              label: e.name,
              value: e.managerId,
            }))}
            star={true}
          />
          {errors.selectManager && (
            <p className={styles.error}>{errors.selectManager}</p>
          )}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="whatsAppNumber"
            labelText={"WhatsApp Number"}
            placeholderText={"+91 916104XXX"}
            placeholderType={"number"}
            required={true}
            value={formData.whatsAppNumber}
            onChange={handleChange}
            maxLength={10}
            star={true}
          />
          {errors.whatsAppNumber && (
            <span className={styles.error}>{errors.whatsAppNumber}</span>
          )}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="shippingAddress"
            labelText={"Shipping Address"}
            placeholderText={"Address "}
            placeholderType={"text"}
            required={true}
            value={formData.shippingAddress}
            onChange={handleChange}
            maxLength={600}
            star={true}
          />
          {errors.shippingAddress && (
            <span className={styles.error}>{errors.shippingAddress}</span>
          )}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="pin"
            labelText={"PIN Code"}
            placeholderText={"26XXX9"}
            placeholderType={"number"}
            required={true}
            value={formData.pin}
            onChange={handleChange}
            maxLength={6}
            star={true}
          />
          {errors.pin && <span className={styles.error}>{errors.pin}</span>}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="state"
            labelText={"State"}
            placeholderText={"Utter Pradesh"}
            placeholderType={"text"}
            required={true}
            value={formData.state}
            onChange={handleChange}
            maxLength={100}
            loading={isLoading}
            star={true}
          />
          {errors.state && <span className={styles.error}>{errors.state}</span>}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="district"
            labelText={"District"}
            placeholderText={"Lucknow"}
            placeholderType={"text"}
            required={true}
            value={formData.district}
            onChange={handleChange}
            maxLength={100}
            loading={isLoading}
            star={true}
          />
          {errors.district && (
            <span className={styles.error}>{errors.district}</span>
          )}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="city"
            labelText={"City"}
            placeholderText={"Lucknow"}
            placeholderType={"text"}
            required={true}
            value={formData.city}
            onChange={handleChange}
            maxLength={100}
            loading={isLoading}
            star={true}
          />
          {errors.city && <span className={styles.error}>{errors.city}</span>}
        </div>
        <div className={styles.inputbox}>
          <InputTag
            name="gstUin"
            labelText={"GSTUIN (Optional)"}
            placeholderText={""}
            placeholderType={"text"}
            required={false}
            value={formData.gstUin}
            onChange={handleChange}
            maxLength={15}
          />
          {errors.gstUin && (
            <span className={styles.error}>{errors.gstUin}</span>
          )}
        </div>
      </div>
      <div className={styles.btnContiner}>
        <Button
          buttonBgColor={"#000000"}
          buttonBorder={"black"}
          buttonText={"CANCEL"}
          buttonTextColor={"#FFFFFF"}
          buttonClick={() => close()}
          buttonIcon={<Block />}
          buttonFontWeight={"500"}
        />
        <Button
          buttonBgColor={"#4164E3"}
          buttonBorder={"#4164E3"}
          buttonText={"+ ADD DEALER"}
          buttonTextColor={"#FFFFFF"}
          buttonClick={handleSubmit}
          buttonFontWeight={"500"}
        />
      </div>
    </div>
  );
};

export default AdduserModel;
