import React, { useState, lazy, Suspense, useEffect } from "react";
import styles from "./AddManager.module.css";
import { useAuth } from "../../components/context/Auth";
import {
  EditManagerIcon,
  ManagerLockIcon,
  ManagerLogOffIcon,
  ManagerLogOutIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CryptoJS from "crypto-js";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import ToggleBtn from "../../components/updatedComponents/FormComponents/ToggleBtn";
import ProgressBar from "../../components/updatedComponents/Manager/ProgressBar";
import fetchData from "../../APIsControll/apiControll";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ManagerModal from "../../components/updatedComponents/Manager/ManagerModal";
import RadioButtonGroup from "../../components/updatedComponents/FormComponents/RadioButtonGroup";

const Button = lazy(() =>
  import("../../components/updatedComponents/Dealers/Button")
);

const AddManager = () => {
  const { mode, managerId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataInsert, setDataInsert] = useState({
    name: "",
    mobile: "",
    loginId: "",
    password: "",
    confirmPassword: "",
    status: "",
  });
  const [searchParams] = useSearchParams();
  const manager_Id = searchParams.get("manager_Id");
  const path = location.pathname;

  const encryptedLoginId = sessionStorage.getItem("loginID")
    ? sessionStorage.getItem("loginID")
    : "";
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedLoginId, "secret key");
  const loginId = decryptedBytes.toString(CryptoJS.enc.Utf8).slice(1, -1);

  const handleSignOut = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const getManager = async () => {
    const apiData = {
      managerId: managerId,
      id: manager_Id,
    };

    const result = await fetchData("managers/getManager", apiData);

    if (result.status === "success") {
      console.log("MANAGER DATA ", result.data);
      setDataInsert((prevState) => ({
        ...prevState,
        name: result.data.name,
        mobile: result.data.mobile,
        loginId: result.data.loginId,
        status: result.data.status,
        designation: result.data.designation,
        selectedWarehouse: result.data.selectedWarehouse,
        selectedState: result.data.selectedState,
      }));
    } else {
      alert(result.msg);
    }
  };

  const getLoggedInManagerData = async () => {
    try {
      const result = await fetchData("managers/managerProfile", { loginId });
      if (result.status === "success") {
        console.log("USER DATA ", result.data);
        setDataInsert((prevState) => ({
          ...prevState,
          name: result.data.name,
          mobile: result.data.mobile,
          loginId: result.data.loginId,
          status: result.data.status,
          designation: result.data.designation,
          selectedWarehouse: result.data.selectedWarehouse,
          selectedState: result.data.selectedState,
        }));
      } else {
        console.error("Failed to fetch user data: ", result.msg);
      }
    } catch (error) {
      console.log("Error while fetching user data ", error);
    }
  };

  useEffect(() => {
    return () => {
      setDataInsert({
        name: "",
        mobile: "",
        loginId: "",
        password: "",
        confirmPassword: "",
        status: "",
      });
    };
  }, []);

  useEffect(() => {
    if (managerId && manager_Id) {
      getManager();
    } else if (loginId) {
      getLoggedInManagerData();
    }
  }, [managerId, manager_Id, loginId]);

  const formatTabText = (d) => {
    if (typeof d !== "string") {
      return d;
    }

    if (d === "superadmin") {
      return "Super Admin";
    }

    const isManager = d.endsWith("manager");
    let formatted = d;

    if (isManager) {
      const baseName = d.slice(0, -7);
      const capitalizedBaseName = baseName
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      formatted = `${capitalizedBaseName} Manager`;
    }
    return formatted;
  };

  return (
    <>
      <div className={`${styles.am} ${mode ? styles.am_dark : ""}`}>
        <div className={styles.am_top}>
          <div className={styles.am_top_left}>
            <div
              className={styles.am_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                className={`${
                  mode ? styles.am_go_back_text_dark : styles.am_go_back_text
                }`}
                style={{
                  color: mode ? "#FFFFFF !important" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.am_top_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              {path === "/profile"
                ? "Profile"
                : "Managers/Manager Profile/" + dataInsert.name}
            </p>
          </div>

          <div className={styles.am_top_left}>
            <Suspense fallback={<div>Loading..</div>}>
              {path === "/profile" ? (
                <>
                  <Button
                    buttonIcon={<ManagerLogOffIcon />}
                    buttonText={"Logoff"}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                  />
                  <Button
                    buttonIcon={<ManagerLogOutIcon color={"#fff"} />}
                    buttonText={"Logout"}
                    buttonBgColor={"#F93E3E"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() => {
                      setIsModalOpen(true);
                    }}
                  />
                  <ModalWrapper
                    isOpen={isModalOpen}
                    onClose={() => {
                      setIsModalOpen(false);
                    }}
                    noBtn
                  >
                    <ManagerModal
                      heading={"Logout Device"}
                      centerHeading={"Are you sure want to Logout?"}
                      subHeading={"To confirm! type logout in the text field."}
                      targetWord={"logout"}
                      handleCloseModal={() => setIsModalOpen(false)}
                      handleSubmit={() => {
                        handleSignOut();
                      }}
                    />
                  </ModalWrapper>
                </>
              ) : (
                <>
                  <Button
                    buttonIcon={<EditManagerIcon color={"#ffffff"} />}
                    buttonText={"Edit"}
                    buttonBgColor={"#4164E3"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() => navigate(`/newmanager/${manager_Id}`)}
                  />
                </>
              )}
            </Suspense>
          </div>
        </div>

        <div
          className={` ${styles.am_details} ${styles.am_bg} ${
            mode ? styles.am_bg_dark : ""
          }`}
        >
          <div className={styles.am_profile_text}>
            <div className={styles.am_photo}>
              <img src="/assets/updatedAssets/manager_img.png" alt="Manager" />
            </div>

            <Suspense fallback={<div>Loading..</div>}>
              <div className={styles.am_profile_text_container}>
                <div>
                  <TextElement
                    label={"Name:"}
                    value={dataInsert.name}
                    labelColor={mode ? "#9C9797" : "#646B88"}
                    valueColor={mode ? "#fff" : "#000000"}
                  />
                  <TextElement
                    label={"Designations:"}
                    value={dataInsert.designation}
                    labelColor={mode ? "#9C9797" : "#646B88"}
                    valueColor={mode ? "#fff" : "#000000"}
                  />
                  <TextElement
                    label={"Mobile:"}
                    value={dataInsert.mobile}
                    labelColor={mode ? "#9C9797" : "#646B88"}
                    valueColor={mode ? "#fff" : "#000000"}
                  />
                </div>
                <div>
                  <TextElement
                    label={"Worked Hour:"}
                    value={"08:00 Hr"}
                    labelColor={mode ? "#9C9797" : "#646B88"}
                    valueColor={mode ? "#fff" : "#000000"}
                  />
                  <TextElement
                    label={"Shift:"}
                    value={"Shift 1"}
                    labelColor={mode ? "#9C9797" : "#646B88"}
                    valueColor={mode ? "#fff" : "#000000"}
                  />
                  <TextElement
                    label={"Date:"}
                    value={"12-07-2024"}
                    labelColor={mode ? "#9C9797" : "#646B88"}
                    valueColor={mode ? "#fff" : "#000000"}
                  />
                </div>
                <div>
                  <div className={styles.am_work_status_section}>
                    <p
                      className={
                        mode
                          ? styles.am_dark_mode_text
                          : styles.am_light_mode_text
                      }
                    >
                      Work Status<span className={styles.mandatory}>*</span>
                    </p>

                    <ToggleBtn status={dataInsert.status} />
                  </div>
                  <div style={{ width: "50%", marginLeft: "auto" }}>
                    <Suspense fallback={<div>Loading..</div>}>
                      <Button
                        buttonIcon={<ManagerLockIcon />}
                        buttonText={"Lock"}
                        buttonBgColor={"#4164E3"}
                        buttonTextColor={"#ffffff"}
                        buttonBorder={"none"}
                      />
                    </Suspense>
                  </div>
                </div>
                {path === "/profile" && (
                  <>
                    <Link
                      to={"#"}
                      style={{ height: "12px" }}
                      className={
                        mode
                          ? styles.am_dark_mode_link
                          : styles.am_light_mode_link
                      }
                    >
                      Reset your pin
                    </Link>
                    <div className={styles.set_theme}>
                      <p style={{ color: mode ? "#9C9797" : "#646B88" }}>
                        Theme
                      </p>
                      <RadioButtonGroup
                        options={[
                          { value: "1", label: "System" },
                          { value: "2", label: "Light" },
                          { value: "3", label: "Dark" },
                        ]}
                        selectedOption="2"
                      />
                    </div>
                  </>
                )}
              </div>
            </Suspense>
          </div>

          {path === "/profile" && (
            <div className={styles.am_action_center}></div>
          )}
        </div>

        <div className={styles.am_assignments}>
          <div className={styles.am_assignments_left}>
            <div
              className={`${styles.am_assignment} ${
                mode ? styles.am_bg_dark : ""
              }`}
            >
              <Suspense fallback={<div>Loading..</div>}>
                <p
                  className={
                    mode ? styles.am_dark_mode_text : styles.am_light_mode_text
                  }
                >
                  Assigned Designations
                </p>
                <div
                  className={`${styles.am_list_data} ${
                    mode ? styles.warehouse_list_dark : ""
                  }`}
                >
                  <>
                    {Array.isArray(dataInsert.designation) &&
                    dataInsert.designation.length !== 0 ? (
                      dataInsert.designation.map((d) => (
                        <div key={d}>
                          <Button
                            buttonText={formatTabText(d) || "No Designation"}
                            buttonBgColor={mode ? "#2C2E33" : "#F8F9FC"}
                            buttonTextColor={mode ? "#ffffff" : "#4164E3"}
                            buttonFontWeight={"400"}
                            buttonBorder={"none"}
                            type={"manager"}
                          />
                        </div>
                      ))
                    ) : (
                      <p className={styles.new_manager_go_back_text}>
                        No warehouse assigned
                      </p>
                    )}
                  </>
                </div>
              </Suspense>
            </div>

            <div
              className={`${styles.am_assignment} ${
                mode ? styles.am_bg_dark : ""
              }`}
            >
              <Suspense fallback={<div>Loading..</div>}>
                <p
                  className={
                    mode ? styles.am_dark_mode_text : styles.am_light_mode_text
                  }
                >
                  Assigned States
                </p>
                <div className={styles.am_list_data}>
                  <>
                    {Array.isArray(dataInsert.selectedState) &&
                    dataInsert.selectedState.length !== 0 ? (
                      dataInsert.selectedState.map((s) => (
                        <div key={s._id}>
                          <Button
                            buttonText={s.stateName}
                            buttonBgColor={mode ? "#2C2E33" : "#F8F9FC"}
                            buttonTextColor={mode ? "#ffffff" : "#4164E3"}
                            buttonFontWeight={"400"}
                            buttonBorder={"none"}
                            type={"manager"}
                          />
                        </div>
                      ))
                    ) : (
                      <p
                        className={
                          mode
                            ? styles.am_dark_mode_text
                            : styles.am_light_mode_text
                        }
                      >
                        No state assigned
                      </p>
                    )}
                  </>
                </div>
              </Suspense>
            </div>

            <div
              className={`${styles.am_assignment} ${
                mode ? styles.am_bg_dark : ""
              }`}
            >
              <Suspense fallback={<div>Loading..</div>}>
                <p
                  className={
                    mode ? styles.am_dark_mode_text : styles.am_light_mode_text
                  }
                >
                  Assigned Warehouse
                </p>
                <div
                  className={`${styles.am_list_data} ${
                    mode ? styles.warehouse_list_dark : ""
                  }`}
                >
                  <>
                    {Array.isArray(dataInsert.selectedWarehouse) &&
                    dataInsert.selectedWarehouse.length !== 0 ? (
                      dataInsert.selectedWarehouse.map((w) => (
                        <div key={w.warehousename}>
                          <Button
                            buttonText={w.warehousename || "No Warehouse"}
                            buttonBgColor={mode ? "#2C2E33" : "#F8F9FC"}
                            buttonTextColor={mode ? "#ffffff" : "#4164E3"}
                            buttonFontWeight={"400"}
                            buttonBorder={"none"}
                            type={"manager"}
                          />
                        </div>
                      ))
                    ) : (
                      <p className={styles.new_manager_go_back_text}>
                        No warehouse assigned
                      </p>
                    )}
                  </>
                </div>
              </Suspense>
            </div>
          </div>

          <div className={styles.am_assignments_right}>
            <div
              className={`${styles.am_assignment} ${
                mode ? styles.am_bg_dark : ""
              }`}
              style={{ maxHeight: "100%" }}
            >
              <Suspense fallback={<div>Loading..</div>}>
                <p
                  className={
                    mode ? styles.am_dark_mode_text : styles.am_light_mode_text
                  }
                >
                  Attendance : Present
                </p>
                <div style={{ overflowY: "auto", maxHeight: "100%" }}>
                  <div>
                    <ProgressBar currentStep={4} />
                  </div>
                </div>
              </Suspense>
            </div>
          </div>
        </div>

        <div className={styles.am_footer_mob}>
          <Suspense fallback={<div>Loading..</div>}>
            {path === "/profile" ? (
              <>
                <Button
                  buttonIcon={<ManagerLogOffIcon />}
                  buttonText={"Logoff"}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                />
                <Button
                  buttonIcon={<ManagerLogOutIcon color={"#fff"} />}
                  buttonText={"Logout"}
                  buttonBgColor={"#F93E3E"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() => handleSignOut()}
                />
              </>
            ) : (
              <Button
                buttonIcon={<EditManagerIcon />}
                buttonText={"Edit"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => navigate(`/newmanager/${manager_Id}`)}
              />
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default AddManager;

// import React, { useEffect, useState } from "react";
// import { Col, Form, Row } from "react-bootstrap";
// import { GreenButton, RedOutButton } from "../../components/Buttons";
// import IndianState from "../../components/context/IndianState.json";
// import Select from "react-select";
// import styles from "./manager.module.css";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { useAuth } from "../../components/context/Auth";
// import { DeleteIcon, Eye, EyeHide } from "../../components/icons/Icon";

// const AddManager = () => {
//   const navigate = useNavigate();
//   const { managerId } = useAuth();
//   const [designation, setDesignation] = useState([{ designation: "" }]);
//   const [stateIndia, setStateIndia] = useState([]);
//   const [warehouse, setWarehouse] = useState([]);
//   const [paymentWarehouse, setPaymentWarehouse] = useState([]);
//   const [dispatcher, setDispatcher] = useState();
//   const [dispatchManagers, setDispatchManagers] = useState([]);
//   const [warehouseList, setWarehouseList] = useState([]);
//   const [dataInsert, setDataInsert] = useState({
//     name: "",
//     mobile: "",
//     loginId: "",
//     password: "",
//     confirmPassword: "",
//     status: "",
//   });

//   const [searchParams] = useSearchParams();
//   const manager_Id = searchParams.get("manager_Id");
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   useEffect(() => {
//     const getWarehouse = async () => {
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       await fetch(
//         process.env.REACT_APP_URL + "warehouses/getAllWarehouse",
//         requestOptions
//       )
//         .then((res) => res.json())
//         .then((data) => {
//           if (data.status === "success") {
//             setWarehouseList(data.data);
//           } else {
//             alert(data.msg);
//           }
//         });
//     };
//     getWarehouse();
//   }, [managerId]);
//   useEffect(() => {
//     if (manager_Id) {
//       const getManagers = async () => {
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//           managerId: managerId,
//           id: manager_Id,
//         });

//         var requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: raw,
//           redirect: "follow",
//         };

//         await fetch(
//           process.env.REACT_APP_URL + "managers/getManager",
//           requestOptions
//         )
//           .then((res) => res.json())
//           .then((result) => {
//             if (result.status === "success") {
//               setDataInsert((prevState) => ({
//                 ...prevState,
//                 name: result.data.name,
//                 mobile: result.data.mobile,
//                 loginId: result.data.loginId,
//                 status: result.data.status,
//               }));
//               if (result.data.designation) {
//                 setDesignation(
//                   result.data.designation.map((obj) => ({ designation: obj }))
//                 );
//               }
//               if (result.data.selectedState) {
//                 setStateIndia(
//                   result.data.selectedState.map((obj) => ({
//                     label: obj.stateName,
//                     value: obj.stateName,
//                   }))
//                 );
//               }
//               if (result.data.selectedWarehouse) {
//                 setWarehouse(
//                   result.data.selectedWarehouse.map((obj) => ({
//                     label: obj.warehousename,
//                     value: obj.warehousename,
//                   }))
//                 );
//               }
//               if (result.data.dms) {
//                 setDispatchManagers(
//                   result.data.dms.map((obj) => ({
//                     label: obj.dmName,
//                     value: obj.managerId,
//                   }))
//                 );
//               }
//               if (result.data.selectedDM) {
//                 setDispatcher(
//                   result.data.selectedDM.map((obj) => ({
//                     label: obj.dmName,
//                     value: obj.dmId,
//                   }))
//                 );
//               }
//             } else {
//               alert(result.msg);
//             }
//           });
//       };
//       getManagers();
//     }
//   }, [manager_Id, managerId]);

//   const dtn = designation.map((item) => item.designation);
//   const st =
//     stateIndia && stateIndia.map((item) => ({ stateName: item.value }));

//   const warehousename =
//     warehouse && warehouse.map((item) => ({ warehousename: item.value }));
//   const selectedDM =
//     dispatcher &&
//     dispatcher.map((item) => ({ dmName: item.value, dmId: item.key }));

//   const createManager = async () => {
//     await fetch(process.env.REACT_APP_URL + "managers/register", {
//       // await fetch('http://localhost:5051/managers/register', {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
//       },
//       body: JSON.stringify({
//         name: dataInsert.name,
//         mobile: dataInsert.mobile,
//         loginId: dataInsert.loginId,
//         password:
//           dataInsert.password === dataInsert.confirmPassword
//             ? dataInsert.password
//             : alert("Password Does Not Match"),
//         designation: dtn,
//         status: dataInsert.status,
//         selectedWarehouse: warehousename,
//         selectedState: st,
//         selectedDM: selectedDM,
//       }),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.msg === "User Successfully Created!") {
//           setDataInsert("");
//           setDesignation([{ designation: [""] }]);
//           setStateIndia("");
//           setWarehouse("");
//           setDispatcher("");

//           // alert( data )
//           // console.log( data )
//         } else {
//           alert(data.msg);
//           // console.log( data.msg )
//         }
//       });
//   };

//   const handleChangeField = (e, index) => {
//     const { name, value } = e.target;
//     const list = [...designation];
//     list[index][name] = value;
//     setDesignation(list);
//   };

//   function handleAdd() {
//     const values = [...designation];
//     values.push({ designation: "" });
//     setDesignation(values);
//   }

//   function handleRemove(i) {
//     const values = [...designation];
//     values.splice(i, 1);
//     setDesignation(values);
//   }

//   const allOption = {
//     label: "All",
//     value: "*",
//   };

//   const optionsWare = warehouseList.map((item, index) => ({
//     value: item.warehousename,
//     key: index,
//     label: item.warehousename,
//   }));
//   // const optionsManagers = managerList.map((item, index) => ({ value: item.name, key: index, label: item.name }))

//   const stateOpt = IndianState.map((state) => state);

//   const handleChangeStates = (e) => {
//     setStateIndia(e);
//     const allFinder = e.map((item) => item.label);
//     allFinder.includes("All") ? setStateIndia(stateOpt) : setStateIndia(e);
//   };
//   const handleUpdateManager = () => {
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       managerId: managerId,
//       id: manager_Id,
//       name: dataInsert.name,
//       mobile: dataInsert.mobile,
//       loginId: dataInsert.loginId,
//       password:
//         dataInsert.password === dataInsert.confirmPassword
//           ? dataInsert.password
//           : alert("Password Does Not Match"),
//       designation: dtn,
//       status: dataInsert.status,
//       selectedWarehouse: warehousename,
//       selectedState: st,
//       selectedDM: selectedDM,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(process.env.REACT_APP_URL + "managers/updateManager", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         alert(result.msg);
//         navigate("/managers");
//       })
//       .catch((error) => console.log("error", error));
//   };

//   console.log(showPassword);

//   return (
//     <React.Fragment>
//       <div noValidate className="textGray text-light w-100 p-3">
//         <p role="button" onClick={() => navigate(-1)}>
//           &lt;&nbsp;<span className="text-decoration-underline">Go Back</span>
//         </p>
//         <Row className={`${styles.bgColor} mx-0 fs_20 pb-5 pt-4`}>
//           <h2 className="text-center text-white mb-5 fs_30">
//             Create New Manager
//           </h2>
//           <Col md={6} className="ps-0">
//             <Form.Group
//               as={Row}
//               className="mb-3 align-items-center"
//               controlId="01"
//             >
//               <Form.Label column sm="3">
//                 Name
//               </Form.Label>
//               <Col sm="9">
//                 <Form.Control
//                   required
//                   name="name"
//                   value={dataInsert.name}
//                   onChange={(e) =>
//                     setDataInsert({
//                       ...dataInsert,
//                       name: e.target.value
//                         .toLocaleLowerCase()
//                         .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                           letter.toUpperCase()
//                         ),
//                     })
//                   }
//                   type="text"
//                   className="text-light bg-transparent"
//                   placeholder="Name"
//                 />
//               </Col>
//             </Form.Group>

//             <Form.Group
//               as={Row}
//               className="mb-3 align-items-center"
//               controlId="02"
//             >
//               <Form.Label column sm="3">
//                 Mobile Number
//               </Form.Label>
//               <Col sm="9">
//                 <Form.Control
//                   name="mobile"
//                   type="number"
//                   value={dataInsert.mobile}
//                   onChange={(e) =>
//                     setDataInsert({ ...dataInsert, mobile: e.target.value })
//                   }
//                   maxLength="10"
//                   onInput={(e) =>
//                     (e.target.value = e.target.value.slice(0, 10))
//                   }
//                   className="text-light bg-transparent"
//                   placeholder="Mobile Number"
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group
//               as={Row}
//               className="mb-3 align-items-center"
//               controlId="03"
//             >
//               <Form.Label column sm="3">
//                 Login Id
//               </Form.Label>
//               <Col sm="9">
//                 <Form.Control
//                   required
//                   name="loginId"
//                   type="text"
//                   value={dataInsert.loginId}
//                   onChange={(e) =>
//                     setDataInsert({ ...dataInsert, loginId: e.target.value })
//                   }
//                   className="text-light bg-transparent"
//                   placeholder="Login Id"
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group
//               as={Row}
//               className="mb-3 align-items-center"
//               controlId="04"
//             >
//               <Form.Label column sm="3">
//                 Password
//               </Form.Label>
//               <Col sm="9" className={styles.EyeIcon_div}>
//                 {showPassword ? (
//                   <span
//                     className={styles.EyeIcon}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     <Eye className={styles.iconColor} />
//                   </span>
//                 ) : (
//                   <span
//                     className={styles.EyeIcon}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     <EyeHide className={styles.iconColor} />
//                   </span>
//                 )}
//                 <Form.Control
//                   type={showPassword ? "text" : "password"}
//                   required
//                   name="password"
//                   value={dataInsert.password}
//                   autoComplete={dataInsert.password.toString()}
//                   onChange={(e) =>
//                     setDataInsert({ ...dataInsert, password: e.target.value })
//                   }
//                   className="text-light bg-transparent"
//                   placeholder="New Password"
//                 />
//               </Col>
//             </Form.Group>
//             <Form.Group
//               as={Row}
//               className="mb-3 align-items-center"
//               controlId="05"
//             >
//               <Form.Label column sm="3">
//                 Confirm Password
//               </Form.Label>
//               <Col sm="9" className={styles.EyeIcon_div}>
//                 {showConfirmPassword ? (
//                   <span
//                     className={styles.EyeIcon}
//                     onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                   >
//                     <Eye className={styles.iconColor} />
//                   </span>
//                 ) : (
//                   <span
//                     className={styles.EyeIcon}
//                     onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                   >
//                     <EyeHide className={styles.iconColor} />
//                   </span>
//                 )}
//                 <Form.Control
//                   // autoComplete={dataInsert.confirmPassword.toString()}
//                   required
//                   name="confirmPassword"
//                   value={dataInsert.confirmPassword}
//                   onChange={(e) =>
//                     setDataInsert({
//                       ...dataInsert,
//                       confirmPassword: e.target.value,
//                     })
//                   }
//                   type={showConfirmPassword ? "text" : "password"}
//                   className="text-light bg-transparent"
//                   placeholder="Confirm Password"
//                 />
//                 {dataInsert.confirmPassword !== dataInsert.password ? (
//                   <p className="text-danger m-0 p-0">Password does not match</p>
//                 ) : null}
//               </Col>
//             </Form.Group>
//             <Form.Group
//               name="status"
//               required
//               as={Row}
//               className="mb-3 align-items-center"
//               controlId="06"
//             >
//               <Form.Label column sm="3">
//                 Status
//               </Form.Label>
//               <Col sm="2">
//                 <Form.Check
//                   type="radio"
//                   label="Active"
//                   value="active"
//                   name="formHorizontalRadios"
//                   id="formHorizontalRadios2"
//                   onChange={(e) =>
//                     setDataInsert({ ...dataInsert, status: e.target.value })
//                   }
//                   checked={dataInsert.status === "active" ? true : false}
//                 />
//               </Col>
//               <Col sm="7">
//                 <Form.Check
//                   type="radio"
//                   label="Disable"
//                   value="disable"
//                   name="formHorizontalRadios"
//                   id="formHorizontalRadios2"
//                   onChange={(e) =>
//                     setDataInsert({ ...dataInsert, status: e.target.value })
//                   }
//                   checked={dataInsert.status === "disable" ? true : false}
//                 />
//               </Col>
//             </Form.Group>
//           </Col>
//           <Col md={{ span: 6 }} className="ps-0">
//             {designation.map((item, index) => (
//               <div key={index}>
//                 <Form.Group
//                   as={Row}
//                   className="align-items-center mb-2"
//                   controlId="02"
//                 >
//                   <Form.Label column sm="3">
//                     Designation
//                   </Form.Label>
//                   <Col sm="8">
//                     <Form.Select
//                       value={item.designation}
//                       name="designation"
//                       className={styles.optionsBgColor}
//                       onChange={(e) => handleChangeField(e, index)}
//                       required
//                     >
//                       <option value="">Select Designation</option>
//                       <option value="superadmin">Super Admin</option>
//                       <option value="ordermanager">Order Manager</option>
//                       <option value="dispatchmanager">Dispatch Manager</option>
//                       <option value="inventorymanager">
//                         Inventory Manager
//                       </option>
//                       <option value="packingmanager">Packer</option>
//                       <option value="contentmanager">Content Manager</option>
//                       <option value="paymentmanager">Payment Manager</option>
//                     </Form.Select>
//                   </Col>
//                   {item.designation.length === 0 ? null : (
//                     <Col sm="1 p-0">
//                       {/* <img src="/assets/icons/RedDelete.svg" alt="RedDelete" className="deleteIconMang" onClick={() => handleRemove(index)} /> */}
//                       <span role="button" onClick={() => handleRemove(index)}>
//                         <DeleteIcon />
//                       </span>
//                     </Col>
//                   )}
//                 </Form.Group>
//                 {item.designation === "ordermanager" && (
//                   <>
//                     <Form.Group
//                       as={Row}
//                       className="mb-3 align-items-center"
//                       controlId="02"
//                     >
//                       <Form.Label column sm="3">
//                         Assign State
//                       </Form.Label>
//                       <Col sm="9">
//                         <Select
//                           closeMenuOnSelect={false}
//                           value={stateIndia}
//                           onChange={(e) => handleChangeStates(e)}
//                           isMulti
//                           allowSelectAll={true}
//                           name="colors"
//                           className="basic-multi-select text-dark cpm "
//                           options={[allOption, ...stateOpt]}
//                         />
//                       </Col>
//                     </Form.Group>
//                   </>
//                 )}
//                 {(item.designation === "dispatchmanager" ||
//                   item.designation === "inventorymanager") && (
//                   <Form.Group
//                     as={Row}
//                     className="mb-3 align-items-center"
//                     controlId="03"
//                   >
//                     <Form.Label column sm="3">
//                       Assign Warehouse
//                     </Form.Label>
//                     <Col sm="9">
//                       <Select
//                         value={warehouse}
//                         closeMenuOnSelect={false}
//                         onChange={(e) => setWarehouse(e)}
//                         isMulti
//                         name="colors"
//                         className="basic-multi-select text-dark cpm "
//                         options={optionsWare}
//                       />
//                     </Col>
//                   </Form.Group>
//                 )}

//                 {item.designation === "paymentmanager" && (
//                   <Form.Group
//                     as={Row}
//                     className="mb-3 align-items-center"
//                     controlId="03"
//                   >
//                     <Form.Label column sm="3">
//                       Payment Warehouse
//                     </Form.Label>
//                     <Col sm="9">
//                       <Select
//                         value={paymentWarehouse}
//                         closeMenuOnSelect={false}
//                         onChange={(e) => setPaymentWarehouse(e)}
//                         isMulti
//                         name="colors"
//                         className="basic-multi-select text-dark cpm "
//                         options={optionsWare}
//                       />
//                     </Col>
//                   </Form.Group>
//                 )}

//                 {item.designation === "packingmanager" && (
//                   <Form.Group
//                     as={Row}
//                     className="mb-3 align-items-center"
//                     controlId="03"
//                   >
//                     <Form.Label column sm="3">
//                       Assign Manager
//                     </Form.Label>
//                     <Col sm="9">
//                       <Select
//                         closeMenuOnSelect={false}
//                         value={dispatcher}
//                         onChange={(e) => setDispatcher(e)}
//                         isMulti
//                         name="colors"
//                         className="basic-multi-select text-dark cpm "
//                         options={[dispatchManagers, ...dispatcher]}
//                       />
//                     </Col>
//                   </Form.Group>
//                 )}
//               </div>
//             ))}
//             <Form.Group as={Row} className="mb-3" controlId="02">
//               <Form.Label className="text-end">
//                 +
//                 <u onClick={() => handleAdd()} className="mouse">
//                   Add More Roles
//                 </u>
//               </Form.Label>
//             </Form.Group>
//           </Col>
//           <div className="text-center">
//             <RedOutButton
//               title="CANCEL"
//               css={`
//                 ${styles.CreateManagerBtn} me-5
//               `}
//               btnType="button"
//               handleSubmit={() => navigate("/managers")}
//             />
//             <GreenButton
//               title={manager_Id ? "UPDATE" : "CREATE"}
//               css={`
//                 ${styles.CreateManagerBtn} ms-5
//               `}
//               btnType="button"
//               handleSubmit={() => {
//                 if (manager_Id) {
//                   handleUpdateManager();
//                 } else {
//                   createManager();
//                 }
//               }}
//             />
//           </div>
//         </Row>
//       </div>
//     </React.Fragment>
//   );
// };

// export default AddManager;
