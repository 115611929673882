import React, { useEffect, useRef, useState } from 'react'
import styles from './SaleOrderMob.module.css'
import ModalMob from '../components/ModalMob'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CircleAddIcon, CloseWhiteIcon, CopyColorIcon, EmailColorIcon, MessageColorIcon, Spinner, WhatsAppIcon, WhatsappIcon } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
import { useSearchParams } from 'react-router-dom';
import LiveSearchMob from '../components/LiveSearchMob';
import PaymentModalMob from '../components/PaymentModalMob';
import AddDealerModalMob from '../components/AddDealerModalMob';
import Modal from '../../components/Modal';
import { GreenButton, RedOutButton } from '../../components/Buttons';
import AddItemModalMob from '../components/AddItemModalMob';
import Alert from '../../components/Alert';

const SaleOrderMob = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { managerId, designation } = useAuth()
    const formRef = useRef(null);
    const [paymentLink, setPaymentLink] = useState('');
    const [showPayment, setShowPayment] = useState(false)
    const [sendPaymnetLinkNumber, setSendPaymnetLinkNumber] = useState('')
    const [showElement, setShowElement] = useState(false)
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId') ? searchParams.get('orderId') : ""
    const method = searchParams.get('method') ? searchParams.get('method') : ""
    // const [showTab, setShowTab] = useState("sale")
    const [showAcceptModal, setShowAcceptModal] = useState(false)
    const [step, setStep] = useState(0)
    const [searchDealer, setSearchDealer] = useState("")
    const [dealers, setDealers] = useState([])
    const [generalInfo, setGeneralInfo] = useState("")
    const [shippingInfo, setShippingInfo] = useState("")
    const [warehouses, setWarehouses] = useState([])
    const [dispatchManagers, setDispatchManagers] = useState([])
    // const [couriers, setCouriers] = useState([])
    // const [partners, setPartners] = useState("")
    const [results, setResults] = useState()
    const [searchItem, setSearchItem] = useState("")
    // const [wareHouseWise, setWareHouseWise] = useState("")
    const [showAddItem, setShowAddItem] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reciptsInfo, setReciptsInfo] = useState([])
    const [reload, setReload] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
    const [loadingReceipt, setLoadingReceipt] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [addDealer, setAddDealer] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)
    const [nextBtn, setNextBtn] = useState(false)
    const [methodPartners, setMethodPartners] = useState([])
    const [showRequestForCancel, setShowRequestForCancel] = useState(false)
    const [reqIssue, setReqIssue] = useState('')
    const [loadingCancelBtn, setLoadingCancelBtn] = useState(false)
    const [reciptData, setReciptData] = useState({
        paymentMethod: 'Cash',
        paymentType: '',
        paymentAmount: '',
        transactionId: '',
        accountNumber: '',
    })

    function enforce_maxlength(e) {
        var t = e.target;
        if (t.hasAttribute('maxlength')) {
            t.value = t.value.slice(0, t.getAttribute('maxlength'));
        }
    }

    const descRef = useRef()
    const priceRef = useRef()
    const unitRef = useRef()
    const qtyFocus = useRef()
    const paymentRef = useRef(null)
    const itemseacrhRef = useRef(null)
    const cartTypeRef = useRef(null)

    const [error] = useState({
        dp: "",
        index: ""
    })
    // const [error, setErrors] = useState({
    //     dp: "",
    //     index: ""
    // })

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...itemsdetails];
        list[index][name] = value;
        setItemsdetails(list);
    };

    const [itemsdetails, setItemsdetails] = useState([
        {
            accessories: [],
            itemNo: "",
            product_name: "",
            collection_name: "",
            warehouse: "",
            cartType: "",
            qty: "",
            dp: "",
            unit: "",
            amount: "",
            totalQty: "",
            desc: "",
            descKey: ""
        }
    ]);

    useEffect(() => {
        if (!orderId) {
            setGeneralInfo("")
        }
    }, [orderId])

    useEffect(() => {
        if (managerId) {
            const fetchOrder = async () => {
                setLoading(true)
                var myHeaders = new Headers();
                myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "managerId": managerId,
                    "orderId": orderId,
                    "warehousename": ""
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                await fetch(process.env.REACT_APP_URL + "order/order", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status === "success") {
                            if (result.data.order) {
                                setGeneralInfo(prevState => ({
                                    ...prevState,
                                    dealerId: result.data.order.dealerId,
                                    storeName: result.data.order.storeName,
                                    dealerName: result.data.order.name,
                                    billingAddress: result.data.order.billingAddress,

                                    shippingType: result.data.order.shippingInfo.shippingType,
                                    shippingCharge: result.data.order.shippingInfo.shippingCharge,
                                    courierName: result.data.order.shippingInfo.courierName,
                                    partnerName: result.data.order.shippingInfo.methodName ? result.data.order.shippingInfo.methodName : result.data.order.shippingInfo.partnerName,
                                    deliveryPartner: result.data.order.shippingInfo.methodName ? result.data.order.shippingInfo.methodName : result.data.order.shippingInfo.partnerName,
                                    whatsapp: result.data.order.DealerInfo.whatsapp ? result.data.order.DealerInfo.whatsapp : result.data.order.shippingInfo.mobile,

                                    warehousename: result.data.order.warehousename ? result.data.order.warehousename : "",
                                    dmId: result.data.order.dmId,
                                    dmName: result.data.order.dmName,
                                    cartType: result.data.order.cartType === "Cart" ? 'ocart' : 'pcart',
                                    orderDate: result.data.order.orderDate,
                                    orderId: result.data.order.orderId,

                                    transactionScreenshot: result.data.order.transactionScreenshot,
                                    paymentMethod: result.data.order.paymentMethod,
                                    paymentType: result.data.order.paymentType,
                                    paymentStatus: result.data.order.paymentStatus,
                                    paymentAmount: result.data.order.paymentAmount,
                                    transferTransId: result.data.order.transferTransId,

                                    status: result.data.order.status,
                                    manageName: result.data.order.manageName,
                                    manageValue: result.data.order.manageValue,
                                    orderType: result.data.order.orderType,
                                    toPay: result.data.order.toPay,
                                    dispatchStatus: result.data.order.dispatchStatus,
                                    reqStatus: result.data.order.reqStatus,
                                    reqType: result.data.order.reqType,
                                }))
                                setShippingInfo(result.data.order.shippingInfo)
                                setItemsdetails(result.data.order.items)
                                setReciptsInfo(result.data.receipts)
                            }
                        }
                    })
                    .catch(error => console.log('error', error))
                    .finally(() => setLoading(false))
            }
            fetchOrder()
        }

    }, [orderId, managerId, reload])


    useEffect(() => {
        // if ((generalInfo.dispatchStatus === '' || generalInfo.dispatchStatus === null || generalInfo.dispatchStatus === undefined) && shippingInfo.pincode && shippingInfo.pincode.length === 6) {
        // setLoadingLocation(true)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "keyword": shippingInfo.pincode,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setShippingInfo(prevState => ({
                        ...prevState,
                        city: result.SubDistName[0] ? result.SubDistName[0].toUpperCase() : "",
                        district: result.districts[0] ? result.districts[0] : "",
                        state: result.states[0] ? result.states[0] : "",
                    }))
                    setLoadingLocation(false)
                }
            })
            .catch(error => console.log('error', error));
        // }
    }, [shippingInfo.pincode, generalInfo.dispatchStatus])

    // useEffect(() => {
    //     if (managerId) {
    //         var myHeaders = new Headers();
    //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "courierName": generalInfo.courierName
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch(process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod", requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 if (result.status === "success") {
    //                     if (!generalInfo.courierName) {
    //                         setCouriers(result.data)
    //                         // if( result.data.courierName)
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             shippingType: "Standard",
    //                             courierName: result.data[0].courierName
    //                         }))
    //                     }
    //                     else if (generalInfo.courierName === "Vehicle") {
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             partnerName: "Auto"
    //                         }))
    //                         setPartners(result.data)
    //                     }
    //                     else {
    //                         setPartners(result.data)
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             partnerName: result.data.partners[0].partnerName
    //                         }))
    //                     }
    //                 }
    //             })
    //             .catch(error => console.log('error', error));
    //     }
    // }, [managerId, generalInfo.courierName])



    const handleSubmitStep0 = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setStep(1)
        }
    }

    const handleSubmitStep1 = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setStep(2)
            orderDeliveryMethod()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleSaleOrder("success")
        }
    }

    let cartPriceSum = itemsdetails && itemsdetails.reduce((sum, currentItem) => {
        return sum + (parseFloat(currentItem.price ? currentItem.price : 0) * parseFloat(currentItem.qty ? currentItem.qty : 0));
    }, 0)

    var cartTotal = itemsdetails && itemsdetails.map(x => !x.accessories ? 0 : x.accessories.map(y => (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
    let subtotal = (cartPriceSum ? cartPriceSum : 0) + (cartTotal ? cartTotal : 0)
    let gettotalAmount = subtotal + parseFloat(generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.toPay ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0)))) + parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0)

    let totalAmount = Math.round(gettotalAmount)
    let roundedAmt = totalAmount ? (totalAmount - gettotalAmount).toFixed(2) : 0

    const handleRemoveClick = index => {
        const list = [...itemsdetails];
        list.splice(index, 1);
        setItemsdetails(list);
    };

    useEffect(() => {
        if (searchDealer) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "keyword": searchDealer
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/finddealer", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data.length > 0) {
                            setDealers(result.data)
                            setShowAcceptModal(true)
                        } else {
                            setShowAcceptModal(false)
                            setDealers([])
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [searchDealer, managerId])

    useEffect(() => {
        if (managerId) {
            // setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "warehousename": generalInfo.warehousename
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/order", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data.warehouses) {
                            setWarehouses(result.data.warehouses)
                        }
                        if (result.data.dispatchmanagers.length > 0) {
                            setDispatchManagers(result.data.dispatchmanagers)
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                dmId: result.data.dispatchmanagers[0].managerId
                            }))
                        }
                        setAccounts(result.data.accounts)
                    }
                })
                .catch(error => console.log('error', error))
                .finally(() => setLoading(false))
        }

    }, [orderId, managerId, generalInfo.warehousename,])

    const orderSubMethod = (selectedValue) => {
        // alert(selectedValue)
        if (shippingInfo.pincode && selectedValue && selectedValue !== 'Self') {
            // setMethodChange(false)
            setMethodPartners([])
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "courierId": '',
                "courierName": '',
                "shipPincode": shippingInfo.pincode,
                "warehousename": generalInfo.warehousename,
                "methodName": selectedValue,
                "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
                "selectedItems": itemsdetails
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.methodSelection === 'Active') {
                            setMethodPartners(result.deliveryMethod)
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                                courierName: result.courierName ? result.courierName : '',
                                deliveryPartner: result.data.methodName ? result.data.methodName : '',
                                deliveryCharge: result.data.deliveryCharge,
                            }))
                        }
                        else if (result.methodSelection === "Disable") {
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                shippingType: "Standard",
                                cartType: "ocart",
                                courierName: result.data.courierName,
                                partnerName: result.data.methodName,
                                deliveryCharge: result.data.deliveryCharge,
                                deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                            }))
                        }
                        setNextBtn(true)
                        setLoadingBtn(false)
                    } else {
                        setMethodPartners(result.deliveryMethod)
                        setGeneralInfo(prevState => ({
                            ...prevState,
                            methodSelection: result.methodSelection ? result.methodSelection : '',
                            courierName: result.courierName ? result.courierName : '',
                            deliveryPartner: '',
                            deliveryCharge: '',
                        }))
                        alert(result.msg)
                        // setMethodChange(true)
                    }
                })
                .catch(error => console.log('error', error))
                .finally(() => setLoadingBtn(false))
        }
    }

    // const deliveryMethodClear = () => {

    //     setGeneralInfo(prevState => ({
    //         ...prevState,
    //         courierName: '',
    //         deliveryPartner: '',
    //         partnerName: '',
    //     }))

    //     var myHeaders = new Headers();
    //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "courierId": '',
    //         "courierName": '',
    //         "shipPincode": shippingInfo.pincode,
    //         "warehousename": generalInfo.warehousename,
    //         "methodName": '',
    //         "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
    //         "selectedItems": itemsdetails
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === "success") {
    //                 if (result.data.length > 1) {
    //                     setMethodPartners(result.data)
    //                     setGeneralInfo(prevState => ({
    //                         ...prevState,
    //                         methodSelection: result.methodSelection ? result.methodSelection : '',
    //                     }))
    //                 }
    //                 else {
    //                     setGeneralInfo(prevState => ({
    //                         ...prevState,
    //                         shippingType: "Standard",
    //                         cartType: "ocart",
    //                         courierName: result.data.courierName,
    //                         partnerName: result.data.methodName,
    //                         deliveryCharge: result.data.deliveryCharge,
    //                         deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
    //                         methodSelection: result.methodSelection ? result.methodSelection : '',
    //                     }))
    //                 }
    //                 setNextBtn(true)
    //                 setLoadingBtn(false)
    //             }
    //         })
    //         .catch(error => console.log('error', error))
    //         .finally(() => setLoadingBtn(false))
    //     // }
    // }

    const orderDeliveryMethod = () => {
        if (generalInfo.warehousename.trim() === '') {
            alert('Please select a warehouse before next button.');
            return;
        }
        setLoadingBtn(true)
        // setMethodChange(false)
        if (shippingInfo.pincode) {
            setMethodPartners([])
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "courierId": '',
                "courierName": '',
                "shipPincode": shippingInfo.pincode,
                "warehousename": generalInfo.warehousename,
                "methodName": generalInfo.deliveryPartner,
                "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
                "selectedItems": itemsdetails
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.methodSelection === 'Active') {
                            setMethodPartners(result.deliveryMethod)
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                                courierName: result.courierName ? result.courierName : '',
                                deliveryPartner: result.data.methodName ? result.data.methodName : '',
                                deliveryCharge: result.data.deliveryCharge,
                            }))
                        }
                        else if (result.methodSelection === "Disable") {
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                shippingType: "Standard",
                                cartType: "ocart",
                                courierName: result.data.courierName,
                                partnerName: result.data.methodName,
                                deliveryCharge: result.data.deliveryCharge,
                                deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                            }))
                        }
                        setNextBtn(true)
                        setLoadingBtn(false)
                    } else {
                        alert(result.msg)
                        // setMethodChange(true)
                    }
                })
                .catch(error => console.log('error', error))
                .finally(() => setLoadingBtn(false))
        }
    }

    // useEffect(() => {
    //     /* eslint-disable */
    //     if ((generalInfo.dispatchStatus === '' || generalInfo.dispatchStatus === null || generalInfo.dispatchStatus === undefined) &&
    //     nextBtn && generalInfo.courierName !== 'Pick Up' && generalInfo.deliveryPartner !== 'Self' && generalInfo.orderType !== 'online' && managerId && shippingInfo.pincode && generalInfo.warehousename && shippingInfo.street && itemsdetails && itemsdetails[0].qty) {
    //         setMethodPartners([])
    //         var myHeaders = new Headers();
    //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "courierId": generalInfo.courierId,
    //             "courierName": generalInfo.courierName,
    //             "shipPincode": shippingInfo.pincode,
    //             "warehousename": generalInfo.warehousename,
    //             "methodName": generalInfo.deliveryPartner,
    //             // "cartType": generalInfo.cartType,
    //             "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
    //             "selectedItems": itemsdetails
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 if (result.status === "success") {
    //                     if (result.data.length > 1) {
    //                         setCouriers(result.data)
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             shippingType: "Standard",
    //                             cartType: "ocart",
    //                         }))
    //                     }
    //                     else if (result.data.methods) {
    //                         setMethodPartners(result.data.methods)
    //                     }
    //                     else {
    //                         // if (result.data.methodPartners && result.data.methodPartners.length !== 0) {
    //                         //     setMethodPartners(result.data.methodPartners)
    //                         //     setGeneralInfo(prevState => ({
    //                         //         ...prevState,
    //                         //         shippingType: "Standard",
    //                         //         cartType: "ocart",
    //                         //         courierName: result.data.courierName,
    //                         //         partnerName: '',
    //                         //         deliveryCharge: result.data.deliveryCharge,
    //                         //         methodName: result.data.methodName? result.data.methodName:''
    //                         //     }))
    //                         // } else {
    //                             setGeneralInfo(prevState => ({
    //                                 ...prevState,
    //                                 shippingType: "Standard",
    //                                 cartType: "ocart",
    //                                 courierName: result.data.courierName,
    //                                 partnerName: result.data.methodName,
    //                                 deliveryCharge: result.data.deliveryCharge,
    //                                 deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
    //                             }))
    //                         }
    //                     }
    //                 // }
    //             })
    //             .catch(error => console.log('error', error));
    //     }
    // }, [generalInfo.dispatchStatus, generalInfo.partnerName, generalInfo.orderType, managerId, shippingInfo.pincode, generalInfo.warehousename, shippingInfo.street, shippingInfo.city, shippingInfo.district, shippingInfo.state, generalInfo.courierName, generalInfo.courierId, generalInfo.deliveryPartner])

    // const orderDeliveryMethod = () => {
    //     setLoadingBtn(true)
    //     if (shippingInfo.pincode) {
    //         setMethodPartners([])
    //         var myHeaders = new Headers();
    //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "courierId": generalInfo.courierId,
    //             "courierName": generalInfo.courierName,
    //             "shipPincode": shippingInfo.pincode,
    //             "warehousename": generalInfo.warehousename,
    //             "methodName": generalInfo.deliveryPartner,
    //             // "cartType": generalInfo.cartType,
    //             "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
    //             "selectedItems": itemsdetails
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 if (result.status === "success") {
    //                     if (result.data.length > 1) {
    //                         setCouriers(result.data)
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             shippingType: "Standard",
    //                             cartType: "ocart",
    //                         }))
    //                     }
    //                     else if (result.data.methods) {
    //                         setMethodPartners(result.data.methods)
    //                     }
    //                     else {
    //                         // if (result.data.methodPartners && result.data.methodPartners.length !== 0) {
    //                         //     setMethodPartners(result.data.methodPartners)
    //                         //     setGeneralInfo(prevState => ({
    //                         //         ...prevState,
    //                         //         shippingType: "Standard",
    //                         //         cartType: "ocart",
    //                         //         courierName: result.data.courierName,
    //                         //         partnerName: '',
    //                         //         deliveryCharge: result.data.deliveryCharge,
    //                         //         methodName: result.data.methodName? result.data.methodName:''
    //                         //     }))
    //                         // } else {
    //                             setGeneralInfo(prevState => ({
    //                                 ...prevState,
    //                                 shippingType: "Standard",
    //                                 cartType: "ocart",
    //                                 courierName: result.data.courierName,
    //                                 partnerName: result.data.methodName,
    //                                 deliveryCharge: result.data.deliveryCharge,
    //                                 deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
    //                             }))
    //                         }
    //                     // }
    //                     setNextBtn(true)
    //                     setLoadingBtn(false)
    //                 }
    //             })
    //             .catch(error => console.log('error', error))
    //             .finally(() => setLoadingBtn(false))
    //     }
    // }

    // useEffect(() => {
    //     if (managerId) {
    //         var myHeaders = new Headers();
    //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "courierName": generalInfo.courierName
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch(process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod", requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 if (result.status === "success") {
    //                     if (!generalInfo.courierName) {
    //                         setCouriers(result.data)
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             shippingType: "Standard",
    //                             courierName: result.data[0].courierName
    //                         }))
    //                     }
    //                     else if (generalInfo.courierName === "Vehicle") {
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             partnerName: "Auto"
    //                         }))
    //                         setPartners(result.data)
    //                     }
    //                     else {
    //                         setPartners(result.data)
    //                         setGeneralInfo(prevState => ({
    //                             ...prevState,
    //                             partnerName: result.data.partners[0].partnerName
    //                         }))
    //                     }
    //                 }
    //             })
    //             .catch(error => console.log('error', error));
    //     }
    // }, [managerId, generalInfo.courierName])

    useEffect(() => {
        if (!searchItem) {
            setResults([])
        }

        if (searchItem) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "keyword": searchItem,
                // "cartType": generalInfo.cartType,
                // "warehousename": wareHouseWise,
                "orderType": generalInfo.orderType,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/searchitems?page=", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setResults(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [searchItem, managerId, generalInfo.orderType, generalInfo.cartType])

    const handleSaleOrder = (status) => {
        if (error.dp) {
            alert(error.dp)
        }
        else if (totalAmount < 0) {
            alert("Total amount must be greater than zero")
        }
        // else if (status === "success" && (parseFloat(totalAmount).toFixed(2) !== 
        //     parseFloat(generalInfo.paymentAmount).toFixed(2))) {
        //     alert("Payment amount is not matching with total amount")
        // }
        else {
            setLoadingBtn(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "warehousename": generalInfo.warehousename,
                "dmId": generalInfo.dmId,
                "cartType": generalInfo.cartType,
                "dealerId": generalInfo.dealerId,
                "name": shippingInfo.name,
                "street": shippingInfo.street,
                "mobile": shippingInfo.mobile,
                "pincode": shippingInfo.pincode,
                "city": shippingInfo.city,
                "district": shippingInfo.district,
                "state": shippingInfo.state,
                "shippingType": generalInfo.shippingType,
                "shippingCharge": generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge),
                "toPay": generalInfo.toPay,
                "courierName": generalInfo.deliveryPartner === 'Self' ? 'Pick Up' : generalInfo.courierName,
                "methodName": generalInfo.courierName === 'Pick Up' ? 'Self' : (generalInfo.methodName ? generalInfo.methodName : generalInfo.deliveryPartner),
                "partnerName": generalInfo.deliveryPartner ? generalInfo.deliveryPartner : generalInfo.partnerName,
                "manageName": generalInfo.manageName,
                "manageValue": generalInfo.manageValue,
                "saleItems": itemsdetails,
                "status": status,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/saleOrder", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setLoadingBtn(false)
                        setReload(true)
                        alert(result.msg)
                        if (status === "success") {
                            if (!orderId) {
                                navigate("/orders/saleorder?orderId=" + result.data.orderId)
                                setShowPaymentModal(true);
                            }
                            // handleOfllinePayment({ orderId: result.data.orderId, status })
                        }
                        if (status === "draft") {
                            navigate("/orders")
                        }
                    }
                    else if (result.status === "failed") {
                        setLoadingBtn(false)
                        if (result.msg === "Select Dispatch Warehouse") {
                            alert(result.msg + ' / Select Item')
                            setStep(1)

                        }
                    } else {

                        alert(result.msg)
                    }
                })
                .catch(error => console.log('error', error))
            // .finally(() => setLoading(false))
        }
    }

    const handleAddClick = () => {
        if (error.dp) {
            alert(error.dp)
        } else {
            setSearchItem("");
            setItemsdetails([...itemsdetails,
            { itemNo: "", product_name: "", collection_name: "", qty: "", price: "", unit: "", amount: "" }]);
        }
    };

    let typeStatus = !generalInfo.status ? true : generalInfo.status === "In Progress" ? true : generalInfo.status === "Draft" ? true : generalInfo.status === "Payment Pending" ? true : false

    const resultArray = [];

    itemsdetails.length > 0 && itemsdetails.forEach(item => {
        //for each item in arrayOfObjects check if the object exists in the resulting array
        if (resultArray.find(object => {
            if (object.warehouse === item.warehouse) {
                //if the object exists iterate times
                object.times++;
                return true;
                //if it does not return false
            } else {
                return false;
            }
        })) {
        } else {
            //if the object does not exists push it to the resulting array and set the times count to 1
            item.times = 1;
            resultArray.push(item);
        }
    })

    const closest = resultArray.length > 0 ? resultArray.reduce(
        (acc, loc) =>
            acc.times > loc.times
                ? acc
                : loc
    ) : null;

    useEffect(() => {
        setGeneralInfo(prevState => ({
            ...prevState,
            warehousename: closest.warehouse ? closest.warehouse : (itemsdetails && itemsdetails.length !== 0) && itemsdetails[0].warehouse
        }))
    }, [closest.warehouse, itemsdetails])

    const handleCancelOrder = () => {
        setLoadingCancelBtn(true)
        // setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/cancelOrder", requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.msg)
                if (result.status === 'success') {
                    setShowCancelOrderModal(false)
                    setLoadingCancelBtn(false)
                    navigate("/orders")
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleRequestForCancel = () => {
        // setMsgShow("")
        if (reqIssue) {
            setLoadingCancelBtn(true)
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "reqType": "Request For Cancel",
                "reqIssue": reqIssue
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(process.env.REACT_APP_URL + "order/orderhelp", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === 'success') {
                        setShowRequestForCancel(false)
                        setLoadingCancelBtn(false)
                        alert(result.msg)
                        window.location.reload();
                    } else {
                        alert(result.msg)
                        setShowRequestForCancel(false)
                        setLoadingCancelBtn(false)
                    }
                })
                .catch((error) => console.error(error));
        } else {
            alert("Please Enter Issue")
        }
    }

    const [isImage, setisImage] = useState();

    const handleRecipt = () => {
        setLoadingReceipt(true)
        setReload(false)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("transactionScreenshot", isImage ? [...isImage][0] : '');
        formdata.append("paymentMethod", reciptData.paymentMethod ? reciptData.paymentMethod : '');
        formdata.append("paymentType", reciptData.paymentType ? reciptData.paymentType : '');
        formdata.append("paymentAmount", reciptData.paymentAmount ? parseFloat(reciptData.paymentAmount).toFixed(2) : '');
        formdata.append("transactionId", reciptData.transactionId ? reciptData.transactionId : '');
        formdata.append("accountNumber", reciptData.accountNumber ? reciptData.accountNumber : '');
        formdata.append("totalAmount", totalAmount ? totalAmount.toFixed(2) : '');
        formdata.append("cartType", generalInfo.cartType);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/offlinePay/" + managerId + "/" + orderId + "?receiptId=" + reciptData.receiptId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setShowPaymentModal(false)
                    setReload(true)
                }
                alert(result.msg)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoadingReceipt(false))
    }

    let payStatus = !generalInfo.status ? false :
        generalInfo.status === "In Progress" ? false :
            generalInfo.status === "Cancelled" ? false :
                true

    useEffect(() => {
        if (totalAmount < 0) {
            alert("Total amount must be greater than zero")
            setGeneralInfo(prevState => ({
                ...prevState,
                manageValue: ""
            }))
        }
    }, [totalAmount])

    const handleSendLink = () => {
        setPaymentLink('')
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "orderId": orderId,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setPaymentLink(result.shortUrl)
                    setShowPayment(true)
                }
            })
            .catch(error => console.log('error', error));
    }

    const sendLink = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": sendPaymnetLinkNumber,
            "templateName": "order_payment",
            "headerData": "https://dealer.wallicon.in/assets/paymentlink.jpg",
            "buttonDataPayload": paymentLink
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (result.messages[0].message_status === 'accepted') {
                    alert('Payment Link Sent Successfully')
                    setShowPayment(false)
                }
            })
            .catch((error) => console.error(error));
    }
    const sendPaymentLink = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": generalInfo.whatsapp,
            "templateName": "order_payment",
            "headerData": "https://dealer.wallicon.in/assets/paymentlink.jpg",
            "buttonDataPayload": paymentLink
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (result.messages[0].message_status === 'accepted') {
                    alert('Payment Link Sent Successfully')
                    setShowPayment(false)
                }
            })
            .catch((error) => console.error(error));
    }

    const [warehouseName, setWarehouseName] = useState()

    const handleNextButtonClick = () => {
        console.log("handleNextButtonClick called");
        console.log("generalInfo.warehousename", generalInfo.warehousename);
        console.log("warehouseName", warehouseName);

        if (!generalInfo.warehousename) {
            if (formRef.current) {
                if (formRef.current.checkValidity()) {
                    // Proceed to the next step
                    if (generalInfo.deliveryPartner !== 'Self' && generalInfo.partnerName !== 'Self') {
                        console.log("Ordering delivery method1");
                        setGeneralInfo(prevState => ({
                            ...prevState,
                            warehousename: warehouseName
                        }))
                        orderDeliveryMethod();
                    } else {
                        console.log("Setting nextBtn to true");
                        setNextBtn(true);
                    }
                } else {
                    // If form is invalid, trigger form validation
                    console.log("Form is invalid");
                    formRef.current.reportValidity();
                }
            } else {
                console.log("formRef.current is null");
            }
        } else {
            if (generalInfo.deliveryPartner !== 'Self' && generalInfo.partnerName !== 'Self') {
                console.log("Ordering delivery method2");
                orderDeliveryMethod();
            } else {
                console.log("Setting nextBtn to true");
                setNextBtn(true);
            }
        }
    }


    return (
        <div>
            <Alert
                showElement={showElement}
                setShowElement={() => setShowElement(false)}
                msg={'Copied'}
            />
            {addDealer && <AddDealerModalMob setGeneralInfo={setGeneralInfo} close={setAddDealer} show={addDealer} />}
            <AddItemModalMob
                showAddItem={showAddItem}
                setShowAddItem={setShowAddItem}
                setItemsdetails={setItemsdetails}
                index={itemsdetails.length - 1}
            />
            {(step === 0) &&
                <div className={styles.dashboard_select_overall}>
                    {(designation === 'ordermanager' || designation === 'superadmin' || designation === 'inventorymanager') &&
                        <>
                            <div
                                className={`${styles.dashboard_overall_select} ${(pathname === "/orders/saleorder") && styles.days_active}`}
                                onClick={() => { navigate("/orders/saleorder") }}>Sale Order</div>
                            <div className={`${styles.dashboard_overall_select} ${(pathname === "/orders/estimate") && styles.days_active}`}
                                onClick={() => { navigate("/orders/estimate") }}>Estimate</div>
                        </>
                    }
                    {(designation === 'inventorymanager' || designation === 'superadmin') &&
                        <>
                            <div
                                className={`${styles.dashboard_overall_select} ${pathname === "/orders/purchase" && styles.days_active}`}
                                onClick={() => { navigate("/orders/purchase") }}>Purchase
                            </div>
                            <div
                                className={`${styles.dashboard_overall_select}`}
                            >Adjustment
                            </div>
                            <div
                                className={`${styles.dashboard_overall_select}`}
                            >Transfer
                            </div>
                        </>
                    }
                </div>
            }

            {/* <form onSubmit={handleSubmit}> */}
            <div>
                {step === 0 ?
                    <form onSubmit={handleSubmitStep0} className={designation === 'superadmin' ? styles.order_container : styles.order_margin}>
                        <div className={styles.status_watermark}>
                            {generalInfo.reqStatus === "Pending" ? generalInfo.reqType : generalInfo.status}
                        </div>
                        <h1 className={styles.order_heading}>Sale Order</h1>
                        <label className='pb-0'>Company/Dealer Name</label>
                        {/* <div type="text" className={styles.order_div} onClick={() => setShowAcceptModal(true)} >
                                {selected}
                            </div> */}
                        <input
                            type="text"
                            className={styles.order_input}
                            placeholder='Company/Dealer Name/DealerId...'
                            value={orderId ? (generalInfo.storeName && generalInfo.dealerName && generalInfo.storeName + " / " + generalInfo.dealerName) : (searchDealer && searchDealer)}
                            onChange={e => setSearchDealer(e.target.value)}
                            onClick={(e) => { setShowAcceptModal(true) }}
                        />
                        <textarea
                            className={styles.order_input_textarea}
                            placeholder='Enter Billing Address'
                            value={loading ? '' : generalInfo.billingAddress}
                            readOnly
                        />

                        <h4 className='border py-2 text-center'>Delivery Address</h4>

                        <input
                            type="text"
                            className={styles.order_input}
                            placeholder='Name'
                            value={shippingInfo.name}
                            onChange={(e) => setShippingInfo(prevState => ({
                                ...prevState,
                                name: e.target.value
                            }))}
                            required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
                        />

                        <input
                            type="number"
                            className={styles.order_input}
                            placeholder='Enter Mobile'
                            value={shippingInfo.mobile}
                            onChange={(e) => setShippingInfo(prevState => ({
                                ...prevState,
                                mobile: e.target.value
                            }))}
                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                            required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
                        />

                        <input
                            type="text"
                            className={styles.order_input}
                            placeholder='Street'
                            value={shippingInfo.street}
                            onChange={(e) => setShippingInfo(prevState => ({
                                ...prevState,
                                street: e.target.value
                            }))}
                            required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
                        />
                        <div className='position-relative'>
                            {/* {shippingInfo.pincode && <div className={styles.pincode_address}>, {shippingInfo.city}, {shippingInfo.district}, {shippingInfo.state}</div>} */}
                            <input
                                type="text"
                                className={styles.order_input + ' mb-0'}
                                placeholder='Pincode'
                                value={shippingInfo.pincode}
                                onChange={(e) => setShippingInfo(prevState => ({
                                    ...prevState,
                                    pincode: e.target.value
                                }))}
                                onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                required={generalInfo.dealerName === "Cash" ? false : generalInfo.dealerId ? false : true}
                            />
                            {loadingLocation ? 'loading...' : (shippingInfo.city && <div className={styles.address_fs}>{shippingInfo.city}, {shippingInfo.district}, {shippingInfo.state}, {shippingInfo.pincode}</div>)}
                        </div>

                        <div className={styles.saleOrders_btns}>
                            <button type='button' className={styles.order_btn} onClick={() => navigate(-1)}>CANCEL</button>
                            <button type='submit' className={styles.order_btn1}
                            // onClick={() => setStep(1)}
                            >NEXT</button>
                        </div>
                    </form>
                    :
                    step === 1 ?
                        <form onSubmit={handleSubmitStep1} className={styles.order_container_step_1}>
                            {generalInfo.orderId &&
                                <div className={styles.orders_voucherNo}>
                                    <div className={styles.voucherNo}>
                                        <div>Voucher No.</div>
                                        <b className={styles.date_source}>{generalInfo.orderId}</b>
                                    </div>
                                    <div className={styles.voucherNo}>
                                        <div>Voucher Date</div>&nbsp;
                                        <b className={styles.date_source}>{generalInfo.orderDate}</b>
                                    </div>
                                    <div className={styles.voucherNo}>
                                        <div>Source</div>&nbsp;
                                        <b className={styles.date_source}>{generalInfo.orderType}</b>
                                    </div>
                                </div>
                            }

                            <div>
                                <div className={styles.item_scroll}>
                                    <div>
                                        {/* <select
                                            className={styles.whereHouse_select_box}
                                            value={wareHouseWise}
                                            onChange={(e) => setWareHouseWise(e.target.value)}
                                        >
                                            <option value="">All Warehouse</option>
                                            {warehouses && warehouses.map((item, index) => (
                                                <option key={index} value={item.warehousename}>{item.warehousename}</option>
                                            ))}
                                        </select> */}
                                        {itemsdetails && itemsdetails.map((x, index) =>
                                            <div key={index}>
                                                <div
                                                    className={styles.item_card_main}
                                                    onKeyDown={(e) => {
                                                        if (e.ctrlKey && e.keyCode === 88) {
                                                            handleRemoveClick(index + 1);
                                                            paymentRef.current.focus()
                                                        }
                                                    }}>
                                                    <div
                                                        className={styles.card_Close}
                                                        onClick={() => { itemsdetails && (itemsdetails.length > 1) && handleRemoveClick(index) }}>
                                                        <CloseWhiteIcon />
                                                    </div>
                                                    <div className={styles.table_th_Sno}>
                                                        <span className={styles.item_description}>{index + 1}. Item Description</span>
                                                    </div>
                                                    <div className={styles.position_relative + " " + styles.w_50}>

                                                        <div className={styles.live_search_input}>
                                                            <div className={styles.warehouseName}>
                                                                <span>{x.product_name}</span>
                                                                <span className={styles.ms_5}>{x.warehouse}</span>
                                                            </div>
                                                            <LiveSearchMob
                                                                orderId={orderId}
                                                                results={results}
                                                                itemsdetails={itemsdetails}
                                                                cartType={generalInfo.cartType}
                                                                value={x.itemNo ? x.itemNo : searchItem}
                                                                renderItem={item => <>{item.name}</>}
                                                                onChange={(e) => setSearchItem(e.target.value)}
                                                                setSearchItem={setSearchItem}
                                                                itemseacrhRef={itemseacrhRef}
                                                                cartTypeRef={cartTypeRef}
                                                                handleRemoveClick={() => handleRemoveClick(index + 1)}
                                                                removeIndex={index + 1}
                                                                setShowAddItem={setShowAddItem}
                                                                onSelect={item => {
                                                                    if (!generalInfo.cartType) {
                                                                        setGeneralInfo(prevState => ({
                                                                            ...prevState,
                                                                            cartType: item.cartType,
                                                                            shippingType: "Standard"
                                                                        }))
                                                                        setItemsdetails([
                                                                            ...itemsdetails.slice(0, index),
                                                                            item,
                                                                            ...itemsdetails.slice(index + 1)
                                                                        ])
                                                                        qtyFocus.current.focus()
                                                                    }
                                                                    else {
                                                                        setItemsdetails([
                                                                            ...itemsdetails.slice(0, index),
                                                                            item,
                                                                            ...itemsdetails.slice(index + 1)
                                                                        ])
                                                                        qtyFocus.current.focus()
                                                                    }
                                                                }}
                                                            />


                                                        </div>

                                                        {((x.accessories && x.accessories.length > 0) || (x.itemNo && x.itemNo) || x.price) &&
                                                            <div>
                                                                <div className={styles.card_qty_rate_per}>
                                                                    <div className='d-flex flex-column me-1'>
                                                                        <label className={styles.card_lebel}>Qty</label>
                                                                        <input
                                                                            type="number"
                                                                            ref={qtyFocus}
                                                                            placeholder="Qty"
                                                                            name='qty'
                                                                            value={x.qty}
                                                                            onChange={e => {
                                                                                if (!x.cartType) {
                                                                                    enforce_maxlength(e, index);
                                                                                    handleInputChange(e, index);
                                                                                    const list = [...itemsdetails];
                                                                                    list[index]["amount"] = x.qty * x.price;
                                                                                    setItemsdetails(list);
                                                                                }
                                                                                else if (!e.target.value || (parseFloat(e.target.value) <= parseFloat(x.totalQty))) {
                                                                                    enforce_maxlength(e, index);
                                                                                    handleInputChange(e, index);
                                                                                    const list = [...itemsdetails];
                                                                                    list[index]["amount"] = x.qty * x.price;
                                                                                    setItemsdetails(list);
                                                                                }
                                                                                setGeneralInfo(prevState => ({
                                                                                    ...prevState,
                                                                                    deliveryPartner: "",
                                                                                }))
                                                                            }}
                                                                            onInput={(e) => {
                                                                                if ((x.unit === 'Roll' || x.unit === 'Pcs' || x.unit === 'Box') && x.totalQty)
                                                                                    e.target.value = e.target.value.slice(0, x.totalQty.length)
                                                                            }}
                                                                            onKeyPress={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.preventDefault()
                                                                                    unitRef.current.focus()
                                                                                }
                                                                            }}
                                                                            required
                                                                            className={styles.card_input_qty}
                                                                            onClick={() => setNextBtn(false)}
                                                                        />
                                                                    </div>

                                                                    <div className='d-flex flex-column me-1'>
                                                                        <label className={styles.card_lebel}>Per</label>
                                                                        <input
                                                                            type="text"
                                                                            value={x.unit}
                                                                            onWheel={(e) => e.target.blur()}
                                                                            name="per"
                                                                            disabled
                                                                            required
                                                                            className={styles.card_input_per}
                                                                        />
                                                                        {/* <select
                                                                        name='unit'
                                                                        value={x.unit}
                                                                        onChange={e => { handleInputChange(e, index); }}
                                                                        ref={unitRef}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                e.preventDefault()
                                                                                priceRef.current.focus()
                                                                            }
                                                                        }}
                                                                        required
                                                                        className={styles.card_input_per}
                                                                    >
                                                                        <option value="" hidden>Select</option>
                                                                        <option value="Roll">Roll</option>
                                                                        <option value="Pcs">Pcs</option>
                                                                        <option value="Box">Box</option>
                                                                        <option value="Sq Meter">Sq Meter</option>
                                                                        <option value="Meter">Meter</option>
                                                                        <option value="Sq Feet">Sq Feet</option>
                                                                        <option value="Feet">Feet</option>
                                                                        <option value="Kg">Kg</option>
                                                                    </select> */}
                                                                    </div>

                                                                    <div className='d-flex flex-column me-1'>
                                                                        <label className={styles.card_lebel}>Rate</label>
                                                                        <input
                                                                            type="number"
                                                                            value={x.price}
                                                                            name="price"
                                                                            // onChange={e => {
                                                                            //     if (x.dp > parseFloat(e.target.value ? e.target.value : 0)) {
                                                                            //         setErrors(prevState => ({ ...prevState, dp: `Enter  Above` + x.dp, index: index }))
                                                                            //     } else {
                                                                            //         setErrors(prevState => ({ ...prevState, dp: "", index: "" }))
                                                                            //     }

                                                                            //     handleInputChange(e, index);
                                                                            // }}
                                                                            ref={priceRef}
                                                                            // onKeyPress={(e) => {

                                                                            //     if (e.key === 'Enter') {
                                                                            //         e.preventDefault()
                                                                            //         const list = [...itemsdetails];
                                                                            //         list[index]["descKey"] = index;
                                                                            //         setItemsdetails(list);
                                                                            //         descRef.current.focus()
                                                                            //     }
                                                                            // }}
                                                                            className={styles.card_input_per}
                                                                            disabled
                                                                            required
                                                                        />

                                                                    </div>

                                                                    <div className='d-flex flex-column'>
                                                                        <label className={styles.card_lebel}>Amount</label>
                                                                        <input
                                                                            type="number"
                                                                            value={(x.qty * x.price).toFixed(2)}
                                                                            disabled
                                                                            readOnly
                                                                            className={styles.card_input}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {<div className='d-flex flex-column mt-1'>
                                                                    <input
                                                                        type={"text"}
                                                                        className={styles.card_input_description}
                                                                        name="desc"
                                                                        placeholder='Enter description'
                                                                        value={x.desc}
                                                                        // autoFocus
                                                                        ref={descRef}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                if (!x.desc) {
                                                                                    const list = [...itemsdetails];
                                                                                    list[index]["descKey"] = "";
                                                                                    setItemsdetails(list);
                                                                                }
                                                                                e.preventDefault()
                                                                                handleAddClick()
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>}
                                                            </div>
                                                        }
                                                        {error.index === index && <div className='fs_10 text-danger text-center'>{error.dp}</div>}

                                                        {x.accessories && x.accessories.length > 0 && x.accessories.map((item, idx) =>
                                                            <div key={idx} className={styles.L_U_clip}>
                                                                <div className={styles.L_clip_price}>
                                                                    <b>{item.accessoriesName}</b>
                                                                    <span>{item.accessoriesPrice} ₹</span> / Pcs
                                                                </div>
                                                                <input
                                                                    type="number"
                                                                    placeholder='Qty'
                                                                    className={styles.L_clip_qty}
                                                                    value={item.accessoriesQty}
                                                                    onChange={e => {
                                                                        setItemsdetails([...itemsdetails.slice(0, index),
                                                                        {
                                                                            ...itemsdetails[index],
                                                                            accessories: [...itemsdetails[index].accessories.slice(0, idx),
                                                                            {
                                                                                ...itemsdetails[index].accessories[idx],
                                                                                accessoriesQty: e.target.value,
                                                                            },
                                                                            ...itemsdetails[index].accessories.slice(idx + 1)]
                                                                        },
                                                                        ...itemsdetails.slice(index + 1)]);
                                                                    }}
                                                                    onClick={e => {
                                                                        setItemsdetails([...itemsdetails.slice(0, index),
                                                                        {
                                                                            ...itemsdetails[index],
                                                                            accessories: [...itemsdetails[index].accessories.slice(0, idx),
                                                                            {
                                                                                ...itemsdetails[index].accessories[idx],
                                                                                accessoriesQty: (e.target.value > 0 ? e.target.value : ''),
                                                                            },
                                                                            ...itemsdetails[index].accessories.slice(idx + 1)]
                                                                        },
                                                                        ...itemsdetails.slice(index + 1)]);
                                                                    }}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault()
                                                                            unitRef.current.focus()
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>




                                                    {/* {(index === x.descKey || x.desc) && <tr>
                                                        <td colSpan={6} className={styles.table_th_details}>
                                                            <input
                                                                type={"text"}
                                                                className={styles.inputFocus}
                                                                style={{ height: "30px", textAlign: "left" }}
                                                                ref={descRef}
                                                                name="desc"
                                                                autoFocus
                                                                value={x.desc}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        if (!x.desc) {
                                                                            const list = [...itemsdetails];
                                                                            list[index]["descKey"] = "";
                                                                            setItemsdetails(list);
                                                                        }
                                                                        e.preventDefault()
                                                                        handleAddClick()
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    } */}



                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <div className={styles.item_btn_main}>
                                    <button type='button' className={styles.item_btn} onClick={() => handleAddClick()}>Add More</button>
                                </div>
                            </div>

                            <div className={styles.totalAmount_card}>
                                <div className='d-flex justify-content-between'>
                                    <span>Sub Total</span>
                                    <span className={styles.charges_txt}>{subtotal.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className={styles.shipingCharges}>Shipping Charges</span>
                                    <div className='d-flex w-50 justify-content-around align-items-center'>

                                        <input
                                            type="number"
                                            className={styles.charges_input}
                                            placeholder='0.00'
                                            value={generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge)}
                                            onChange={(e) => setGeneralInfo(prevState => ({
                                                ...prevState,
                                                shippingCharge: e.target.value
                                            }))}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                        />

                                        <span className='d-flex align-items-center'>
                                            <span className={styles.toPay}>To Pay</span>

                                            <input
                                                type='checkbox'
                                                className={styles.toPay}
                                                value={generalInfo.toPay}
                                                onChange={(e) => setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    toPay: e.target.checked
                                                }))}
                                                checked={generalInfo.toPay}
                                                disabled={generalInfo.deliveryPartner === 'Self' ? true : false}
                                            />

                                        </span>


                                    </div>
                                    <span className={styles.charges_txt}>{generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0))}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <span className='d-flex align-items-center'>
                                        <CircleAddIcon />

                                        <input
                                            type="text"
                                            className={styles.adjustment}
                                            value={generalInfo.manageName}
                                            placeholder="Enter Manage"
                                            onChange={(e) => setGeneralInfo(prevState => ({
                                                ...prevState,
                                                manageName: e.target.value,
                                            }))}
                                            required={generalInfo.manageValue ? true : false}
                                        />

                                    </span>
                                    <div className='d-flex w-50 justify-content-around align-items-center'>
                                        <input
                                            type="number"
                                            className={styles.charges_input}
                                            value={generalInfo.manageValue}
                                            name='manageValue'
                                            onChange={(e) => {
                                                setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    manageValue: e.target.value
                                                }))
                                            }}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                            required={generalInfo.manageName ? true : false}
                                        />

                                        <span className={styles.toPay_hide}></span>
                                    </div>
                                    <span className={styles.charges_txt + ' text-danger'}>{generalInfo.manageValue ? generalInfo.manageValue : 0}</span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span>Round Off</span>
                                    <span className={styles.charges_txt}>{roundedAmt}</span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span><b>Total (INR)</b></span>
                                    <span className={styles.charges_txt}><b>{totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</b></span>
                                </div>
                            </div>

                            <div className={styles.saleStep1_btn}>
                                <button type='button' className={styles.order_btn} onClick={() => { setStep(0) }}>BACK</button>
                                <button type='submit' className={styles.order_btn1}
                                //  onClick={() => setStep(2)}
                                >NEXT</button>
                            </div>
                        </form>
                        :
                        step === 2 &&
                        <form onSubmit={handleSubmit} ref={formRef} className={designation === 'superadmin' ? styles.order_container_step_2 : styles.step_2_main}>
                            {generalInfo.orderId &&
                                <div className={styles.orders_voucherNo}>
                                    <div className={styles.voucherNo}>
                                        <div>Voucher No.</div>
                                        <b className={styles.date_source}>{generalInfo.orderId}</b>
                                    </div>
                                    <div className={styles.voucherNo}>
                                        <div>Voucher Date</div>&nbsp;
                                        <b className={styles.date_source}>{generalInfo.orderDate}</b>
                                    </div>
                                    <div className={styles.voucherNo}>
                                        <div>Source</div>&nbsp;
                                        <b className={styles.date_source}>{generalInfo.orderType}</b>
                                    </div>
                                </div>
                            }
                            <label className={styles.order_lebel}>Dispached Warehouse</label>
                            <select
                                className={styles.select_packer}
                                value={generalInfo.warehousename}
                                onChange={(e) => {
                                    setGeneralInfo(prevState => ({
                                        ...prevState,
                                        warehousename: e.target.value
                                    })); setWarehouseName(e.target.value)
                                }}
                                required
                            >
                                <option value="">All Warehouse</option>
                                {warehouses && warehouses.map((item, index) => (
                                    <option key={index} value={item.warehousename}>{item.warehousename}</option>
                                ))}
                            </select>
                            <label className={styles.order_lebel}>Dispatch Manager</label>
                            <select
                                className={styles.select_packer}
                                value={generalInfo.dmId}
                                onChange={(e) => setGeneralInfo(prevState => ({
                                    ...prevState,
                                    dmId: e.target.value
                                }))}
                                required
                            // required={generalInfo.dealerId ? false : true}
                            >
                                <option value="" >Select Dispatch Manager</option>
                                {dispatchManagers && dispatchManagers.map((item, index) => (
                                    <option key={index} value={item.managerId}>{item.name}</option>
                                ))}
                            </select>
                            {/* <label className={styles.order_lebel}>Delivery Type</label>
                            <select
                                className={styles.select_packer}
                                value={generalInfo.shippingType}
                                onChange={(e) => setGeneralInfo(prevState => ({
                                    ...prevState,
                                    shippingType: e.target.value
                                }))}
                                // required
                            >
                                <option value="" hidden>Choose Delivery Type</option>
                                <option>Standard</option>
                                <option>Express</option>
                            </select> */}
                            <label className={styles.order_lebel}>Delivery Method</label>
                            <input type="text"
                                className={styles.delivery_method}
                                value={generalInfo.deliveryPartner === 'Self' ? 'Pick Up' : generalInfo.courierName}
                                disabled
                                placeholder='Delivery Method Name'
                            />
                            {/* <select
                                className={styles.select_packer}
                                value={generalInfo.deliveryPartner === 'Self' ? 'Pick Up' : generalInfo.courierName}
                                onChange={(e) => setGeneralInfo(prevState => ({
                                    ...prevState,
                                    courierName: e.target.value,
                                    deliveryPartner: '',
                                }))}
                            // required
                            >
                                {generalInfo.deliveryPartner === 'Self' ?
                                    <>
                                        <option value='Pick Up'>Pick Up</option>
                                        {couriers.length > 0 && couriers.map((obj, index) =>
                                            <option key={index} value={obj.courierName}>{obj.courierName}</option>
                                        )}
                                    </>
                                    :
                                    <>
                                        <option value="" hidden>Choose Delivery Method</option>
                                        {
                                            couriers.length > 0 ? couriers.map((obj, index) =>
                                                <option key={index} value={obj.courierName}>{obj.courierName}</option>
                                            )
                                                :
                                                <option value={generalInfo.courierName}>{generalInfo.courierName}</option>
                                        }
                                    </>
                                }
                            </select> */}
                            <label className={styles.order_lebel}>Sub-Method</label>
                            <select
                                className={styles.select_packer}
                                value={generalInfo.deliveryPartner}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setGeneralInfo(prevState => ({
                                        ...prevState,
                                        deliveryPartner: selectedValue
                                    }));

                                    // Check if the selected value is what you need to trigger orderSubMethod
                                    if (selectedValue) {
                                        orderSubMethod(selectedValue);
                                    }
                                }}
                                // onClick={() => {
                                //     generalInfo.deliveryPartner && generalInfo.courierName && generalInfo.methodSelection !== 'Disable' &&
                                //         deliveryMethodClear()
                                // }}
                                required
                            >
                                <option value="" hidden>Choose Sub-Method</option>
                                <option value='Self'>Self</option>
                                {methodPartners && methodPartners.length > 0 ? methodPartners.map((obj, index) =>
                                    <option key={index} value={obj.partnerName || obj.methodName}>{obj.partnerName || obj.methodName}</option>
                                )
                                    :
                                    <>
                                        <option value={generalInfo.partnerName}>{generalInfo.partnerName}</option>
                                        {/* <option value='Self'>Self</option> */}
                                    </>
                                }
                            </select>

                            <div className={styles.totalAmount_card}>
                                <div className='d-flex justify-content-between'>
                                    <span>Sub Total</span>
                                    <span className={styles.charges_txt}>{subtotal.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className={styles.shipingCharges}>Shipping Charges</span>
                                    <div className='d-flex w-50 justify-content-around align-items-center'>

                                        <input
                                            type="number"
                                            className={styles.charges_input}
                                            placeholder='0.00'
                                            value={generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge)}
                                            onChange={(e) => setGeneralInfo(prevState => ({
                                                ...prevState,
                                                shippingCharge: e.target.value
                                            }))}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                        />

                                        <span className='d-flex align-items-center'>
                                            <span className={styles.toPay}>To Pay</span>

                                            <input
                                                type='checkbox'
                                                className={styles.toPay}
                                                value={generalInfo.toPay}
                                                onChange={(e) => setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    toPay: e.target.checked
                                                }))}
                                                checked={generalInfo.toPay}
                                                disabled={generalInfo.deliveryPartner === 'Self' ? true : false}
                                            />

                                        </span>


                                    </div>
                                    <span className={styles.charges_txt}>{generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0))}</span>
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <span className='d-flex align-items-center'>
                                        <CircleAddIcon />

                                        <input
                                            type="text"
                                            className={styles.adjustment}
                                            value={generalInfo.manageName}
                                            placeholder="Enter Manage"
                                            onChange={(e) => setGeneralInfo(prevState => ({
                                                ...prevState,
                                                manageName: e.target.value,
                                            }))}
                                            required={generalInfo.manageValue ? true : false}
                                        />

                                    </span>
                                    <div className='d-flex w-50 justify-content-around align-items-center'>
                                        <input
                                            type="number"
                                            className={styles.charges_input}
                                            value={generalInfo.manageValue}
                                            name='manageValue'
                                            onChange={(e) => {
                                                setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    manageValue: e.target.value
                                                }))
                                            }}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                            required={generalInfo.manageName ? true : false}
                                        />

                                        <span className={styles.toPay_hide}></span>
                                    </div>
                                    <span className={styles.charges_txt + ' text-danger'}>{generalInfo.manageValue ? generalInfo.manageValue : 0}</span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span>Round Off</span>
                                    <span className={styles.charges_txt}>{roundedAmt}</span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span><b>Total (INR)</b></span>
                                    <span className={styles.charges_txt}><b>{totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</b></span>
                                </div>
                            </div>

                            {payStatus && <div className={styles.status_card}>
                                <div className='d-flex justify-content-end'>
                                    {payStatus &&
                                        <button
                                            type='button'
                                            className={styles.addReceipt_btn}
                                            onClick={() => {
                                                if (!orderId) {
                                                    alert("Please Create Voucher Before")
                                                } else {
                                                    setShowPaymentModal(true)
                                                    setReciptData(prevState => ({
                                                        ...prevState,
                                                        paymentMethod: '',
                                                        paymentType: '',
                                                        paymentAmount: '',
                                                        transactionId: '',
                                                        accountNumber: '',
                                                        transactionScreenshot: ''
                                                    }))
                                                }
                                            }}
                                        >
                                            Add Receipts
                                        </button>
                                    }
                                </div>
                                <div className={styles.receiptList_scroll}>
                                    {reciptsInfo && reciptsInfo.map((item, index) =>
                                        <div key={index} onClick={() => { setReciptData(item) }} className='d-flex flex-wrap'>
                                            <span>Receipt Id : <u><b>{item.receiptId}</b></u></span>
                                            <span>Method : {item.paymentMethod}</span>
                                            <span>Amount : <b>{item.paymentAmount}</b></span>
                                            <span>Status : <u className='text-danger'>
                                                <b role='button'
                                                    className={item.status.includes('Pending') ? styles.recipt_status_pending :
                                                        item.status === 'Cancelled' ? styles.recipt_status_red :
                                                            styles.recipt_status_green}
                                                    onClick={() => { setShowPaymentModal(true) }}>
                                                    <u>{item.status}</u>
                                                </b>
                                            </u></span>
                                        </div>
                                    )}
                                </div>
                            </div>}

                            <div className={styles.saleOrderStep2_btn}>
                                <button type='button' className={styles.back_btn} onClick={() => setStep(1)}>BACK</button>
                                {typeStatus &&
                                    <button
                                        type='button'
                                        className={styles.saveAsDraft}
                                        onClick={() => handleSaleOrder("draft")}
                                    >SAVE AS DRAFT
                                    </button>
                                }
                                {orderId && generalInfo.reqType !== "Request For Cancel" && (generalInfo.dispatchStatus !== "New Order") && generalInfo.dispatchStatus !== null && generalInfo.status !== 'Cancelled' ?
                                    <button
                                        type='button'
                                        className={styles.saveAsDraft + ' text-danger border-danger'}
                                        onClick={() => { setShowRequestForCancel(true) }}
                                    >REQUEST FOR CANCEL
                                    </button>
                                    :
                                    orderId && !method && generalInfo.status !== "Cancelled" && generalInfo.reqType !== "Request For Cancel" && generalInfo.status !== "Dispatched" &&
                                    <button
                                        type='button'
                                        className={styles.saveAsDraft + ' text-danger border-danger'}
                                        onClick={() => { setShowCancelOrderModal(true) }}
                                    >CANCEL ORDER
                                    </button>
                                }
                                {!typeStatus ?
                                    <button
                                        type='button'
                                        className={styles.save_send}
                                        onClick={() => navigate("/orders/saleinvoice?orderId=" + orderId)}>
                                        VIEW INOVICE
                                    </button>
                                    :
                                    <>
                                        {(!orderId && nextBtn) ?
                                            <button
                                                type='submit'
                                                disabled={loadingBtn ? true : false}
                                                className={styles.save_send}
                                            >
                                                {loadingBtn ? <Spinner size="15" /> : "SAVE & SEND"}
                                            </button>
                                            :
                                            (orderId && !nextBtn) ?
                                                <button
                                                    type='button'
                                                    disabled={loadingBtn ? true : false}
                                                    className={styles.save_send}
                                                    onClick={() => handleNextButtonClick()}
                                                >
                                                    {loadingBtn ? <Spinner size="15" />
                                                        : "UPDATE & NEXT"}
                                                </button>
                                                : (orderId && nextBtn) ?
                                                    <button
                                                        type='submit'
                                                        disabled={loadingBtn ? true : false}
                                                        className={styles.save_send}
                                                    >
                                                        {loadingBtn ? <Spinner size="15" /> :
                                                            generalInfo.status === "Payment Pending"
                                                                ? "UPDATE & SEND" : "SAVE & SEND"}
                                                    </button>
                                                    :
                                                    <button
                                                        type='button'
                                                        disabled={loadingBtn ? true : false}
                                                        className={styles.save_send}
                                                        onClick={() => handleNextButtonClick()}
                                                    >
                                                        {loadingBtn ? <Spinner size="15" /> : "NEXT"}
                                                    </button>
                                        }
                                    </>
                                }

                                {orderId && generalInfo.status !== 'Cancelled' && generalInfo.reqType !== "Request For Cancel" &&
                                    <button
                                        type='button'
                                        className={styles.back_btn}
                                        onClick={() => { handleSendLink() }}
                                    >SEND LINK
                                    </button>
                                }
                                {generalInfo.status === 'Dispatched' && <Link to={'/viewdispatchdetails/' + orderId + '&saleorder'} className={styles.viewDetail_btn}>View Dispatch Details</Link>}

                            </div>
                        </form>
                }
            </div>
            {/* </form> */}

            <ModalMob
                show={showAcceptModal}
                close={setShowAcceptModal}
                css={designation === 'superadmin' ? styles.dealerMargin_top : styles.date_margin_top}
                content={
                    <div className={styles.dealer_select}>
                        <p onClick={() => { setAddDealer(true); setShowAcceptModal(false) }} className={styles.add_dealer_btn}>
                            Add Dealer
                        </p>
                        {dealers?.map((item, index) =>
                            <p key={index}
                                // setAddDealer={setAddDealer}
                                onClick={() => {
                                    if (item.name === "Cash") {
                                        setGeneralInfo(prevState => ({
                                            ...prevState,
                                            dealerId: item.dealerId,
                                            dealerName: item.name,
                                            storeName: item.storeName,
                                            shippingType: "Standard",
                                            courierName: "Pick Up",
                                            partnerName: "Self",
                                        }))
                                    }
                                    else {
                                        setGeneralInfo(prevState => ({
                                            ...prevState,
                                            dealerId: item.dealerId,
                                            dealerName: item.name,
                                            storeName: item.storeName,
                                            billingAddress: (item.street || item.city || item.district || item.state || item.pincode) && item.street + ' ' + item.city + ' ' + item.district + ' ' + item.state + ' ' + item.pincode
                                        }))
                                        setShippingInfo(prevState => ({
                                            ...prevState, mobile: item.mobile,
                                            name: item.storeName,
                                            pincode: item.pincode,
                                            street: item.street,
                                            city: item.city,
                                            district: item.district,
                                            state: item.state,
                                        }))
                                    }
                                    setShowAcceptModal(false)
                                    setSearchDealer(item.storeName && item.name && item.storeName + " / " + item.name)
                                }}>
                                {item.storeName + " / " + item.name}
                            </p>
                        )}

                    </div>
                }
            />

            <PaymentModalMob
                show={showPaymentModal}
                close={setShowPaymentModal}
                handleRecipt={handleRecipt}
                setReciptData={setReciptData}
                reciptData={reciptData}
                isImage={isImage}
                setisImage={setisImage}
                totalAmount={totalAmount}
                accounts={accounts}
                setLoadingReceipt={setLoadingReceipt}
                loadingReceipt={loadingReceipt}
            />
            <Modal
                show={showCancelOrderModal}
                close={setShowCancelOrderModal}
                closeBtn={true}
                heading="CANCEL ORDER"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message_cancelOrder}>Are You Sure Want To Cancel This Order</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton css={styles.moveToSale_btn_no} title="NO" handleSubmit={() => setShowCancelOrderModal(false)} />
                            <GreenButton css={`${styles.moveToSale_btn} ms-4`} title={loadingCancelBtn ? <Spinner size="15" /> : "YES"} handleSubmit={() => { handleCancelOrder(); }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showRequestForCancel}
                close={setShowRequestForCancel}
                closeBtn={true}
                heading="REQUEST FOR CANCEL"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message_cancelOrder}>Are You Sure Want To Cancel This Order</p>
                        <textarea type="text" placeholder='Enter Issue' className={styles.reqIssue} value={reqIssue} onChange={(e) => setReqIssue(e.target.value)} />
                        <div className={styles.massageModal_btn}>
                            <RedOutButton css={styles.moveToSale_btn_no} title="NO" handleSubmit={() => setShowRequestForCancel(false)} />
                            <GreenButton css={`${styles.moveToSale_btn} ms-4`} title={loadingCancelBtn ? <Spinner size="15" /> : "YES"} handleSubmit={() => { handleRequestForCancel() }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showPayment}
                close={setShowPayment}
                closeBtn={true}
                heading="Payment Link"
                content={
                    <div className={styles.text_center}>
                        <div className='d-flex justify-content-between w-75 mx-auto my-4 px-4'>
                            <span role='button' className={styles.link_icon}>
                                <span onClick={() => sendPaymentLink()}><WhatsAppIcon /></span>
                                {/* <span onClick={() => window.open(`https://wa.me/91${shippingInfo.mobile}?text=` + paymentLink, '_blank')}><WhatsAppIcon /></span> */}
                            </span>

                            <span role='button' className={styles.link_icon}><MessageColorIcon /></span>
                            <span role='button' className={styles.link_icon}><EmailColorIcon /> </span>
                            <span role='button' title='Copy command to clip board' onClick={() => { navigator.clipboard.writeText('https://dealer.wallicon.in/' + paymentLink); setShowElement(true) }} className={styles.link_icon}><CopyColorIcon /></span>
                        </div>

                        <div className='w-75 d-flex flex-row mx-auto mb-4'>
                            <input type='text' className={styles.paymentLink_input} value={'https://dealer.wallicon.in/' + paymentLink} />
                        </div>

                        <div className='position-relative w-75 d-flex justify-centent-between mx-auto mb-4'>
                            <div className={styles.country_plus}>&#43;</div>
                            <div className={styles.country_code}>
                                <select className={styles.country_code_select}>
                                    <option>91</option>
                                    <option>92</option>
                                </select>
                            </div>
                            <div className={styles.country_slash}>|</div>
                            <input type='number' className={styles.whatsapp_input} value={sendPaymnetLinkNumber} onChange={(e) => setSendPaymnetLinkNumber(e.target.value)} maxLength="10"
                                onInput={(e) => e.target.value = e.target.value.slice(0, 10)} />
                            <span className={sendPaymnetLinkNumber.length !== 10 ? styles.copyLinkBtn : styles.copyLink} onClick={() => { sendPaymnetLinkNumber.length === 10 && sendLink() }}><WhatsappIcon />Send Link</span>
                            {/* <span className={styles.copyLink} onClick={() => window.open(`https://wa.me/91${sendPaymnetLinkNumber}?text=` + paymentLink, '_blank')}><WhatsappIcon />Send Link</span> */}
                        </div>

                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowPayment(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowPayment(false) }} />
                        </div>
                    </div>
                }>
            </Modal>

        </div>
    )
}

export default SaleOrderMob;