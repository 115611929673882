import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  ArowUpIcon,
  EditManagerIcon,
  NavbarArrowDownIcon,
  NewManagerCancelIcon,
  OpenCollectionIcon,
  SaveTaxRateIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import styles from "./Inventory.module.css";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import SlidingFilter from "../../components/updatedComponents/Dealers/SlidingFilter";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import Button from "../../components/updatedComponents/Dealers/Button";
import fetchData from "../../APIsControll/apiControll";
import RadioButtonGroup from "../../components/updatedComponents/FormComponents/RadioButtonGroup";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import MultiSelectDropdown from "../../components/updatedComponents/FormComponents/MultiSelectDropdown ";

const CollectionInfo = () => {
  const { managerId, mode } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [searchparams] = useSearchParams();
  const product_id = searchparams.get("product_id");
  const collection_id = searchparams.get("collection_id");
  const collection_name = params.collection;
  const product_name = params.product;
  const [inputSearch, setInputSearch] = useState("");
  const [items, setItems] = useState([]);
  const [collectionInfoModal, setCollectionInfoModal] = useState(false);
  const [msgShow, setMsgShow] = useState("");
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [totalItems, setTotalItems] = useState({});
  const [reload, setReload] = useState(false);
  const [wholesaleTabs, setWholesaleTabs] = useState("Inventory");
  const [showDropdown, setShowDropdown] = useState(null);
  const [openAll, setOpenAll] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [taxRates, setTaxRates] = useState([]);
  const [collectionInfo, setCollectionInfo] = useState({
    warehouse: "",
    description: "",
    hscode: "",
    unit: "",
    odp: "",
    dp: "",
    dmrp: "",
    cu: "",
    cp: "",
    cmrp: "",
    mcp: "",
    vm: "",
    accessories: [],
  });

  console.log("SHOW DROP DOWN ", showDropdown);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: "5",
      product_id: product_id,
      collection_id: collection_id,
      sortBy: wholesaleTabs.toLowerCase(),
      searchKeyword: "",
      warehousename: "",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(process.env.REACT_APP_URL + "inventory/items", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        console.log("RESULT ", result.status);
        setItems(result.data);
        setWarehouses(result.warehouses);
        if (wholesaleTabs.toLowerCase() === "items") {
          setTaxRates(result.taxRate);
        }
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [collection_id, product_id, wholesaleTabs]);

  // const handleSubmitCollectionInfo = () => {
  //   setReload(false);
  //   setMsgShow("");
  //   var myHeaders = new Headers();
  //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     managerId: managerId,
  //     collection_id: collection_id,
  //     inventdesc: collectionInfo.description,
  //     hscode: collectionInfo.hscode,
  //     unit: collectionInfo.unit,
  //     odp: collectionInfo.odp,
  //     dp: collectionInfo.dp,
  //     cu: collectionInfo.cu,
  //     cp: collectionInfo.cp,
  //     mcp: collectionInfo.mcp,
  //     // "vm": collectionInfo.vm,
  //     dmrp: collectionInfo.dmrp,
  //     cmrp: collectionInfo.cmrp,
  //     accessories: collectionInfo.accessories,
  //     warehousename: collectionInfo.warehouse,
  //     itemsdetails: items,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     process.env.REACT_APP_URL + "inventory/update-collection",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === "success") {
  //         setMsgShow(result.message);
  //         setCollectionInfoModal(true);
  //         setReload(true);
  //       } else {
  //         alert(result.msg);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  const toggleDropdown = (index) => {
    setOpenAll(false);

    if (showDropdown === index) {
      setShowDropdown(null);
    } else {
      setShowDropdown(index);
    }
  };

  const handleOpenAll = () => {
    setOpenAll(!openAll);
    setShowDropdown(!openAll ? "all" : null);
  };

  const toggleDropdownEdit = (index) => {
    if (openAll) {
      setShowDropdown(null);
      setOpenAll(false);
    } else {
      setShowDropdown(index);
    }
  };

  const slidingFilterData = ["Inventory", "Items"];
  const inventoryHeader = [
    "Status",
    "Item",
    "Warehouse",
    "Inventory Type",
    "Weight (Kg)",
    "Volume (CBM)",
    "Opening",
    "Purchase",
    "Sold",
    "Adjust",
    "Closing",
  ];
  const itemsHeader = [
    "Status",
    "Item",
    "Batch No.",
    "Warehouse",
    "Inventory Type",
    "Description",
    "HS Code",
    "Alias",
    "Weight (Kg)",
    "Volume (CBM)",
    "Unit",
    "MRP",
    "Online Price",
    "Offline Price",
  ];

  console.log("TAX RATES ", taxRates);

  return (
    <>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <div
              className={styles.inventory_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin: 0,
                  paddingTop: "0.21rem",
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.inventory_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              Product/{product_name}/{collection_name}
            </p>
          </div>
        </div>

        <div className={styles.collection_header}>
          <div className={styles.collection_header_left}>
            <div className={styles.inventory_search_bar}>
              <SearchBar />
            </div>
            <div>
              <SlidingFilter
                itemList={slidingFilterData}
                setFilter={setWholesaleTabs}
              />
            </div>
          </div>
          <div className={styles.collection_header_right}>
            <div className={styles.drop_down}>
              <p style={{ color: mode ? "#fff" : "#000" }}>Warehouse</p>
              <div>
                <MultiSelectDropdown
                  label={"All"}
                  options={warehouses?.map((w) => ({
                    label: w.warehousename,
                    value: w.warehousename,
                  }))}
                />
              </div>
            </div>
            <div
              style={{ cursor: "pointer", marginLeft: "20px" }}
              onClick={() => handleOpenAll()}
            >
              <OpenCollectionIcon color={mode ? "#fff" : "#000"} />
            </div>

            {wholesaleTabs === "Items" && !editMode && (
              <Button
                buttonIcon={<EditManagerIcon />}
                buttonText={"Edit"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  setEditMode(!editMode);
                }}
              />
            )}

            {wholesaleTabs === "Items" && editMode && (
              <Button
                buttonIcon={<SaveTaxRateIcon />}
                buttonText={"SAVE"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  alert("Are you sure, you want to update this?");
                }}
              />
            )}

            {wholesaleTabs === "Items" && editMode && (
              <Button
                buttonIcon={<NewManagerCancelIcon />}
                buttonText={"CANCEL"}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  setEditMode(!editMode);
                }}
              />
            )}
          </div>
        </div>

        <ul className={styles.responsiveTable}>
          <li
            className={
              wholesaleTabs === "Inventory"
                ? `${styles.collection_list} ${styles.collection_list_inventory}`
                : `${styles.collection_list}`
            }
            style={
              mode
                ? {
                    backgroundColor: "#232529",
                    color: "#fafafa",
                    border: "1px solid #646B88",
                  }
                : {
                    backgroundColor: "#D9DCE5",
                    color: "#646B88",
                  }
            }
          >
            {wholesaleTabs === "Inventory" ? (
              <>
                {inventoryHeader?.map((h) => (
                  <div key={h}>{h}</div>
                ))}
                <div></div>
              </>
            ) : (
              <>
                {itemsHeader?.map((h) => (
                  <div key={h}>{h}</div>
                ))}
                <div></div>
              </>
            )}
          </li>

          <div
            className={
              wholesaleTabs === "Inventory"
                ? `${styles.inventory_data_container}`
                : `${styles.inventory_data_container} ${styles.items_data_container}`
            }
          >
            {loading ? (
              <div className={styles.collection_container}>
                <Spinner />
              </div>
            ) : (
              items &&
              items?.map((item, index) => (
                <div className={styles.collection_data}>
                  <li
                    key={index}
                    className={
                      wholesaleTabs === "Inventory"
                        ? `${styles.collection_list} ${styles.collection_list_inventory}`
                        : `${styles.collection_list}`
                    }
                    style={{
                      ...(mode
                        ? {
                            backgroundColor: "#232529",
                            color: "#fafafa",
                            border: "1px solid #646B88",
                          }
                        : {
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #E4E7EB",
                          }),
                      ...(showDropdown === index || showDropdown === "all"
                        ? {
                            // borderBottom: "0px solid",
                            borderRadius: "5px 5px 0 0",
                          }
                        : {
                            borderRadius: "5px",
                          }),
                    }}
                  >
                    {wholesaleTabs === "Inventory" ? (
                      <>
                        <div
                          style={{
                            color:
                              item.status === "Active" ? "#34A853" : "#F93E3E",
                          }}
                        >
                          {item.status === "Active" ? "Active" : "Inactive"}
                        </div>
                        <div>{item.itemNo}</div>
                        <div className={styles.collection_item}>
                          <p> {item.warehouse}</p>
                          <span> {item.warehouse}</span>
                        </div>
                        <div>{item.inventoryType}</div>
                        <div>{item.weight}</div>
                        <div>{item.vm}</div>
                        <div>{item.openings}</div>
                        <div>{item.purchase}</div>
                        <div>{item.sold}</div>
                        <div>{item.adjust}</div>
                        <div>{item.closing}</div>
                        <div>
                          <div
                            onClick={() => toggleDropdown(index)}
                            className={styles.collection_arrow_icon}
                          >
                            {showDropdown === index ||
                            showDropdown === "all" ? (
                              <ArowUpIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            ) : (
                              <NavbarArrowDownIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            color:
                              item.status === "Active" ? "#34A853" : "#F93E3E",
                          }}
                        >
                          {item.status === "Active" ? "Active" : "Inactive"}
                        </div>
                        <div>{item.itemNo}</div>
                        <div>{item.batch}</div>
                        <div className={styles.collection_item}>
                          <p> {item.warehouse}</p>
                          <span> {item.warehouse}</span>
                        </div>
                        <div>{item.inventoryType}</div>

                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.description}
                          />
                        ) : (
                          <div>{item.description}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.hscode}
                          />
                        ) : (
                          <div>{item.hscode}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.alias}
                          />
                        ) : (
                          <div>{item.alias}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.weight}
                          />
                        ) : (
                          <div>{item.weight}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.vm}
                          />
                        ) : (
                          <div>{item.vm}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.unit}
                          />
                        ) : (
                          <div>{item.unit}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.mrp}
                          />
                        ) : (
                          <div>{item.mrp}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.onlinePrice}
                          />
                        ) : (
                          <div>{item.onlinePrice}</div>
                        )}
                        {editMode && wholesaleTabs === "Items" ? (
                          <input
                            className={
                              mode
                                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                                : `${styles.collection_list_inp}`
                            }
                            type="text"
                            value={item.oflinePrice}
                          />
                        ) : (
                          <div>{item.oflinePrice}</div>
                        )}
                        <div>
                          <div
                            onClick={() => toggleDropdown(index)}
                            className={styles.collection_arrow_icon}
                          >
                            {showDropdown === index ||
                            showDropdown === "all" ? (
                              <ArowUpIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            ) : (
                              <NavbarArrowDownIcon
                                color={mode ? "#ffffff" : "#646B88"}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </li>

                  {(showDropdown === index || showDropdown === "all") && (
                    <div
                      className={styles.collection_hidden_Data}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={{
                        ...(mode
                          ? {
                              backgroundColor: "#1B1D21",
                              border: "1px solid #646B88",
                              borderTop: "none",
                            }
                          : {
                              border: "1px solid #E4E7EB",
                              borderTop: "none",
                            }),
                      }}
                    >
                      <SourcedItem
                        editMode={editMode}
                        item={item}
                        mode={mode}
                        wholesaleTabs={wholesaleTabs}
                        taxRates={taxRates}
                      />
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </ul>
      </div>
    </>
  );
};

const DropdownElement = ({ label, children, isEditable }) => (
  <div className={styles.drop_down_element}>
    {isEditable ? (
      <p
        style={{
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {label} {children}
      </p>
    ) : (
      <TextElement label={label} value={children} />
    )}
  </div>
);

const SourcedItem = ({ editMode, item, taxRates, wholesaleTabs, mode }) => (
  <>
    <DropdownElement label="Our sourced:">
      {editMode && wholesaleTabs === "Items" ? (
        <RadioButtonGroup
          options={[
            { value: "1", label: "Yes" },
            { value: "2", label: "No" },
          ]}
        />
      ) : (
        <p>
          {item.outsourcedItem === ""
            ? "N/A"
            : item.outsourcedItem === "Disable"
            ? "No"
            : "Yes"}
        </p>
      )}
    </DropdownElement>

    {wholesaleTabs === "Inventory" ? (
      <DropdownElement label="Recommended Items:">
        {item.recommendedItems && item.recommendedItems.length > 0
          ? item.recommendedItems.map((r) => r.name).join(", ")
          : "N/A"}
      </DropdownElement>
    ) : (
      <div className={styles.drop_down_element}>
        <p style={{ fontWeight: "600" }}>Limit:</p>
        {editMode && wholesaleTabs === "Items" ? (
          <input
            className={
              mode
                ? `${styles.collection_list_inp} ${styles.collection_list_inp_dark}`
                : `${styles.collection_list_inp}`
            }
            type="text"
            value={item.outSourceItemlimit}
          />
        ) : (
          <p>{item?.outSourceItemlimit}</p>
        )}
        pcs
      </div>
    )}

    <DropdownElement label="Production Item:">
      {editMode && wholesaleTabs === "Items" ? (
        <RadioButtonGroup
          options={[
            { value: "1", label: "Yes" },
            { value: "2", label: "No" },
          ]}
          selectedOption={item.productionItem}
        />
      ) : (
        <p>
          {item.productionItem === ""
            ? "N/A"
            : item.productionItem === "Disable"
            ? "No"
            : "Yes"}
        </p>
      )}
    </DropdownElement>

    <DropdownElement label="Sell Condition:">
      {editMode && wholesaleTabs === "Items" ? (
        <MultiSelectDropdown
          label={"Select"}
          options={[
            { label: "City", value: "City" },
            { label: "State", value: "State" },
            { label: "Country", value: "Country" },
          ]}
          initialOptions={
            item.sellCondition.length > 0
              ? item.sellCondition.map((s) => ({ label: s, value: s }))
              : []
          }
        />
      ) : item.sellCondition.length !== 0 ? (
        item.sellCondition.map((s) => s)
      ) : (
        "N/A"
      )}
    </DropdownElement>

    <DropdownElement label="Tax Rate:">
      {editMode && wholesaleTabs === "Items" ? (
        <MultiSelectDropdown
          label={"Select"}
          options={taxRates?.map((t) => ({
            label: t.name,
            value: t.taxRateId,
          }))}
          initialOptions={
            item.taxRate.length > 0
              ? item.taxRate.map((t) => ({ label: t.name, value: t.taxRateId }))
              : []
          }
        />
      ) : (
        "N/A"
      )}
    </DropdownElement>
  </>
);

export default CollectionInfo;

// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { GreenButton, RedOutButton } from "../../components/Buttons";
// import { useAuth } from "../../components/context/Auth";
// import {
//   AllInventoryViewIcon,
//   DownArrowCircle,
//   Edit,
//   Search,
//   UpArrowCircle,
// } from "../../components/icons/Icon";
// import Modal from "../../components/Modal";
// import styles from "../inventorymanager/Inventories.module.css";

// const CollectionInfo = () => {
//   const { managerId } = useAuth();
//   const navigate = useNavigate();
//   const params = useParams();
//   const [searchparams] = useSearchParams();
//   const product_id = searchparams.get("product_id");
//   const collection_id = searchparams.get("collection_id");
//   const collection_name = params.collection;
//   const [inputSearch, setInputSearch] = useState("");
//   const [items, setItems] = useState([]);
//   const [collectionInfoModal, setCollectionInfoModal] = useState(false);
//   const [msgShow, setMsgShow] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [warehouses, setWarehouses] = useState([]);
//   const [totalItems, setTotalItems] = useState({});
//   const [reload, setReload] = useState(false);
//   const [wholesaleTabs, setWholesaleTabs] = useState("inventory");
//   const [showDropdown, setShowDropdown] = useState(null);
//   const [collectionInfo, setCollectionInfo] = useState({
//     warehouse: "",
//     description: "",
//     hscode: "",
//     unit: "",
//     odp: "",
//     dp: "",
//     dmrp: "",
//     cu: "",
//     cp: "",
//     cmrp: "",
//     mcp: "",
//     vm: "",
//     accessories: [],
//   });

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true);
//       setItems([]);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         product_id: product_id,
//         collection_id: collection_id,
//         warehousename: collectionInfo.warehouse,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_URL + "inventory/collection-info",
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             setLoading(false);
//             if (result.totalItems) {
//               setTotalItems((prevState) => ({
//                 ...prevState,
//                 weight: result.totalItems.weight ? result.totalItems.weight : 0,
//                 vm: result.totalItems.vm ? result.totalItems.vm : 0,
//                 openingstock: result.totalItems.openingstock
//                   ? result.totalItems.openingstock
//                   : 0,
//                 purchase: result.totalItems.purchase
//                   ? result.totalItems.purchase
//                   : 0,
//                 sold: result.totalItems.sold ? result.totalItems.sold : 0,
//                 adjust: result.totalItems.adjust ? result.totalItems.adjust : 0,
//                 closing: result.totalItems.closing
//                   ? result.totalItems.closing
//                   : 0,
//               }));
//             }
//             if (result.warehouses) {
//               setWarehouses(result.warehouses);
//             }
//             if (result.collectionInfo) {
//               setCollectionInfo((prevState) => ({
//                 ...prevState,
//                 description: result.collectionInfo.inventdesc
//                   ? result.collectionInfo.inventdesc
//                   : "",
//                 hscode: result.collectionInfo.hscode
//                   ? result.collectionInfo.hscode
//                   : "",
//                 unit: result.collectionInfo.unit
//                   ? result.collectionInfo.unit
//                   : "",
//                 odp: result.collectionInfo.odp ? result.collectionInfo.odp : "",
//                 dp: result.collectionInfo.dp ? result.collectionInfo.dp : "",
//                 dmrp: result.collectionInfo.dmrp
//                   ? result.collectionInfo.dmrp
//                   : "",
//                 cu: result.collectionInfo.cu ? result.collectionInfo.cu : "",
//                 cp: result.collectionInfo.cp ? result.collectionInfo.cp : "",
//                 cmrp: result.collectionInfo.cmrp
//                   ? result.collectionInfo.cmrp
//                   : "",
//                 mcp: result.collectionInfo.mcp ? result.collectionInfo.mcp : "",
//                 vm: result.collectionInfo.vm ? result.collectionInfo.vm : "",
//                 accessories: result.collectionInfo.accessories
//                   ? result.collectionInfo.accessories
//                   : "",
//               }));
//             }
//             if (result.items) {
//               setItems(result.items);
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [managerId, product_id, collection_id, collectionInfo.warehouse, reload]);

//   const handleSubmitCollectionInfo = () => {
//     setReload(false);
//     setMsgShow("");
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       managerId: managerId,
//       collection_id: collection_id,
//       inventdesc: collectionInfo.description,
//       hscode: collectionInfo.hscode,
//       unit: collectionInfo.unit,
//       odp: collectionInfo.odp,
//       dp: collectionInfo.dp,
//       cu: collectionInfo.cu,
//       cp: collectionInfo.cp,
//       mcp: collectionInfo.mcp,
//       // "vm": collectionInfo.vm,
//       dmrp: collectionInfo.dmrp,
//       cmrp: collectionInfo.cmrp,
//       accessories: collectionInfo.accessories,
//       warehousename: collectionInfo.warehouse,
//       itemsdetails: items,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       process.env.REACT_APP_URL + "inventory/update-collection",
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status === "success") {
//           setMsgShow(result.message);
//           setCollectionInfoModal(true);
//           setReload(true);
//         } else {
//           alert(result.msg);
//         }
//       })
//       .catch((error) => console.log("error", error));
//   };
//   // const [showDropdown, setShowDropdown] = useState(null);
//   const [openAll, setOpenAll] = useState(false);
//   const [editAllItem, setEditAllItem] = useState(false);
//   // const [showDropdown, setShowDropdown] = useState(null);

//   const toggleDropdown = (index) => {
//     // If openAll is true, reset it to false since we are toggling an individual dropdown
//     setOpenAll(false);

//     // Toggle the individual dropdown
//     if (showDropdown === index) {
//       setShowDropdown(null);
//     } else {
//       setShowDropdown(index);
//     }
//   };

//   const handleOpenAll = () => {
//     setOpenAll(!openAll);
//     setShowDropdown(!openAll ? "all" : null);
//   };

//   const toggleDropdownEdit = (index) => {
//     if (openAll) {
//       setShowDropdown(null);
//       setOpenAll(false);
//     } else {
//       setShowDropdown(index);
//     }
//   };

//   // const [newWeight, setNewWeight] = useState('');
//   // const [newVolume, setNewVolume] = useState('');
//   // const handleBulkChange = () => {
//   //     const updatedItems = items.map(item => ({
//   //         ...item,
//   //         weight: newWeight,
//   //         volume: newVolume
//   //     }));
//   //     setItems(updatedItems);
//   // };
//   // console.log(items);

//   return (
//     <React.Fragment>
//       <Modal
//         show={collectionInfoModal}
//         close={setCollectionInfoModal}
//         closeBtn={true}
//         heading="COLLECTION STATUS"
//         content={
//           <div className={styles.text_center}>
//             <p className={styles.message}>{msgShow}</p>
//             <div className={styles.massageModal_btn}>
//               <RedOutButton
//                 title="CANCEL"
//                 handleSubmit={() => setCollectionInfoModal(false)}
//               />
//               <GreenButton
//                 title="OK"
//                 handleSubmit={() => setCollectionInfoModal(false)}
//               />
//             </div>
//           </div>
//         }
//       />

//       <div className={styles.main_Container}>
//         <div className="d-flex justify-content-between">
//           <div>
//             <span onClick={() => navigate(-1)}>
//               &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
//             </span>
//             <h3 className="mb-0">{collection_name.toUpperCase()}</h3>
//           </div>
//           <div>
//             <div className="d-flex justify-content-left align-items-center mt-2">
//               <h4 className="me-3 mb-0">Warehouse</h4>
//               <select
//                 className={styles.selectDropdown}
//                 value={collectionInfo.warehouse}
//                 onChange={(e) =>
//                   setCollectionInfo({
//                     ...collectionInfo,
//                     warehouse: e.target.value,
//                   })
//                 }
//               >
//                 <option value="">All Warehouse</option>
//                 {warehouses &&
//                   warehouses.map((item, index) => (
//                     <option key={index} value={item.warehousename}>
//                       {item.warehousename}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           </div>
//         </div>

//         <div className={styles.ItemsCollection_div}>
//           <div className="w-25">
//             <div className={styles.wholesale_seacrh_div}>
//               <label className={styles.search_Icon_wholesale}>
//                 {inputSearch.length === 0 && <Search color="#FFFFFF" />}
//               </label>
//               <input
//                 type="search"
//                 placeholder="Search item"
//                 className={styles.seacrh_inputField_wholesale}
//                 value={inputSearch}
//                 onChange={(e) => {
//                   setInputSearch(e.target.value);
//                 }}
//               />
//             </div>
//           </div>

//           <div className="w-25 d-flex justify-content-center">
//             <div className={styles.wholesale_tabs}>
//               <div
//                 className={`${styles.wholesale_single_tab} ${
//                   wholesaleTabs === "inventory" ? styles.tabs_active : ""
//                 }`}
//                 onClick={() => {
//                   setWholesaleTabs("inventory");
//                 }}
//               >
//                 Inventory
//               </div>
//               <div
//                 className={`${styles.wholesale_single_tab} ${
//                   wholesaleTabs === "items" ? styles.tabs_active : ""
//                 }`}
//                 onClick={() => {
//                   setWholesaleTabs("items");
//                 }}
//               >
//                 Items
//               </div>
//             </div>
//           </div>

//           <div className="w-25 d-flex justify-content-end">
//             <div
//               className={`${styles.editAll} me-4`}
//               onClick={() => handleOpenAll()}
//             >
//               <span>
//                 <AllInventoryViewIcon
//                   color={showDropdown === "all" ? "#2196F3" : "#FFFFFF"}
//                 />
//               </span>
//               {/* <span>Edit All</span> */}
//             </div>
//             <div
//               className={styles.editAll}
//               onClick={() => setEditAllItem(!editAllItem)}
//             >
//               <span>
//                 <Edit color={editAllItem ? "#2196F3" : "#FFFFFF"} />
//               </span>
//               {/* <span>Edit All</span> */}
//             </div>
//           </div>
//         </div>

//         {wholesaleTabs === "inventory" ? (
//           <div className={styles.table_heading_div}>
//             <span className={styles.table_heading_shot}>Status</span>
//             <span className={styles.table_heading}>Item</span>
//             <span className={styles.table_heading_mid}>Warehouse</span>
//             <span className={styles.table_heading}>Inventory Type</span>
//             <span className={styles.table_heading}>Weight (Kg)</span>
//             <span className={styles.table_heading}>Volume (CBM)</span>
//             <span className={styles.table_heading_shot}>Opening</span>
//             <span className={styles.table_heading_shot}>Purchase</span>
//             <span className={styles.table_heading_shot}>Sold</span>
//             <span className={styles.table_heading_shot}>Adjust</span>
//             <span className={styles.table_heading_shot}>Closing</span>
//             <span className={styles.table_dropdown}></span>
//           </div>
//         ) : (
//           <div className={styles.table_heading_div}>
//             <span className={styles.table_heading_status}>Status</span>
//             <span className={styles.table_heading}>Item</span>
//             <span className={styles.table_heading_mid}>Batch No.</span>
//             <span className={styles.table_heading_mid}>Warehouse</span>
//             <span className={styles.table_heading}>Inventory Type</span>
//             <span className={styles.table_heading}>Description</span>
//             <span className={styles.table_heading_shot}>HS Code</span>
//             <span className={styles.table_heading_shot}>Alias</span>
//             <span className={styles.table_heading_price}>Weight (Kg)</span>
//             <span className={styles.table_heading}>Volume (CBM)</span>
//             <span className={styles.table_heading_status}>Unit</span>
//             <span className={styles.table_heading_status}>MRP</span>
//             <span className={styles.table_heading_price}>Online Price</span>
//             <span className={styles.table_heading_price}>Offline Price</span>
//             <span className={styles.table_dropdown}></span>
//           </div>
//         )}

//         {loading ? (
//           <div className={"loadingMain"}>
//             <img src="/wallicon.gif" alt="walliconGIF" />
//           </div>
//         ) : (
//           <div className={styles.inventory_scroll_wholesale}>
//             {wholesaleTabs === "inventory" ? (
//               <>
//                 {items &&
//                   items.map((item, index) => (
//                     <div
//                       key={index}
//                       className={styles.table_content_div}
//                       onClick={() => toggleDropdown(index)}
//                     >
//                       <div className={styles.table_content_subdiv}>
//                         <span className={styles.table_txt_shot}>Active</span>
//                         <span className={styles.table_txt}>WSHT4455667</span>
//                         <span className={styles.table_txt_mid}>Golaganj</span>
//                         <span className={styles.table_txt}>Seller</span>
//                         <span className={styles.table_txt}>
//                           <b>12.50</b>
//                         </span>
//                         <span className={styles.table_txt}>
//                           <b>0.2233</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           <b>200</b>
//                         </span>
//                         <span className={styles.table_txt_dropdwon}>
//                           <span className={styles.reciptPositionAbsolute}>
//                             {showDropdown === index ||
//                             showDropdown === "all" ? (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   toggleDropdown(index);
//                                 }}
//                               >
//                                 <UpArrowCircle color="#FFFFFF" />
//                               </span>
//                             ) : (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   toggleDropdown(index);
//                                 }}
//                               >
//                                 <DownArrowCircle color="#FFFFFF" />
//                               </span>
//                             )}
//                           </span>
//                         </span>
//                       </div>
//                       {showDropdown === index || showDropdown === "all" ? (
//                         <div
//                           className={styles.dropdown_wholesale_div}
//                           onClick={() => {}}
//                         >
//                           {true && (
//                             <div className={styles.dropdown_reason_wholesale}>
//                               <div>
//                                 <span>Outsourced Item : </span>
//                                 <span>Yes</span>
//                               </div>
//                               <div>
//                                 <span>Production Item : </span>
//                                 <span>No</span>
//                               </div>
//                               <div>
//                                 <span>Sell Condition : </span>
//                                 <span>Not Applicable</span>
//                               </div>
//                               <div>
//                                 <span>Tax Rate : </span>
//                                 <span>Not Applicable</span>
//                               </div>
//                               <div>
//                                 <span>Recommended Items : </span>
//                                 <span>WAS400 WSD334</span>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       ) : (
//                         ""
//                       )}
//                     </div>
//                   ))}
//               </>
//             ) : (
//               <>
//                 {items &&
//                   items.map((item, index) => (
//                     <div
//                       key={index}
//                       className={styles.table_content_div}
//                       onClick={() => {
//                         editAllItem
//                           ? toggleDropdownEdit(index)
//                           : toggleDropdown(index);
//                       }}
//                     >
//                       <div className={styles.table_content_subdiv}>
//                         <span className={styles.table_txt_status}>Active</span>
//                         <span className={styles.table_txt}>WSHT44556677</span>
//                         <span className={styles.table_txt_mid}>Seller</span>
//                         <span className={styles.table_txt_mid}>Golaganj</span>
//                         <span className={styles.table_txt}>Own</span>
//                         <span
//                           className={`${styles.table_txt} ${styles.description_truncate}`}
//                           title={"Wallpaper"}
//                         >
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_txt}
//                             />
//                           ) : (
//                             "Wallpaper"
//                           )}
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_txt}
//                             />
//                           ) : (
//                             " 48142000"
//                           )}
//                         </span>
//                         <span className={styles.table_txt_shot}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_txt}
//                             />
//                           ) : (
//                             "AL5000"
//                           )}
//                         </span>
//                         <span className={styles.table_txt_price}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>12.50</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>0.2233</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_status}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>SQ Ft</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_status}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>200</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_price}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>200</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_price}>
//                           {editAllItem ? (
//                             <input
//                               type="text"
//                               value=""
//                               className={styles.itemEdit_input_number}
//                             />
//                           ) : (
//                             <b>200</b>
//                           )}
//                         </span>
//                         <span className={styles.table_txt_dropdwon}>
//                           <span className={styles.reciptPositionAbsolute}>
//                             {showDropdown === index ||
//                             showDropdown === "all" ? (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   if (editAllItem) {
//                                     toggleDropdownEdit(index);
//                                   } else {
//                                     toggleDropdown(index);
//                                   }
//                                 }}
//                               >
//                                 <UpArrowCircle color="#FFFFFF" />
//                               </span>
//                             ) : (
//                               <span
//                                 onClick={(e) => {
//                                   e.stopPropagation();
//                                   if (editAllItem) {
//                                     toggleDropdownEdit(index);
//                                   } else {
//                                     toggleDropdown(index);
//                                   }
//                                 }}
//                               >
//                                 <DownArrowCircle color="#FFFFFF" />
//                               </span>
//                             )}
//                           </span>
//                         </span>
//                       </div>
//                       {showDropdown === index || showDropdown === "all" ? (
//                         <div
//                           className={styles.dropdown_wholesale_div}
//                           onClick={() => {}}
//                         >
//                           {true && (
//                             <div className={styles.dropdown_reason_wholesale}>
//                               <div className="d-flex">
//                                 <span>Outsourced Item : </span>
//                                 {editAllItem ? (
//                                   <div className={styles.activeDisable_btn}>
//                                     <label className="ms-2 me-3">
//                                       <input
//                                         required
//                                         className={styles.activeDisable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Active"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Active" ? true : false}
//                                       />
//                                       Yes
//                                     </label>
//                                     <label>
//                                       <input
//                                         required
//                                         className={styles.activeDisable_disable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Disable"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Disable" ? true : false}
//                                       />
//                                       No
//                                     </label>
//                                   </div>
//                                 ) : (
//                                   <>&nbsp;Yes</>
//                                 )}
//                               </div>
//                               <div>
//                                 <span>Limit : </span>
//                                 {editAllItem ? (
//                                   <input
//                                     type="text"
//                                     value=""
//                                     className={styles.itemEdit_input}
//                                   />
//                                 ) : (
//                                   <>&nbsp;50 pcs</>
//                                 )}
//                               </div>
//                               <div className="d-flex">
//                                 <span>Production Item : </span>
//                                 {editAllItem ? (
//                                   <div className={styles.activeDisable_btn}>
//                                     <label className="ms-2 me-3">
//                                       <input
//                                         required
//                                         className={styles.activeDisable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Active"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Active" ? true : false}
//                                       />
//                                       Yes
//                                     </label>
//                                     <label>
//                                       <input
//                                         required
//                                         className={styles.activeDisable_disable}
//                                         type="radio"
//                                         name="onlineVisibility"
//                                         value="Disable"
//                                         // onChange={(e) => setAddData({ ...addData, onlineVisibility: e.target.value })}
//                                         // checked={addData.onlineVisibility === "Disable" ? true : false}
//                                       />
//                                       No
//                                     </label>
//                                   </div>
//                                 ) : (
//                                   <>&nbsp;Yes</>
//                                 )}
//                               </div>
//                               <div>
//                                 <span>Sell condition : </span>
//                                 {editAllItem ? (
//                                   <select
//                                     value=""
//                                     className={styles.itemEdit_input}
//                                   >
//                                     <option value="">Not Applicable</option>
//                                   </select>
//                                 ) : (
//                                   <>Not Applicable</>
//                                 )}
//                               </div>
//                               <div>
//                                 <span>Tax Rate : </span>
//                                 {editAllItem ? (
//                                   <select
//                                     value=""
//                                     className={styles.itemEdit_input}
//                                   >
//                                     <option value="">Not Applicable</option>
//                                   </select>
//                                 ) : (
//                                   <>Not Applicable</>
//                                 )}
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       ) : (
//                         ""
//                       )}
//                     </div>
//                   ))}
//               </>
//             )}

//             {/* <div className={styles.collectionInfoCard}>
//                                 <GreenOutButton btnType="button" title="SAVE" css={styles.updateCollectionBtn} handleSubmit={() => { handleSubmitCollectionInfo() }} />
//                                 <h2 className={styles.collection_heading}>Collection Info</h2>
//                                 <div className={styles.collectionInfo_div}>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Warehouse</span>
//                                         <select
//                                             className={styles.selectDropdown}
//                                             value={collectionInfo.warehouse}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, warehouse: e.target.value })}
//                                         >
//                                             <option value="">All Warehouse</option>
//                                             {warehouses && warehouses.map((item, index) =>
//                                                 <option key={index} value={item.warehousename}>{item.warehousename}</option>
//                                             )}
//                                         </select>
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Description</span>
//                                         <input
//                                             type='text'
//                                             className={styles.inputField}
//                                             value={collectionInfo.description}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, description: e.target.value })}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>HS Code</span>
//                                         <input
//                                             type='text'
//                                             className={styles.inputField}
//                                             value={collectionInfo.hscode}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, hscode: e.target.value })}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Unit</span>
//                                         <select
//                                             className={styles.selectDropdown}
//                                             value={collectionInfo.unit}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, unit: e.target.value })}
//                                         >
//                                             <option value="" hidden>Select</option>
//                                             <option value="Roll">Roll</option>
//                                             <option value="Pcs">Pcs</option>
//                                             <option value="Box">Box</option>
//                                             <option value="Sq Meter">Sq Meter</option>
//                                             <option value="Meter">Meter</option>
//                                             <option value="Sq Feet">Sq Feet</option>
//                                             <option value="Feet">Feet</option>
//                                             <option value="Kg">Kg</option>
//                                         </select>
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Dealer MRP</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.dmrp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, dmrp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Offline Price</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.odp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, odp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Dealer Price</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.dp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, dp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>

//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Cust Unit</span>
//                                         <select
//                                             className={styles.selectDropdown}
//                                             value={collectionInfo.cu}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, cu: e.target.value })}
//                                         >
//                                             <option value="" hidden>Select</option>
//                                             <option value="Roll">Roll</option>
//                                             <option value="Pcs">Pcs</option>
//                                             <option value="Box">Box</option>
//                                             <option value="Sq Meter">Sq Meter</option>
//                                             <option value="Meter">Meter</option>
//                                             <option value="Sq Feet">Sq Feet</option>
//                                             <option value="Feet">Feet</option>
//                                             <option value="Kg">Kg</option>
//                                         </select>
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Cust MRP</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.cmrp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, cmrp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>Cust Price </span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.cp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, cp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>
//                                     <div className={styles.details_div}>
//                                         <span className={styles.details_txt}>&#x3e;Cust Price</span>
//                                         <input
//                                             type='number'
//                                             className={styles.inputField}
//                                             value={collectionInfo.mcp}
//                                             onChange={(e) => setCollectionInfo({ ...collectionInfo, mcp: e.target.value })}
//                                             onWheel={(e) => e.target.blur()}
//                                         />
//                                     </div>

//                                     {
//                                         collectionInfo.accessories && collectionInfo.accessories.map((item, index) =>
//                                             <div key={index} className={styles.details_div}>
//                                                 <span className={styles.details_txt}>{item.accessoriesName}</span>
//                                                 <input
//                                                     type='text'
//                                                     className={styles.inputField}
//                                                     value={item.accessoriesPrice}
//                                                     onChange={(e) => setCollectionInfo({
//                                                         ...collectionInfo, accessories: [...collectionInfo.accessories.map((obj1, i1) => {
//                                                             if (i1 === index) {
//                                                                 obj1.accessoriesPrice = e.target.value;
//                                                             }
//                                                             return obj1;
//                                                         })
//                                                         ]
//                                                     })
//                                                     }
//                                                 />
//                                             </div>
//                                         )}
//                                 </div>
//                             </div> */}

//             {/* <h2 className={styles.collection_heading}>Items in Collection</h2>
//                             <div className={styles.ItemsCollection_div}>
//                                 <div className={styles.seacrh_div}>
//                                     <label className={styles.search_Icon}>{inputSearch.length === 0 && <Search color="#FFFFFF" />}</label>
//                                     <input
//                                         type="search"
//                                         placeholder='Search item'
//                                         className={styles.seacrh_inputField}
//                                         value={inputSearch}
//                                         onChange={(e) => { setInputSearch(e.target.value) }}
//                                     />
//                                 </div>

//                                 <div className={styles.editAll}>
//                                     <span><Edit color="#FFFFFF" /></span>
//                                     <span>Edit All</span>
//                                 </div>
//                             </div> */}

//             {/* <div className={styles.collectionCard} >
//                                 <div className={styles.totalItem_div}>
//                                     Total <span className={styles.qty}>{items?.length ? items.length : ''}&nbsp;Items</span>
//                                 </div>
//                                 <div className={styles.weight_div}>
//                                     <div className={styles.view_div}>
//                                         <span className={styles.qty}>Weight</span>
//                                         <span className={styles.qty_txt}>KG</span>
//                                     </div>
//                                     <div className={styles.view_div}>
//                                         <span className={styles.qty}>Volume</span>
//                                         <span className={styles.qty_txt}>CBM</span>
//                                     </div>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.openingstock}</span>
//                                     <span className={styles.qty_txt}>Opening</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.purchase}</span>
//                                     <span className={styles.qty_txt}>Purchase</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.sold}</span>
//                                     <span className={styles.qty_txt}>Sold</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.adjust}</span>
//                                     <span className={styles.qty_txt}>Adjust</span>
//                                 </div>
//                                 <div className={styles.view_div}>
//                                     <span className={styles.qty}>{totalItems.closing}</span>
//                                     <span className={styles.qty_txt}>Closing</span>
//                                 </div>
//                             </div> */}

//             {/* {
//                                 items && items.map((item, index) =>
//                                     <div key={index} className={styles.collectionCard_div} >
//                                         <div className={styles.totalItem_details_div}>
//                                             <div className={styles.activeRadioBtn}>
//                                                 <input type="radio" defaultChecked={item.status === "Active" ? true : false} />
//                                                 <span className={styles.status_active}>Active</span>
//                                             </div>
//                                             <h2 className={styles.itemName}>{item.itemNo}</h2>
//                                             <div className={styles.outsourcedProduct}>
//                                                 <div>Outsourced Product</div>
//                                                 <div className={styles.outsourcedStatus}>
//                                                     <input type="radio" disabled={item.outsourcedProduct === "Yes" ? false : true} defaultChecked={item.outsourcedProduct === "Yes" ? true : false} />Yes
//                                                     <input type="radio" disabled={item.outsourcedProduct === "No" ? false : true} defaultChecked={item.outsourcedProduct === "No" ? true : false} />No
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className={styles.weight_div}>
//                                             <div className={styles.details_div}>
//                                                 <span className={styles.details_txt}>Weight (in kg)</span>
//                                                 <input
//                                                     type='number'
//                                                     className={styles.inputField}
//                                                     value={item.weight}
//                                                     onChange={(e) => {
//                                                         const list = [...items];
//                                                         list[index]["weight"] = e.target.value;
//                                                         setItems(list);
//                                                     }}
//                                                     onWheel={(e) => e.target.blur()}
//                                                 />
//                                             </div>
//                                             <div className={`${styles.details_div} ml-2`}>
//                                                 <span className={styles.details_txt}>Volume (CBM)</span>
//                                                 <input
//                                                     type='number'
//                                                     className={styles.inputField}
//                                                     value={item.vm}
//                                                     onChange={(e) => {
//                                                         const list = [...items];
//                                                         list[index]["vm"] = e.target.value;
//                                                         setItems(list);
//                                                     }}
//                                                     onWheel={(e) => e.target.blur()}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Opening Stock</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.openingstock}
//                                                 onChange={(e) => {
//                                                     const list = [...items];
//                                                     list[index]["openingstock"] = e.target.value;
//                                                     setItems(list);
//                                                 }}
//                                                 onWheel={(e) => e.target.blur()}
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Purchase</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.purchase}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Sold</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.sold}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Adjust</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.adjust}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                         <div className={styles.details_div}>
//                                             <span className={styles.details_txt}>Closing</span>
//                                             <input
//                                                 type='number'
//                                                 className={styles.inputField}
//                                                 value={item.closing}
//                                                 onChange={(e) => { }}
//                                                 disabled
//                                             />
//                                         </div>
//                                     </div>
//                                 )} */}

//             {editAllItem && (
//               <GreenButton
//                 btnType="button"
//                 title="SAVE"
//                 css={styles.CollectionBtn_items}
//                 handleSubmit={() => {
//                   handleSubmitCollectionInfo();
//                 }}
//               />
//             )}
//           </div>
//         )}
//       </div>
//     </React.Fragment>
//   );
// };

// export default CollectionInfo;
