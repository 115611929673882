// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { useAuth } from '../../components/context/Auth'
// import { LargeEyeIcon } from '../../components/icons/Icon'
// import styles from '../inventorymanager/Inventories.module.css'

// const Inventory = () => {

//     const { managerId } = useAuth()
//     const navigate = useNavigate()
//     const [loading, setLoading] = useState(false)
//     const [data, setData] = useState([])

//     useEffect(() => {
//         if (managerId) {
//             setLoading(true)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "inventory/products", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         setLoading(false)
//                         setData(result.data)
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }, [managerId])

//     return (
//         <React.Fragment>
//             <div className={styles.main_Container}>
//                 <h1 className={styles.inventory_heading}>Inventory</h1>
//                 <div className={styles.inventory_scroll_div}>
//                     {
//                         loading ? <div className={'loadingMain'}>
//                             <img src='/wallicon.gif' alt='walliconGIF' />
//                         </div> :

//                             data && data.map((item, index) =>
//                                 <div key={index} className={styles.InventoryCard} onClick={() => navigate('/inventory/' + item.product_name.replace(/\s/g, "-").toLowerCase() + "?product_id=" + item._id)}>
//                                     <div className={styles.productName_div}>
//                                         <div className={styles.activeRadioBtn}>
//                                             <input type="radio" defaultChecked={item.status === "Active" ? true : false} />
//                                             <span className={styles.status_active}>Active</span>
//                                         </div>
//                                         <h2 className={styles.productName}>{item.product_name}</h2>
//                                     </div>
//                                     <div className={styles.produts_details_div}>
//                                         <div className={styles.view_div}>
//                                             <span className={styles.qty}>{item.collCount}</span>
//                                             <span className={styles.qty_txt}>Colls</span>
//                                         </div>
//                                         <div className={styles.view_div}>
//                                             <span className={styles.qty}>{item.itemCount}</span>
//                                             <span className={styles.qty_txt}>Items</span>
//                                         </div>
//                                         <div className={styles.view_div}>
//                                             <span className={styles.qty}>{item.qty.toFixed(2)}</span>
//                                             <span className={styles.qty_txt}>Qty</span>
//                                         </div>
//                                     </div>
//                                     <div className={styles.view_div}>
//                                         <span><LargeEyeIcon color="#FFFFFF" /></span>
//                                         <span className={styles.qty_txt}>View</span>
//                                     </div>
//                                 </div>
//                             )}
//                 </div>
//             </div>
//         </React.Fragment>
//     )
// }

// export default Inventory

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  ArrowRightIcon,
  EyeBtn,
  TestTopAddDealerIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import styles from "./Inventory.module.css";
import fetchData from "../../APIsControll/apiControll";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import Button from "../../components/updatedComponents/Dealers/Button";

const Inventory = () => {
  const { managerId } = useAuth();
  const { mode } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getInventoryData = async () => {
      const apiData = {
        managerId: managerId,
        searchKeyword: "",
      };
      try {
        setLoading(true);
        const res = await fetchData("inventory/products", apiData);
        if (res.status === "success") {
          setData(res.data);
          setLoading(false);
          console.log(res.msg);
        }
      } catch (error) {
        console.log("Error while fetching inventory data ", error);
      }
    };
    if (managerId) {
      getInventoryData();
    }
  }, [managerId]);

  console.log("Inventory Data ", data);

  return (
    <React.Fragment>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <div
              className={styles.inventory_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin: 0,
                  paddingTop: "0.21rem",
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.inventory_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
                display: "flex",
                alignItems: "center",
              }}
            >
              Product
              <ArrowRightIcon color={mode ? "#9C9797" : "#646B88"} />
            </p>
          </div>

          <div className={styles.tax_rate_btn}>
            <Button
              buttonIcon={<TestTopAddDealerIcon />}
              buttonText={"Tax Rate"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                navigate("/taxrate");
              }}
            />
          </div>
        </div>
        <div className={styles.inventory_search_bar}>
          <SearchBar />
        </div>
        <div className={styles.inventory_table_container}>
          <ul className={styles.responsiveTable}>
            <li
              className={styles.tableHeader}
              style={
                mode
                  ? {
                      backgroundColor: "#232529",
                      color: "#fafafa",
                      border: "1px solid #646B88",
                    }
                  : { backgroundColor: "#D9DCE5", color: "#646B88" }
              }
            >
              <div className="col">Status</div>
              <div className="col">Product</div>
              <div className="col">Collections</div>
              <div className="col">Items</div>
              <div className="col">Quantity</div>
              <div className="col" style={{ visibility: "hidden" }}>
                Icon
              </div>
            </li>

            <div className={styles.inventory_data_container}>
              {loading ? (
                <p
                  style={
                    mode
                      ? {
                          color: "#fafafa",
                        }
                      : {}
                  }
                >
                  Loading...
                </p>
              ) : (
                data &&
                data.map((item, index) => (
                  <li
                    key={index}
                    className={styles.tableHeader}
                    style={
                      mode
                        ? {
                            backgroundColor: "#1B1D21",
                            color: "#fafafa",
                            border: "1px solid #646B88",
                            cursor: "pointer",
                          }
                        : { backgroundColor: "#FFFFFF", cursor: "pointer" }
                    }
                    onClick={() =>
                      navigate(
                        "/inventory/" +
                          item.product_name.replace(/\s/g, "-").toLowerCase() +
                          "?product_id=" +
                          item._id
                      )
                    }
                  >
                    <div
                      className="col"
                      style={{
                        color: item.status === "Active" ? "#34A853" : "#F93E3E",
                      }}
                    >
                      {item.status === "Active" ? "Active" : "Inactive"}
                    </div>
                    <div className="col">{item.product_name}</div>
                    <div className="col">{item.collCount}</div>
                    <div className="col">{item.itemCount}</div>
                    <div className="col">{item.qty.toFixed(2)}</div>
                    <div className="col">
                      <EyeBtn color={mode ? "#ffffff" : "#000000"} />
                    </div>
                  </li>
                ))
              )}
            </div>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Inventory;
