// import React, { useState } from 'react';
// import styles from './pagnation.module.css'; // Import CSS module
// import { useAuth } from '../../context/Auth';

// const data = Array.from({ length: 1110 }, (_, index) => `Item ${index + 1}`);

// const PaginationComponent = () => {
//   const { mode } = useAuth()
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;
//   const totalPages = Math.ceil(data.length / itemsPerPage);



//   // Handle page change
//   const handlePageChange = (pageNumber) => {
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   // Helper to render ellipsis
//   const renderEllipsis = (key) => (
//     <div key={key} className={mode ? styles.ellipsis_dark : styles.ellipsis} >
//       <span>
//         ...
//       </span>
//     </div>
//   );

//   // Render the page numbers
//   const renderPageNumbers = () => {
//     const pageNumbers = [];

//     // Always show the first two pages
//     pageNumbers.push(
//       <button
//         key={1}
//         onClick={() => handlePageChange(1)}
//         className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === 1 ? styles.activeButton : ''
//           }`}
//       >
//         1
//       </button>,
//       <button
//         key={2}
//         onClick={() => handlePageChange(2)}
//         className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === 2 ? styles.activeButton : ''
//           }`}
//       >
//         2
//       </button>
//     );

//     // Show ellipsis if current page is beyond 3rd page
//     if (currentPage > 3) {
//       pageNumbers.push(renderEllipsis('ellipsis1'));
//     }

//     // Show the current, previous, and next pages
//     const startPage = Math.max(3, currentPage - 1); // Starting from the 3rd page
//     const endPage = Math.min(totalPages - 2, currentPage + 1); // Ending two pages before the last

//     for (let i = startPage; i <= endPage; i++) {
//       pageNumbers.push(
//         <button
//           key={i}
//           onClick={() => handlePageChange(i)}
//           className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === i ? styles.activeButton : ''
//             }`}
//         >
//           {i}
//         </button>
//       );
//     }

//     // Show ellipsis if current page is before the last two pages
//     if (currentPage < totalPages - 2) {
//       pageNumbers.push(renderEllipsis('ellipsis2'));
//     }

//     // Always show the last two pages
//     pageNumbers.push(
//       <button
//         key={totalPages - 1}
//         onClick={() => handlePageChange(totalPages - 1)}
//         className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === totalPages - 1 ? styles.activeButton : ''
//           }`}
//       >
//         {totalPages - 1}
//       </button>,
//       <button
//         key={totalPages}
//         onClick={() => handlePageChange(totalPages)}
//         className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === totalPages ? styles.activeButton : ''
//           }`}
//       >
//         {totalPages}
//       </button>
//     );

//     return pageNumbers;
//   };

//   return (
//     <div>
//       {/* Pagination controls */}
//       <div className={styles.pagination}>
//         {/* First page button */}
//         <button
//           className={mode ? styles.pageButton_dark : styles.pageButton}
//           onClick={() => handlePageChange(currentPage - 5)}
//           disabled={currentPage === 1}
//         >
//           {'<<'}
//         </button>

//         {/* Previous button */}
//         <button
//           className={mode ? styles.pageButton_dark : styles.pageButton}
//           onClick={() => handlePageChange(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           {'<'}
//         </button>

//         {/* Render page numbers */}
//         {renderPageNumbers()}

//         {/* Next button */}
//         <button
//           className={mode ? styles.pageButton_dark : styles.pageButton}
//           onClick={() => handlePageChange(currentPage + 1)}
//           disabled={currentPage === totalPages}
//         >
//           {'>'}
//         </button>

//         {/* Last page button */}
//         <button
//           className={mode ? styles.pageButton_dark : styles.pageButton}
//           onClick={() => handlePageChange(currentPage + 5)}
//           disabled={currentPage === totalPages}
//         >
//           {'>>'}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default PaginationComponent;


import React from 'react';
import styles from './pagnation.module.css'; 
import { useAuth } from '../../context/Auth';

// const data = Array.from({ length: 1110 }, (_, index) => `Item ${index + 1}`);

const PaginationComponent = ({ totalPages , currentPage, setCurrentPage}) => {
  const { mode } = useAuth();
  // const [] = useState(1);
  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Render page numbers around the current page
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const range = 1; // Number of pages to show before and after the current page

    // Always show the first page
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === 1 ? styles.activeButton : ''}`}
        >
          1
        </button>
      );
    }

    // Add ellipsis if there's a gap before the first page in the range
    if (currentPage > range + 1) {
      pageNumbers.push(
        <div key="ellipsis1" className={mode ? styles.ellipsis_dark : styles.ellipsis}>
          <span>...</span>
        </div>
      );
    }

    // Add pages around the current page
    for (let i = Math.max(2, currentPage - range); i <= Math.min(totalPages - 1, currentPage + range); i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === i ? styles.activeButton : ''}`}
        >
          {i}
        </button>
      );
    }

    // Add ellipsis if there's a gap after the last page in the range
    if (currentPage < totalPages - range) {
      pageNumbers.push(
        <div key="ellipsis2" className={mode ? styles.ellipsis_dark : styles.ellipsis}>
          <span>...</span>
        </div>
      );
    }

    // Always show the last page if there are more than one page
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`${mode ? styles.pageButton_dark : styles.pageButton} ${currentPage === totalPages ? styles.activeButton : ''}`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      {/* Pagination controls */}
      <div className={styles.pagination}>
        <button
          className={mode ? styles.pageButton_dark : styles.pageButton}
          onClick={() => handlePageChange(Math.max(1, currentPage - 5))}
          disabled={currentPage === 1}
        >
          {'<<'}
        </button>

        <button
          className={mode ? styles.pageButton_dark : styles.pageButton}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>

        {renderPageNumbers()}

        <button
          className={mode ? styles.pageButton_dark : styles.pageButton}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>

        <button
          className={mode ? styles.pageButton_dark : styles.pageButton}
          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 5))}
          disabled={currentPage === totalPages}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default PaginationComponent;
