import { useAuth } from "../../context/Auth";
import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
import styles from "./FromComponent.module.css";
import { useState, useEffect, useRef } from "react";

const CustomDropdown = ({ label, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);
  const { mode } = useAuth();

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.dropdown_container} ref={dropdownRef}>
      <label
        className={styles.dropdown_label}
        style={mode ? { color: "#fff" } : {}}
      >
        {label}
      </label>
      <div className={styles.custom_select}>
        <div
          className={styles.dropdown_trigger}
          style={mode ? { backgroundColor: "#1B1D21", border: "none" } : {}}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={styles.dropdown_placeholder}
            style={{ color: mode ? "#fff" : "#646b88" }}
          >
            {selectedOption ? (
              selectedOption.label
            ) : (
              <>
                <p>Select</p>
                {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <ul
            className={styles.dropdown_list}
            style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
          >
            {options.map((option, index) => (
              <li
                key={index}
                className={`${styles.dropdown_item} ${
                  mode ? styles.dropdown_item_dark : ""
                }`}
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
