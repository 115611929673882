import React, { useState, useEffect } from "react";
import styles from "./FromComponent.module.css";
import { NavbarArrowDownIcon } from "../../icons/Icon";

const MultiSelectDropdown = ({ label, options, initialOptions }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    initialOptions?.map((opt) => opt.value) 
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setSelectedOptions(initialOptions?.map((opt) => opt.value));
  }, [initialOptions]);

  const handleOptionChange = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const isOptionSelected = (value) => selectedOptions.includes(value);

  return (
    <div className={styles.dropdownContainer}>
      <div
        className={`${styles.dropdownHeader} ${dropdownOpen ? "open" : ""}`}
        onClick={toggleDropdown}
      >
        {selectedOptions?.length > 0
          ? `Selected (${selectedOptions?.length})`
          : label}
        <NavbarArrowDownIcon />
      </div>

      {dropdownOpen && (
        <div className={styles.dropdownOptions}>
          {options?.map((option) => (
            <div key={option.value} className={styles.optionLabel}>
              <label>
                <input
                  type="checkbox"
                  value={option.value}
                  checked={isOptionSelected(option.value)}
                  onChange={() => handleOptionChange(option.value)} 
                />
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
