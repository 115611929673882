import React, { useEffect, useState } from 'react'
import { GreenButton } from '../../components/Buttons'
import { useAuth } from '../../components/context/Auth'
import { Envelope, MessageIcon, Search, WhatsappIcon } from '../../components/icons/Icon'
import ProgressCircle from '../../components/ProgressCircle'
import styles from "./Admin.module.css"
import Paginantion from '../../components/Paginantion'

const SuperAdmin = () => {

  const { managerId } = useAuth()
  const [data, setData] = useState([])
  const [orders, setOrders] = useState([])
  const [keyword, setKeyword] = useState('')
  const [sortKey, setSortKey] = useState('New Order')

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "managerId": managerId,
        "keyword": keyword,
        "sortKey": sortKey,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + "admin/adminAllOrder?page=1", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === 'success') {
            setData(result.data)
            setOrders(result.orders)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [managerId, keyword, sortKey])

  // console.log('keyword', keyword);

  return (
    <React.Fragment>

      <div className={styles.right_main}>
        <div className='d-flex justify-content-between mt-3'>
          <h1 className={styles.superAdmin_heading}>
            Dashboard
          </h1>
          <div className={styles.dashboard_header_select_days}>
            <div className={`${styles.dashboard_days_select} ${styles.days_active}`}>Order</div>
            <div className={styles.dashboard_days_select}>Dispatch</div>
            <div className={styles.dashboard_days_select}>Packing</div>
            <div className={styles.dashboard_days_select}>Marketing</div>
          </div>
        </div>


        <div className={styles.superAdmin_height}>
          <div className={styles.dashboard_main_container}>
            <div className={styles.statusCard_div}>
              {orders.length === 0 ? Array.from({ length: 6 }).map((_, idx) =>
                <div key={idx} className='skeleton'>
                  <span className={styles.status_count_loading}>0</span>
                  <span className={styles.statusTxt}>status</span>
                </div>
              )
                :
                orders.map((item, index) =>
                  <div key={index} className={styles.statusCard}
                    onClick={() => setSortKey(item.status)}
                  >
                    <span className={
                      item.status === 'New Order' ? styles.statusNew :
                        item.status === 'In Progress' ? styles.statusYellow :
                          item.status === 'Payment Pending' ? styles.statusRed :
                            item.status === 'Completed' ? styles.statusBlue :
                              item.status === 'Packed' ? styles.statusOrange :
                                item.status === 'Dispatched' ? styles.statusGreen :
                                  ''}>
                      {item.count}
                    </span>
                    <span className={styles.statusTxt}>{item.status}</span>
                  </div>
                )
              }
            </div>

            <div className={styles.shortBy_search}>
              <div className={styles.dashboard_search_div}>
                <input
                  type="search"
                  placeholder='Search'
                  className={styles.dashboard_search}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <Search color="#ffffff" css={styles.dashboard_search_icon} />
              </div>

              <div>
                <label className={styles.dashboard_sortBy_txt}>Sort By </label>
                <select
                  className={styles.dashboard_sortBy_select}
                  value={sortKey}
                  onChange={(e) => setSortKey(e.target.value)}
                >
                  <option value="" hidden>View All</option>
                  {
                    orders && orders.map((item, index) =>
                      <option key={index} value={item.status} >{item.status}</option>
                    )
                  }
                </select>
              </div>
            </div>

            <div className={styles.adminHeight}>
              <table className={styles.itemTable}>
                <thead>
                  <tr className={styles.item}>
                    <th>Date</th>
                    <th>  Name & City  </th>
                    <th>Cart Type</th>
                    <th>Order Manager</th>
                    <th>Order Id</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Last Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map((val, index) =>
                    <tr key={index} className={styles.item}>
                      <td>{val.orderDate} </td>
                      <td> {val.storeName + ", " + val.city}</td>
                      <td>{val.cartType} </td>
                      <td>{val.ordermanager} </td>
                      <td>{val.orderId} </td>
                      <td>{val.totalAmount} </td>
                      <td className={`${val.status === 'Dispatched' || val.status === 'Packed' ?
                        styles.table_txt_green : (val.status === 'Payment Pending') ?
                          styles.table_txt_red : (val.status === 'In Progress') ?
                            styles.table_txt_yellow : val.status === 'New Order' ?
                              styles.table_txt_blue : ''}`}>
                        {val.status}
                      </td>
                      <td>{val.lastAction} </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <Paginantion />

          </div>



          <div className={styles.actionPending_div}>

            <div className={styles.actionPending}>
              <h3 className={styles.actionPending_heading}>ACTIONS PENDING</h3>
              <div className={styles.dashboard_select_overall}>
                <div className={`${styles.dashboard_overall_select} ${styles.days_active}`}>Today</div>
                <div className={styles.dashboard_overall_select}>Overall</div>
              </div>

              <div className={styles.progressBar_center}>
                <ProgressCircle percent={55} />
              </div>

              <div className={styles.takeAction}><GreenButton title="TAKE ACTION" /></div>
            </div>

            <div className={styles.InactiveDealers}>
              <h3 className={styles.actionPending_heading}>INACTIVE DEALERS</h3>
              <div className={styles.InactiveDealers_div}>

                <div className={styles.InactiveDealers_left}>
                  <div className={styles.dashboard_select_overall}>
                    <div className={`${styles.dashboard_overall_select} ${styles.days_active}`}>15 Days</div>
                    <div className={styles.dashboard_overall_select}>Over 15 Days</div>
                  </div>

                  <div className={styles.selectAll}>
                    <input type='checkbox' />&nbsp;Select All
                  </div>

                  <div>
                    <div className={styles.inactive_profile_details}>
                      <input type='checkbox' />
                      <img src='/assets/dealerprofile.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                      <div className={styles.profile_details}>
                        <span><b>Demo Interiors</b></span>
                        <span>DEMO0906/08/02</span>
                        <span>Last Order: <b>12/07/2021</b></span>
                      </div>
                    </div>
                    <div className={styles.inactive_profile_details}>
                      <input type='checkbox' />
                      <img src='/assets/dealerprofile2.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                      <div className={styles.profile_details}>
                        <span><b>Demo Interiors</b></span>
                        <span>DEMO0906/08/02</span>
                        <span>Last Order: <b>12/07/2021</b></span>
                      </div>
                    </div>
                    <div className={styles.inactive_profile_details}>
                      <input type='checkbox' />
                      <img src='/assets/dealerprofile3.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                      <div className={styles.profile_details}>
                        <span><b>Demo Interiors</b></span>

                        <span>DEMO0906/08/02</span>
                        <span>Last Order: <b>12/07/2021</b></span>
                      </div>
                    </div>
                    <div className={styles.inactive_profile_details}>
                      <input type='checkbox' />
                      <img src='/assets/dealerprofile4.png' alt='dealer_profile' width={60} height={60} className={styles.inactive_dealer_img} />
                      <div className={styles.profile_details}>
                        <span><b>Demo Interiors</b></span>
                        <span>DEMO0906/08/02</span>
                        <span>Last Order: <b>12/07/2021</b></span>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='w-100'>
                  <div className={styles.InactiveIcon_main}>
                    <div className={styles.inactiveIcon_div}>
                      <div><Envelope color='#FFF' css={styles.inactiveIcon} /></div>
                    </div>
                    <div className={styles.inactiveIcon_div}>
                      <div><MessageIcon color='#FFF' css={styles.inactiveIcon} /></div>
                    </div>
                    <div className={styles.inactiveIcon_div}>
                      <div><WhatsappIcon color='#FFF' css={styles.inactiveIcon} /></div>
                    </div>
                  </div>
                  <textarea rows="13" placeholder='Type Your Message Here..' className={styles.textarea_css} />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default SuperAdmin