import { useAuth } from "../../components/context/Auth";
import {
  ManagerSortByIcon,
  ManagerWareHouseIcon,
  TestTopAddDealerIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import styles from "./ManagerJsx.module.css";

import DealerInfoCard from "../../components/updatedComponents/Dealers/DealerInfoCard";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import Button from "../../components/updatedComponents/Dealers/Button";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import fetchData from "../../APIsControll/apiControll";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";

const Managers = () => {
  const { mode, managerId } = useAuth();
  const containerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [managersData, setManagersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchAllManagers = async () => {
    const apiData = { managerId: managerId };
    const endpoint = `managers/allManagersList?page=${page}&limit=${13}`;

    try {
      setLoading(true);
      const result = await fetchData(endpoint, apiData);

      if (result.status === "success") {
        setManagersData((prevData) => {
          const newManagers = result.managers.filter(
            (manager) => !prevData.some((m) => m._id === manager._id)
          );
          newManagers.reverse();
          return [...prevData, ...newManagers];
        });
        setPages(result.pages);

        if (page >= result.pages) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error while fetching managers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (loading || !hasMore) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      console.log("Reached bottom, loading more data...");
      setPage((prevPage) => prevPage + 1);
      if (!hasMore) {
        console.log("No more data to load.");
      }
    }
  };

  useEffect(() => {
    if (managerId) {
      fetchAllManagers();
    }
  }, [managerId, page]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  const formatText = (t) => {
    if (typeof t !== "string") {
      return t;
    }

    if (t === "superadmin") {
      return "Super Admin";
    }

    const baseName = t.replace(/manager$/, "");

    const capitalizedBaseName = baseName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return capitalizedBaseName.trim();
  };

  console.log("MANAGER DATA ", managersData);

  const activeManagers = managersData.filter(
    (m) => m.status === "active"
  ).length;
  const disabledManagers = managersData.filter(
    (m) => m.status === "disable"
  ).length;

  const mangerStatusData = [
    { statusText: "Active", statusItemCount: activeManagers },
    { statusText: "Disabled", statusItemCount: disabledManagers },
  ];

  return (
    <>
      <div
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
        className={styles.mgx}
      >
        <div className={styles.mgx_top}>
          <div className={styles.mgx_top_left}>
            <div
              className={styles.mgx_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                className={styles.mgx_top_left_go_back_text}
                style={{
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.mgx_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              {location.pathname.slice(1, location.pathname.length)}
            </p>
          </div>

          <div className={styles.mgx_top_right}>
            <DropDown label={"District"} />
            <DropDown label={"State"} />
            <DropDown label={"City"} />
            <DropDown label={"Warehouse"} />
            <DropDown label={"Sort By"} />
            <div className={styles.mgx_add_manager_btn}>
              <Button
                buttonIcon={<TestTopAddDealerIcon />}
                buttonText={"Add Manager"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/newmanager");
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.mgx_mob_filters}>
          <div className={styles.mgx_mob_filters_top}>
            <div style={{ width: "40%" }}>
              <SearchBar />
            </div>
            <ManagerWareHouseIcon />
            <ManagerSortByIcon />
            <div>
              <Button
                buttonIcon={<TestTopAddDealerIcon />}
                buttonText={"Add Manager"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/newmanager");
                }}
              />
            </div>
          </div>
          <div className={styles.mgx_mob_filters_top}>
            <DropDown label={"State"} />
            <DropDown label={"District"} />
            <DropDown label={"City"} />
          </div>
        </div>

        <div
          className={styles.mgx_middle}
          style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
        >
          <div style={{ width: "440px", display: "flex", gap: "10px" }}>
            {mangerStatusData.map((status, index) => (
              <DealerStatusCard
                key={index}
                manager
                statusText={status.statusText}
                statusItemCount={status.statusItemCount}
              />
            ))}
            <>
              <div
                style={{
                  height: "50%",
                  margin: "auto 0",
                }}
              ></div>
            </>
          </div>
        </div>

        <div
          className={styles.mgx_bottom}
          style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
          ref={containerRef}
        >
          {loading && (
            <div className={styles.mgx_loader_container}>
              <Spinner
                borderWidth={"5px"}
                color={mode ? "#000000" : "#4164E3"}
              />
            </div>
          )}
          {managersData &&
            managersData.map((item, index) => (
              <div
                className={styles.mgx_manager_card}
                key={index}
                onClick={() => {
                  navigate("/manager?manager_Id=" + item._id);
                }}
              >
                <DealerInfoCard
                  key={index}
                  dealerName={item.name}
                  dealerStatus={item.status}
                  dealerDesignation={item.designation
                    .map((d) => formatText(d))
                    .join(", ")}
                  type={"manager"}
                />
              </div>
            ))}
        </div>

        <div className={styles.mgx_pagination}>
          <div style={{ width: "50%" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <GoToPage
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"1"}
              />
            </div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Pagenation
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"1"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Managers;

// import React, { useEffect, useState } from "react";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import AddDealerModal from "../../components/AddDealerModal";
// import { GreenOutButton } from "../../components/Buttons";
// import { useAuth } from "../../components/context/Auth";
// import {
//   AddDealerIcon,
//   Ban,
//   Call,
//   Checked,
//   Messanger,
//   Search,
//   WhatsApp,
// } from "../../components/icons/Icon";
// import Pagination from "../../components/Paginantion";

// // import { AddDealerModal } from '../ordermanager/SaleOrders'
// import styles from "../kyc/Dealer.module.css";

// const Managers = () => {
//   const navigate = useNavigate();
//   const [searchparams] = useSearchParams();
//   const page = searchparams.get("page")
//     ? parseInt(searchparams.get("page"))
//     : 1;

//   const [showAddDealer, setShowAddDealer] = useState(false);
//   const [dealerData, setDealerData] = useState(false);
//   const { managerId } = useAuth();
//   const [managersData, setManagersData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [width, setWidth] = useState(window.innerWidth);
//   useEffect(() => {
//     window.addEventListener("resize", () => {
//       return setWidth(window.innerWidth);
//     });
//     window.removeEventListener("resize", () => {
//       return setWidth(window.innerWidth);
//     });
//   }, []);
//   const [searchDealerData, setSearchDealerData] = useState({
//     searchDealerName: "",
//     sortByStatus: "",
//   });
//   const [state, setState] = React.useState({
//     totalPages: "",
//     currentPage: 1,
//   });

//   const { totalPages } = state;

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         keyword: searchDealerData.searchDealerName,
//         sortKey: searchDealerData.sortByStatus,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_URL +
//           `managers/allManagersList?page=${page}&limit=${
//             width > 1600 ? "18" : "15"
//           }`,
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             if (result.managers) {
//               setLoading(false);
//               setManagersData(result.managers);
//             }
//             if (result.pages) {
//               setState((prevState) => ({
//                 ...prevState,
//                 totalPages: result.pages ? result.pages : "",
//               }));
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [
//     managerId,
//     searchDealerData.sortByStatus,
//     searchDealerData.searchDealerName,
//     page,
//     width,
//   ]);

//   useEffect(() => {
//     if (dealerData.dealerId) {
//       navigate("/dealers/" + dealerData.dealerId);
//     }
//   }, [dealerData.dealerId, navigate]);

//   return (
//     <React.Fragment>
//       <AddDealerModal
//         show={showAddDealer}
//         close={setShowAddDealer}
//         setData={setDealerData}
//       />

//       <div className={styles.right_main}>
//         <div className={styles.addDealers}>
//           <h1 className={styles.dealers}>Managers</h1>
//           <GreenOutButton
//             btnType="button"
//             title={
//               <>
//                 <AddDealerIcon />
//                 &nbsp;&nbsp;Add Manager
//               </>
//             }
//             css={styles.addDealerButton}
//             handleSubmit={() => {
//               navigate("/manager");
//             }}
//             //   handleSubmit={() => { setShowAddDealer(!showAddDealer) }}
//           />
//         </div>
//         {loading ? (
//           <div className={"loadingMain"}>
//             <img src="/wallicon.gif" alt="walliconGIF" />
//           </div>
//         ) : (
//           <div className={styles.Dealer_main_container}>
//             <div className={styles.dealer_header}>
//               <div className={styles.dealer_search_div}>
//                 <input
//                   type="search"
//                   placeholder="Search"
//                   className={styles.dealer_search}
//                   value={searchDealerData.searchDealerName}
//                   onChange={(e) =>
//                     setSearchDealerData({
//                       ...searchDealerData,
//                       searchDealerName: e.target.value,
//                     })
//                   }
//                 />
//                 <Search color="#ffffff" css={styles.dealer_search_icon} />
//               </div>
//               <div className={styles.dealer_header_right}>
//                 <div className="">
//                   <label className={styles.dealer_sortBy_txt}>Sort By </label>
//                   <select
//                     className={styles.dealer_sortBy_select}
//                     value={searchDealerData.sortByStatus}
//                     onChange={(e) =>
//                       setSearchDealerData({
//                         ...searchDealerData,
//                         sortByStatus: e.target.value,
//                       })
//                     }
//                   >
//                     <option className={styles.dealer_sortBy_color} value="">
//                       View All
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Referral"
//                     >
//                       Referral
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Widthout OTP"
//                     >
//                       Widthout OTP
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Managers Created"
//                     >
//                       Managers Created
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Self Created"
//                     >
//                       Self Created
//                     </option>
//                     <option className={styles.dealer_sortBy_color} value="New">
//                       New
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="newestfirst"
//                     >
//                       Newest First
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Pending"
//                     >
//                       Not Verified
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Verified"
//                     >
//                       Verified
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Ascending"
//                     >
//                       Ascending
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Descending"
//                     >
//                       Descending
//                     </option>
//                   </select>
//                 </div>
//                 {/* <div className={styles.dealer_header_select_days}>
//                             <div className={`${styles.dealer_days_select} ${styles.days_active}`}>All</div>
//                             <div className={styles.dealer_days_select}>15 Days</div>
//                             <div className={styles.dealer_days_select}>15-30 Days</div>
//                             <div className={styles.dealer_days_select}>30 Days</div>
//                          </div> */}
//               </div>
//             </div>
//             <div className={styles.dealer_main_div}>
//               {managersData &&
//                 managersData.map((item, index) => (
//                   <div className={styles.dealer_card} key={index}>
//                     <div className={styles.dealer_card_div}>
//                       <div
//                         onClick={() => {
//                           navigate("/manager?manager_Id=" + item._id);
//                         }}
//                         className={styles.cursor_pointer}
//                       >
//                         <div className={styles.dealer_status}>
//                           {item.status === "active" ? (
//                             <Checked
//                               color="#1FFC33"
//                               css={styles.dealer_status_icon}
//                             />
//                           ) : (
//                             <Ban
//                               color="#F93E3E"
//                               css={styles.dealer_status_icon}
//                             />
//                           )}
//                         </div>

//                         {!item.profileImage ? (
//                           <div className={styles.name_char}>
//                             <span className={styles.name_char_mb}>
//                               {item && item.name.charAt(0).toUpperCase()}
//                             </span>
//                           </div>
//                         ) : (
//                           <img
//                             src={
//                               process.env.REACT_APP_S3URL + item.profileImage
//                             }
//                             className={styles.dealer_image}
//                             alt="dealer_image"
//                           />
//                         )}

//                         <h4 className={styles.managerName}>{item.name}</h4>
//                         <div className={styles.dealeStore_owner}>
//                           {item.designation}
//                         </div>
//                       </div>
//                       <div className={styles.dealer_btn_div}>
//                         <button className={styles.dealer_Btn}>
//                           <Messanger color="#ffffff" width={16} />
//                         </button>
//                         <a
//                           href={"tel:+91" + item.mobile}
//                           className={styles.dealer_Btn}
//                         >
//                           <Call color="#ffffff" width={16} />
//                         </a>
//                         <a
//                           target="_blank"
//                           rel="noreferrer"
//                           href={"https://wa.me/+91" + item.whatsapp}
//                           className={styles.dealer_Btn}
//                         >
//                           <WhatsApp width={16} />
//                         </a>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//             </div>
//             <Pagination total={totalPages} current={page} />
//           </div>
//         )}
//       </div>
//     </React.Fragment>
//   );
// };

// export default Managers;
