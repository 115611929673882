import styles from "./Wrapper.module.css";

const ModalWrapper = ({ children, isOpen, onClose }) => {
  return (
    <div
      className={`${styles.modalOverlay} ${
        isOpen ? styles.open : styles.close
      }`}
      onClick={onClose}
    >
      <div
        className={`${styles.modalContent}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
