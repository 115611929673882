import React, { useCallback, useEffect, useRef, useState } from "react"
import AddDealerModal from "../../components/AddDealerModal"
// import { useAuth } from "../../components/context/Auth"
import styles from './Order.module.css'
const DealerSearch = ({  name, onChange, onSelect, setGeneralInfo, }) => {
    // const { switchDisplay } = useAuth()
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const resultContainer = useRef(null)
    const [showResults, setShowResults] = useState(false)
    const [defaultValue, setDefaultValue] = useState("")
    const [results, setResults] = useState([])
    const [showAddDealer, setShowAddDealer] = useState(false)


    useEffect(() => {
        if (defaultValue) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": 1,
                "keyword": defaultValue
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/finddealer", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setResults(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [defaultValue])




    const handleSelection = selectedIndex => {
        const selectedItem = results[selectedIndex]

        if (!selectedItem) return resetSearchComplete()
        setDefaultValue(selectedItem.name)
        onSelect && onSelect(selectedItem)
        resetSearchComplete()
    }

    const resetSearchComplete = useCallback(() => {
        setFocusedIndex(-1)
        setShowResults(false)
    }, [])

    const handleKeyDown = e => {
        const { key, ctrlKey, keyCode, altKey } = e
        let nextIndexCount = 0

        // move down
        if (key === "ArrowDown")
            nextIndexCount = (focusedIndex + 1) % results.length

        // move up
        if (key === "ArrowUp")
            nextIndexCount = (focusedIndex + results.length - 1) % results.length

        // hide search results
        if (key === "Escape") {
            resetSearchComplete()
        }
        if (altKey && keyCode === 67) {
            setShowAddDealer(true);
        }

        // select the current item
        if (key === "Enter") {
            e.preventDefault()
            handleSelection(focusedIndex)
            setShowResults(false)
        }
        if (ctrlKey && keyCode === 32) {
            setShowResults(true)
        }

        setFocusedIndex(nextIndexCount)
    }

    const handleChange = e => {
        setDefaultValue(e.target.value)
        onChange && onChange(e)
        setGeneralInfo(prevState => ({
            ...prevState,
            name: e.target.value
        }))
        setShowResults(true)
    }

    useEffect(() => {
        if (!resultContainer.current) return

        resultContainer.current.scrollIntoView({
            block: "center"
        })
    }, [focusedIndex])

    // useEffect(() => {
    //     // if (results.length > 0 && !showResults) setShowResults(true)

    //     if (results.length <= 0) setShowResults(false)
    // }, [results])

    useEffect(() => {
        if (name) setDefaultValue(name)
    }, [name])

    // console.log(focusedIndex)
    return (
        <React.Fragment>

            <AddDealerModal
                show={showAddDealer}
                close={setShowAddDealer}
                setData={setGeneralInfo}
            />
            <div
                tabIndex={1}
                onBlur={() => {
                    // if (onSelect && focusedIndex) {
                    //     qtyFocus.current.focus();
                    // }

                    resetSearchComplete()
                }}
                onKeyDown={handleKeyDown}
                className={styles.inputDivDealer}
            >
                <input
                    value={defaultValue}
                    onChange={handleChange}
                    type="text"
                    className={styles.dealerInputField}
                    placeholder="Company/Dealer Name/DealerId..."
                    onClick={() => setShowResults(true)}
                    autoFocus={'new user'}
                    required
                />

                {/* Search Results Container */}

                {showResults && (
                    <div className={styles.suggetion}>
                        <div className={styles.dealerCreateNew}>
                            <div onClick={() => { setShowAddDealer(true); setShowResults(false) }}>Create New Dealer</div>
                            <div>Alt + C</div>
                        </div>

                        {results && results.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    onMouseDown={() => { handleSelection(index); setShowResults(false) }}
                                    ref={index === focusedIndex ? resultContainer : null}
                                    className={`${index === focusedIndex ? styles.foucsList : ""} ${styles.suggetionList}`}
                                >
                                    {item.storeName + " / " + item.name}

                                    {/* {renderItem(item)}  */}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default DealerSearch