import React from 'react';
import styles from './FromComponent.module.css';
import { useAuth } from '../../context/Auth';

const InputTypeChack = ({ lable ,onChange , checked}) => {
    // const handechange = (e)=>{
    //     const isChecked = e.target.checked;
    //     console.log(isChecked);

    // }
    const { mode } = useAuth()
    return (
        <div className={styles.checkboxWrapper}>
            <label>
                <input
                    type="checkbox"
                    style={mode ? { backgroundColor: "#1b1d21" }
                        : { backgroundColor: "#fff" }}
                        onChange={onChange}
                        checked ={checked}
                />
                <span className={styles.checkbox}> {lable}</span>
            </label>
        </div>
    );
};

export default InputTypeChack;
