import React, { useEffect, useState } from 'react'
import styles from './Templates.module.css'
import { DocumentIcon, GotoWebsiteIcon, ImageIcon, LiveLocationIcon, Phone, QuickReplyIcon, VideoIcon } from '../../components/icons/Icon';
import { useAuth } from '../../components/context/Auth';
// import { useSearchParams } from 'react-router-dom';
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar';
import InputTag from '../../components/updatedComponents/FormComponents/InputTag';
import Button from '../../components/updatedComponents/Dealers/Button';
import { TemplateDone } from '../../components/icons/Icon2';
import InputRedio from '../../components/updatedComponents/FormComponents/InputRedio';
import SelectBox from "../../components/updatedComponents/FormComponents/SelectBox"
import InputTypeMedia from '../../components/updatedComponents/FormComponents/InputTypeMedia';
import Editer from '../../components/updatedComponents/FormComponents/Editer';
import InputTypeChack from '../../components/updatedComponents/FormComponents/InputTypeChack';
import AddBtnContner from '../../components/updatedComponents/Templet/AddBtnContner';
// import { handleCreateTemplate1 } from '../../APIsControll/TempletApiColl/AddTempletall';
import { useToast } from '../../components/updatedComponents/Toaster/Toaster';
import { convertHtmlToMarkdown, transformButtons } from '../../Utils/TextUtils';
import fetchDataParem from '../../APIsControll/paramApiControll';
const CreateTemplate = () => {
    const { managerId, mode } = useAuth();
    const { show } = useToast();
    const [selctHeaderType, setSelctHeaderType] = useState("");
    const [TempLoading, settemploading] = useState(false);

    const ensureProtocol = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return 'https://' + url;
        }
        return url;
    }



    const [typeOfMedia, setTypeOfMedia] = useState("");
    const [Fileselect, setFileselect] = useState(null)
    const [selectedValue, setSelectedValue] = useState('');
    const [footerChack, setFooterChack] = useState("");
    const [footerBtton, setFooterBtton] = useState("");
    const [buttons1, setButtons1] = useState([]);
    const [editorContent, setEditorContent] = useState("");
    const [text1, setText1] = useState("");
    const [footerText1, setFooterText1] = useState("");
    const [tampletName, setTempletName] = useState("");
    const [slectlanguage, setSlectLanguage] = useState("");
    const [DocumentNa, setDocumentNa] = useState("");
    const [err, setErrors] = useState({})
    const handleChangee = (value) => {
        setSelectedValue(value);
    };
    // console.log("typeOfMedia =>", typeOfMedia);
    // console.log("selctHeaderType =>", selctHeaderType);
    // console.log("editorContent =>", editorContent);

    // const formattedText = editorContent && editorContent
    // .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    // .replace(/_(.*?)_/g, '<em>$1</em>')
    // .replace(/\n/g, '<br />');

    const formattedText = editorContent && editorContent
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // For bold
        .replace(/\*(.*?)\*/g, '<strong>$1</strong>') // For single asterisk bold
        .replace(/_(.*?)_/g, '<em>$1</em>') // For italics
        .replace(/\n/g, '<br />'); // For line breaks

    console.log("formattedText =>", formattedText);


    const hendelTempletCration = async (event) => {
        event.preventDefault();
        if (editorContent.trim().length === 0) {
            show(' Body is required!', 'error');
            return;
        }
        const dataObj = {
            tampletName: tampletName,
            footerText: footerText1,
            selectedValue: selectedValue,
            typeText: text1,
            slectlanguage: slectlanguage,
            body: editorContent,
            button: buttons1,
            documentName: DocumentNa,
            managerId: managerId
        }
        const res = await handleCreateTemplate1(Fileselect, selctHeaderType, typeOfMedia, dataObj);
        if (res) {
            show(res)
        }
    }

    const handelcoll = (DocumentUri, dataObj, typeOfMedia, File, selctHeaderType) => {
        try {
            settemploading(true)
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + process.env.TEMPLATE_CREATED_BEARER);
            const raw = JSON.stringify({
                "name": dataObj?.tampletName.replace(/ /g, '_').toLowerCase(),
                "category": dataObj?.selectedValue === "Marketing" ? "MARKETING" :
                    dataObj?.selectedValue === "Utility" ? "UTILITY" : null,
                "language": dataObj?.slectlanguage,
                "header": (() => {
                    if (selctHeaderType === "Media") {
                        return {
                            "format": typeOfMedia === "Image" ? "IMAGE" :
                                typeOfMedia === "Video" ? "VIDEO" :
                                    typeOfMedia === "Document" ? "DOCUMENT" : null,
                            "content": DocumentUri || null,
                        };
                    } else if (selctHeaderType === "Text") {
                        return {
                            "format": "TEXT",
                            "content": dataObj?.typeText || null,

                        };
                    }
                    return null;
                })(),
                // ...(dataObj?.body && { "bodyText": convertHtmlToMarkdown(dataObj.body) }),
                ...(dataObj?.body && { "bodyText": dataObj?.body }),
                ...(dataObj?.footerText && { "footerText": dataObj.footerText }),
                ...(dataObj?.button && { "buttons": transformButtons(dataObj?.button) }),
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(process.env.REACT_APP_URL + "wallikonChat/templateCreated", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === 'APPROVED' || result.status === 'PENDING') {
                        show(`${result.status}`, "success")
                        const response = handleAddTemplate(typeOfMedia, dataObj, File, selctHeaderType);
                        return response;
                    }
                    else if (result.status === 'REJECTED') {
                        show(`${result.status}`, "error");
                        return result
                    }

                    if (result.status === "failed") {
                        show(`${result.status}`, "error");
                        settemploading(false)
                        return result
                    }
                    // return alert(result.status)
                })
                .catch((error) => console.error(error));


        } catch (error) {
            return error;
        }

    }
    const handleAddTemplate = (typeOfMedia, dataObj, File, selctHeaderType) => {
        settemploading(true)
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        const formdata = new FormData();
        formdata.append("managerId", dataObj?.managerId);
        formdata.append("templateName", dataObj?.tampletName.replace(/ /g, '_').toLowerCase())
        formdata.append("category", dataObj?.selectedValue === "Marketing" ? "MARKETING" : dataObj?.selectedValue === "Utility" ? "UTILITY" : null,);
        formdata.append("language", dataObj?.slectlanguage);
        console.log("handleAddTemplate  selctHeaderType =>", selctHeaderType);
        console.log("handleAddTemplate  typeOfMedia =>", typeOfMedia);

        if (selctHeaderType === "Media") {
            formdata.append("headerType",
                typeOfMedia === "Image" ? "image" :
                    typeOfMedia === "Video" ? "video" :
                        typeOfMedia === "Document" ? "document" :
                            typeOfMedia === "Location" ? "location" :
                                null
            );
        } else if (selctHeaderType === "Text") {
            formdata.append("headerType", "text");
            formdata.append("headerText", dataObj?.typeText);

        }
        // if ((typeOfMedia === "Image" || typeOfMedia === "Video" || typeOfMedia === "Document") && dataObj?.tampletName) { formdata.append("documentName", typeOfMedia === 'Document' ? dataObj?.tampletName.replace(/ /g, '_').toLowerCase() : ''); }
        if (selctHeaderType === "Media" && typeOfMedia === "Document" && dataObj?.tampletName) { formdata.append("documentName", dataObj?.documentName); }
        // formdata.append("body", convertHtmlToMarkdown(dataObj.body));
        formdata.append("body", dataObj?.body);
        if (dataObj.footerText) { formdata.append("footer", dataObj.footerText) }
        if (dataObj.button.length > 0) { formdata.append("buttons", JSON.stringify(transformButtons(dataObj.button))) }
        if (File && (typeOfMedia === "Image" || typeOfMedia === "Video" || typeOfMedia === "Document") && selctHeaderType === "Media") { formdata.append('file', File) }

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/addTemplate", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.status === "success") {
                    show(`${result.msg}`, "success")
                    return result
                }
                if (result?.status === "failed") {
                    show(`${result.msg}`, "error");
                    return result
                } else {
                    show(`error`, "error")
                }
            })
            .catch((error) => console.error(error)).finally(() => settemploading(false));

    }

    const handleCreateTemplate1 = async (File, selctHeaderType, typeOfMedia, dataObj) => {
        settemploading(true)
        if (selctHeaderType === "Media") {
            if (typeOfMedia === "Video" || typeOfMedia === "Image" || typeOfMedia === "Document") {
                try {
                    const formdata = new FormData();
                    formdata.append("file", File);
                    const res = await fetchDataParem("wallikonChat/templateCreation",
                        `fileLength=${File?.size}&fileType=${File?.type}&fileName=${File?.name}`,
                        formdata);

                    const response = handelcoll(res.data.h, dataObj, typeOfMedia, File, selctHeaderType);
                    if (response.status === "failed") {
                        show("Change Template Name ", "error");
                        settemploading(false)
                        return
                    }
                    // return response
                } catch (error) {
                    if (error.status === "failed") {
                        show("Change Template Name ", "error");
                        settemploading(false)
                    }
                }
            }
            else if (typeOfMedia === "Location") {
                try {
                    const myHeaders = new Headers();
                    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", "Bearer " + process.env.TEMPLATE_CREATED_BEARER);
                    const raw = JSON.stringify({
                        "name": dataObj?.tampletName.replace(/ /g, '_').toLowerCase(),
                        "category": dataObj?.selectedValue === "Marketing" ? "MARKETING" : dataObj?.selectedValue === "Utility" ? "UTILITY" : null,
                        "language": dataObj?.slectlanguage,
                        "header": {
                            "format": "LOCATION",
                        },
                        // ...(dataObj?.body && { "bodyText": convertHtmlToMarkdown(dataObj.body) }),
                        ...(dataObj?.body && { "bodyText": dataObj?.body }),
                        ...(dataObj?.footerText && { "footerText": dataObj?.footerText }),
                        ...(dataObj?.button && { "buttons": transformButtons(dataObj?.button) }),
                    });



                    const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow"
                    };

                    fetch(process.env.REACT_APP_URL + "wallikonChat/templateCreated", requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status === 'APPROVED' || result.status === 'PENDING') {
                                show(`${result.status}`, "success")
                                handleAddTemplate(typeOfMedia, dataObj, File, selctHeaderType);
                                return
                            }
                            else if (result.status === 'REJECTED') {
                                show(`${result.status}`, "error");
                                settemploading(false)
                                return result
                            }

                            if (result.status === "failed") {
                                show(`${result.status}`, "error");
                                settemploading(false)
                                return result
                            }
                            // return alert(result.status)
                        })
                        .catch((error) => console.error(error));


                } catch (error) {
                    console.log(error);
                    return error;
                }

            }
        }
        if (selctHeaderType === "Text") {
            return handelcoll(null, dataObj, typeOfMedia, File, selctHeaderType)
        }

    };


    const Chackvalidation = () => {
        let chack = /^[a-zA-Z0-9]+$/.test(tampletName);
        setErrors({ ...err, chack })

    }
    useEffect(() => {
        Chackvalidation()
    }, [])
    console.log("errors", err);

    // useEffect(() => {
    //     const res = convertHtmlToMarkdown(editorContent)
    //     console.log("------------------>", res);
    // }, [editorContent])



    return (
        <React.Fragment>
            <div className={styles.main_continer}>
                <div className={styles.secand_main_continer}
                    style={mode ? { backgroundColor: "#2C2E33", border: "1px solid #2C2E33" } :
                        { backgroundColor: "#F8F9FC", border: "1px solid #F8F9FC" }}>
                    <form onSubmit={hendelTempletCration}>
                        <div className={styles.Templet_container} >
                            <div className={styles.go_Back}>
                                <TopTabBar text={"Campaigns / Create Template"} />
                            </div>
                            <div className={styles.templateName_continer} >
                                <div className={styles.template_input} >
                                    <div className={styles.inputTageDiv} >
                                        <InputTag
                                            name={"Template Name"}
                                            maxLength={50}
                                            placeholderType={"text"}
                                            placeholderText={"Template Name"}
                                            onChange={(e) => setTempletName(e.target.value)}
                                            required={true}
                                        />
                                        {/* <span>Err</span> */}
                                    </div>
                                </div>
                                <div className={styles.template_btn_Continer}>
                                    <div style={{ width: "100px" }}>
                                        <Button
                                            buttonBgColor={"#4164E3"}
                                            buttonText={"Done"}
                                            buttonTextColor={"#ffff"}
                                            buttonIcon={<TemplateDone />}
                                            loading={TempLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.Templet_container1} /* style={mode ? {backgroundColor:"#232529"}:{backgroundColor:"#ffff"}} */ >
                            <div className={styles.form_continer} >
                                <div className={styles.Marketing_continer} style={mode ? { border: "1px solid #232529", backgroundColor: "#232529" } : { border: "1px solid #E4E7EB", backgroundColor: "#ffffff" }} >
                                    <div className={styles.Marketing_continer_headr}
                                        style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff" } :
                                            { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000" }} >
                                        <span>Marketing</span>
                                        <span className={styles.changeColor}>Change</span>
                                    </div>
                                    <div className={styles.Marketing_continer_body} >
                                        <div>
                                            <InputRedio
                                                id="Marketing"
                                                lableText="Marketing"
                                                name="hello"
                                                value="Marketing"
                                                checked={selectedValue === "Marketing"}
                                                onChange={() => handleChangee("Marketing")}
                                                required={true}

                                            />
                                            <div className={styles.Marketing_Text} style={mode ? { color: "#fafafa" } : { color: "#000" }}>
                                                <span >Promotions or information
                                                    about your business, products
                                                    or services. Or any message
                                                    that isn't utility or authentication.</span>
                                            </div>

                                        </div>
                                        <div style={{ marginTop: "20px" }}>
                                            <InputRedio
                                                id="Utility"
                                                lableText="Utility"
                                                name="hello"
                                                value="Utility"
                                                checked={selectedValue === "Utility"}
                                                onChange={() => handleChangee("Utility")}
                                                required={true}
                                            />
                                            <div className={styles.Marketing_Text} style={mode ? { color: "#fafafa" } : { color: "#000" }}>
                                                <span >Messages about a specific transaction. account, order or customer request.</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{ marginTop: "20px" }}>
                                                <InputRedio
                                                    id="Authentication"
                                                    lableText="Authentication"
                                                    name="hello"
                                                    value="Authentication"
                                                    disabled={true}
                                                    required={true}
                                                />
                                            </div>

                                        </div>
                                        <hr style={mode ? { borderColor: "white" } : { borderColor: "black" }} />
                                        <div className={styles.select_Lng}>
                                            <span style={mode ? { color: "#ffff" } : { color: "#000" }}>Language</span>
                                            <div className={styles.select_box_tag}>
                                                <select name="" id="" className={styles.select_box}
                                                    style={mode ? { backgroundColor: "#1b1d21", border: "1px solid #232529", color: "#fff" } :
                                                        { background: "#f8f9fc", border: "1px solid #232529", color: "#000" }}
                                                    onChange={(e) => setSlectLanguage(e.target.value)}
                                                    required={true}
                                                >
                                                    <option value='' hidden >Select</option>
                                                    <option value='en'>English</option>
                                                    <option value='en_US'>English (US)</option>
                                                    <option value='en_UK'>English (UK)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ height: "200px" }}>

                                        </div>
                                    </div>
                                </div>
                                <div className={styles.Header_continer} style={mode ? { border: "1px solid #232529", backgroundColor: "#232529", borderRadius: "8px" } : { border: "1px solid #E4E7EB", backgroundColor: "#ffffff", borderRadius: "8px" }} >
                                    <div className={styles.Marketing_continer_headr}
                                        style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff" } :
                                            { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000" }} >
                                        <span> <InputTypeChack lable={"Header"} />   </span>
                                        <span className={styles.changeColor}>Change</span>
                                    </div>
                                    <div className={styles.create_Tamplet_Continer} >
                                        <div className={styles.create_tem_selectBox} style={{ marginTop: "15px" }} >
                                            <SelectBox
                                                dropDownText={"Select"}
                                                label={"Type"}
                                                onSelect={(e) => setSelctHeaderType(e.label)}
                                                option={[{ label: "Text" }, { label: "Media" }]}
                                                star={true}
                                                value={selctHeaderType}

                                            />

                                        </div>
                                        <div>

                                            {
                                                selctHeaderType === "" ? null :
                                                    selctHeaderType === "Media" ?
                                                        <div className={styles.create_tem_TypeOfTamp}>
                                                            <div className={styles.create_tem_TypeOfTamp1} >
                                                                <InputRedio
                                                                    id="Image"
                                                                    lableText="Image"
                                                                    name="media"
                                                                    value="Image"
                                                                    checked={typeOfMedia === "Image"}
                                                                    onChange={() => setTypeOfMedia("Image")}
                                                                    required={true}
                                                                />
                                                                <InputRedio
                                                                    id="Video"
                                                                    lableText="Video"
                                                                    name="media"
                                                                    value="Video"
                                                                    checked={typeOfMedia === "Video"}
                                                                    onChange={() => setTypeOfMedia("Video")}
                                                                    required={true}
                                                                />
                                                                <InputRedio
                                                                    id="Document"
                                                                    lableText="Document"
                                                                    name="media"
                                                                    value="Document"
                                                                    checked={typeOfMedia === "Document"}
                                                                    onChange={() => setTypeOfMedia("Document")}
                                                                    required={true}
                                                                />
                                                                <InputRedio
                                                                    id="Location"
                                                                    lableText="Location"
                                                                    name="media"
                                                                    value="Location"
                                                                    checked={typeOfMedia === "Location"}
                                                                    onChange={() => setTypeOfMedia("Location")}
                                                                    required={true}
                                                                />
                                                            </div>
                                                            <div className={styles.create_tem_Text} >
                                                                <span className={styles.create_tem_Text_Span1} style={mode ? { color: "#f0f0f0" } : { color: "#000" }} >Add Simple Media File</span>
                                                                <span className={styles.create_tem_Text_Span2} style={mode ? { color: "#f0f0f0" } : { color: "#000" }}  >Provide examples of the media in the header to help Meta review your templates. Do not include any customer information.</span>
                                                            </div>
                                                            {
                                                                typeOfMedia === "Location" ? null :
                                                                    <div className={styles.create_tem_MediaFile}>
                                                                        <div style={{ width: "150px" }}>
                                                                            <InputTypeMedia
                                                                                name="mediaFile"
                                                                                id="mediaFile"
                                                                                required={true}
                                                                                accept={
                                                                                    typeOfMedia === "Image" ? "image/jpeg, image/png, image/jpg" :
                                                                                        typeOfMedia === "Video" ? "video/mp4" :
                                                                                            typeOfMedia === "Document" ? "application/pdf" : ""
                                                                                }
                                                                                onChange={(e) => setFileselect(e.target.files[0])}
                                                                            />
                                                                        </div>
                                                                        <div className={styles.create_tem_MediaFile_text}>
                                                                            <span style={mode ? { color: "#B2B5CA" } : { color: "#B2B5CA" }} >Max image size - 5 MB</span>
                                                                            <span style={mode ? { color: "#f0f0f0" } : { color: "#B2B5CA" }} >Supported file formats :
                                                                                {
                                                                                    typeOfMedia === "Image" ? " .png, .jpeg, .jpg" :
                                                                                        typeOfMedia === "Video" ? " .mp4" :
                                                                                            typeOfMedia === "Document" ? " .pdf" : ""
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        {
                                                                            typeOfMedia === "Document" ?
                                                                                <div style={{ width: "90%", marginTop: "10px" }}>
                                                                                    <InputTag
                                                                                        name={"Document Name"}
                                                                                        labelText={"Document Name"}
                                                                                        placeholderText={"Document Name"}
                                                                                        placeholderType={"text"}
                                                                                        maxLength={150}
                                                                                        required={true}
                                                                                        star={true}
                                                                                        onChange={(e) => setDocumentNa(e.target.value)}
                                                                                    />
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                            }


                                                        </div> : <>
                                                            <div className={styles.create_tem_MediaText_Con}>
                                                                <InputTag
                                                                    placeholderText={"Enter Your Text"}
                                                                    placeholderType={"text"}
                                                                    maxLength={60}
                                                                    required={true}
                                                                    star={true}
                                                                    labelText={"Text"}
                                                                    onChange={(e) => setText1(e.target.value)}
                                                                    value={text1}
                                                                />
                                                            </div>
                                                        </>
                                            }
                                        </div>
                                        <div className={styles.Marketing_continer_headr}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff", marginTop: "15px" } :
                                                { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000", marginTop: "15px" }} >
                                            <span> <InputTypeChack lable={"Limited Time Offer"} />   </span>
                                            {/* <span className={styles.changeColor}>Change</span> */}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                            <Editer bodyText={editorContent} setBodyText={setEditorContent} />
                                        </div>
                                        <div className={styles.Marketing_continer_headr}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff", marginTop: "15px" } :
                                                { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000", marginTop: "15px" }} >
                                            <span> <InputTypeChack onChange={(e) => setFooterChack(e.target.checked)} lable={"Footer"} />   </span>
                                            {/* <span className={styles.changeColor}>Change</span> */}
                                        </div>
                                        {footerChack && (
                                            <div className={styles.create_temp_footer_input}>
                                                <div className={styles.create_temp_footer_input1}>
                                                    <InputTag
                                                        labelText={"Footer Text"}
                                                        maxLength={100}
                                                        star={true}
                                                        required={true}
                                                        placeholderText={"Enter footer text"}
                                                        placeholderType={"text"}
                                                        onChange={(e) => setFooterText1(e.target.value)}
                                                        value={footerText1}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className={styles.Marketing_continer_headr}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21", color: "#ffff", marginTop: "15px" } :
                                                { backgroundColor: "#E4E7EB", border: "1px solid #E4E7EB", color: "#000", marginTop: "15px" }} >
                                            <span> <InputTypeChack onChange={(e) => setFooterBtton(e.target.checked)} lable={"Button"} />   </span>
                                            <span style={mode ? { color: "#fff" } : { color: "#000" }}>Call To Action</span>
                                        </div>
                                        {footerBtton && <AddBtnContner buttons={buttons1} setButtons={setButtons1} key={"2"} />}
                                    </div>
                                </div>
                                <div className={styles.Preview_continer} /* style={mode ? { backgroundColor: "#232529" }
                                            : { backgroundColor: "#E4E7EB" }} */>
                                    <div className={styles.templateRight}>
                                        <div>

                                            <p className={styles.Preview_Text} style={mode ? { backgroundColor: "#1B1D21", color: "#fff" }
                                                : { backgroundColor: "#E4E7EB", color: "#000" }} > Preview</p>
                                        </div>
                                        <div className={styles.Preview_continer_main}
                                            style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #1B1D21" } :
                                                { backgroundColor: "#fff", border: "1px solid #E4E7EB" }} >
                                            <div className={styles.Preview_continer_media}>
                                                {
                                                    selctHeaderType === "Text" ? <div className={styles.con} > <span style={mode ? { color: "#fff" }
                                                        : { color: "#000" }} >{text1}</span>  </div> :
                                                        selctHeaderType === "Media" ?

                                                            <div className={styles.Preview_continer_media1} style={mode ? { backgroundColor: "#2C2E33" } : { backgroundColor: "#E4E7EB" }}>
                                                                {
                                                                    typeOfMedia === "Image" ? (Fileselect instanceof File ? (<img src={URL.createObjectURL(Fileselect)} alt="" className={styles.image} />) : (<ImageIcon />)) :
                                                                        typeOfMedia === "Video" ? (Fileselect instanceof File ? (<video autoPlay muted controls src={URL.createObjectURL(Fileselect)} className={styles.image} />) : (<VideoIcon />)) :
                                                                            typeOfMedia === "Document" ? (Fileselect instanceof File ? (
                                                                                <iframe onScrollCapture={false} src={URL.createObjectURL(Fileselect)} title="PDF Preview" className={styles.SelectPdf} />) : (<DocumentIcon />)) :
                                                                                typeOfMedia === "Location" ? (<LiveLocationIcon />) : null
                                                                }
                                                            </div> : null}

                                                <div className={styles.Preview_continer_media2}>
                                                    {/* <div dangerouslySetInnerHTML={{ __html: editorContent }} style={mode ? { color: "#fff" } : { color: "#000" }} /> */}
                                                    <div dangerouslySetInnerHTML={{ __html: formattedText }}
                                                        style={{ color: mode ? "#fff" : "#000" }}
                                                    ></div>
                                                    {footerText1 && <div className={styles.con} > <span style={mode ? { fontSize: "12px", color: "#B3B3B3" }
                                                        : { fontSize: "12px", color: "#B3B3B3" }} >{footerText1}</span>  </div>}
                                                </div>
                                                {buttons1?.map((button, index) =>
                                                    <div key={index} className='text-capitalize text-primary text-center border-top mt-2 pt-1'>
                                                        {/* {console.log("button", buttons1)} */}
                                                        <span className='me-2'>{button.value === "PHONE_NUMBER" ? <Phone color='#fff' /> :
                                                            button.value === 'QUICK_REPLY' ? <QuickReplyIcon color='#fff' /> :
                                                                <GotoWebsiteIcon />}</span><a className={styles.websiteLink}
                                                                    href={ensureProtocol(button.url)} target='_blank' rel="noopener noreferrer">
                                                            <b>{button.btnText}</b></a>
                                                    </div>
                                                )}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreateTemplate

// const EmojiPicker = ({ onSelect }) => {
//     const smileys = [
//         "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍",
//         "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩",
//         "🥳", "😏", "😒", "😞", "😔", "😟", "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢",
//         "😭", "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓", "🤗",
//         "🤔", "🤭", "🤫", "🤥", "😶", "😐", "😑", "😬", "🙄", "😯", "😦", "😧", "😮", "😲", "🥱",
//         "😴", "🤤", "😪", "😵", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈",
//         "👿", "👹", "👺", "🤡", "💩", "👻", "💀", "☠️", "👽", "👾", "🤖", "🎃", "😺", "😸", "😹",
//         "😻", "😼", "😽", "🙀", "😿", "😾", "🤲", "👐", "🙌", "👏", "🤝", "👍", "👎", "👊", "✊",
//         "🤛", "🤜", "🤞", "✌️", "🤟", "🤘", "👌", "👈", "👉", "👆", "👇", "☝️", "✋", "🤚", "🖐️",
//         "🖖", "👋", "🤙", "💪", "🦾", "🖕", "✍️", "🙏", "🦶", "🦵", "🦿", "🦷", "🦴", "👀", "👁️‍🗨️",
//         "👅", "👄", "💋", "🩸", "👂", "🦻", "👃", "🫁", "👣", "🦰", "🦱", "🦲", "🦳", "🐵", "🐒",
//         "🦍", "🦧", "🐶", "🐕", "🦮", "🐕‍🦺", "🐩", "🐺", "🦊", "🦝", "🐱", "🐈", "🐈‍⬛", "🦁", "🐯",
//         "🐅", "🐆", "🐴", "🐎", "🦄", "🦓", "🦌", "🦬", "🐮", "🐂", "🐃", "🐄", "🐷", "🐖", "🐗",
//         "🐽", "🐏", "🐑", "🐐", "🐪", "🐫", "🦙", "🦒", "🐘", "🦣", "🦏", "🦛", "🐭", "🐁", "🐀",
//         "🐹", "🐰", "🐇", "🐿️", "🦫", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨", "🦘", "🦡",
//         "🐾", "🦃", "🐔", "🐓", "🐣", "🐤", "🐥", "🐦", "🐧", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦩",
//         "🦚", "🦜", "🐸", "🐊", "🐢", "🦎", "🐍", "🐲", "🐉", "🦕", "🦖", "🐳", "🐋", "🐬", "🦭",
//         "🐟", "🐠", "🐡", "🦈", "🐙", "🐚", "🐌", "🦋", "🐛", "🐜", "🐝", "🪰", "🐞", "🦗", "🪳",
//         "🕷️", "🕸️", "🦂", "🦟", "🪲", "🦠", "💐", "🌸", "💮", "🏵️", "🌹", "🥀", "🌺", "🌻", "🌼",
//         "🌷", "🌱", "🌲", "🌳", "🌴", "🌵", "🌾", "🌿", "☘️", "🍀", "🍁", "🍂", "🍃", "🍄", "🌰",
//         "🦀", "🦞", "🦐", "🦑", "🌍", "🌎", "🌏", "🌐", "🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗",
//         "🌘", "🌙", "🌚", "🌛", "🌜", "☀️", "🌝", "🌞", "🪐", "⭐", "🌟", "🌠", "🌌", "☁️", "⛅",
//         "⛈️", "🌤️", "🌥️", "🌦️", "🌧️", "🌨️", "🌩️", "🌪️", "🌫️", "🌬️", "🌀", "🌈", "🌂", "☂️", "☔",
//         "⚡", "❄️", "☃️", "⛄", "☄️", "🔥", "💧", "🌊"
//     ];

//     const travelAndPlaces = [
//         "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", "🚚", "🚛", "🚜", "🛴", "🚲",
//         "🛵", "🏍️", "🛺", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚋", "🚞", "🚝", "🚄", "🚅",
//         "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🛸", "🛫", "🛬", "🛩️", "💺", "🚁", "🛶", "⛵", "🚤",
//         "🛥️", "🚢", "🗼", "🏰", "🏯", "🏟️", "🎡", "🎢", "🎠", "⛲", "⛺", "🌁", "🏔️", "⛰️", "🌋",
//         "🗻", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🏗️", "🌇", "🌆", "🏙️", "🌃", "🌉", "🌌", "🌠", "🎇",
//         "🎆", "🌈", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
//         "🏛️", "⛪", "🕌", "🕍", "🕋", "⛩️", "🛕", "🌄", "🌅", "🏠", "🏡", "🏥", "🏦", "🏤", "🏨",
//         "🏪", "🏬", "🌆", "🌇", "🌃", "🏙️", "🌉", "🌌", "🌠", "🎇", "🎆", "🌈",
//     ];

//     const object = [
//         "⌚", "📱", "💻", "⌨️", "🖥️", "🖱️", "🕹️", "🗜️", "💽", "💾", "💿", "📀", "🧮", "🎥", "📷",
//         "📸", "📹", "📼", "🔍", "🔎", "🔬", "🔭", "📡", "📠", "📺", "📻", "🎙️", "🎚️", "🎛️", "⏱️",
//         "⏲️", "⏰", "🕰️", "⌛", "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️", "🪔", "🧯", "🛢️", "🔧",
//         "🔨", "⚒️", "🛠️", "⛏️", "🔩", "⚙️", "🗜️", "⚖️", "🦯", "🔗", "⛓️", "🧰", "🧲", "🔫", "🛡️",
//         "🚬", "⚰️", "⚱️", "🗺️", "🧭", "🧱", "🔮", "🧿", "🏮", "🪑", "🧼", "🧽", "🧴", "🛁", "🚿",
//         "🪒", "🧻", "🧺", "🧹", "🧺", "🧹", "🧼", "🧽", "🧯", "🛢️", "🪣", "🧰", "🔧", "🔩", "⚙️",
//         "🛠️", "🧲", "🔫", "🚬", "🔪", "🧫", "🧬", "🔬", "🔭", "📡", "🛎️", "🔑", "🗝️", "🚪", "🛋️",
//         "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔑", "🗝️", "🛋️",
//         "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔖", "🚬", "🔗",
//         "📎", "🖇️", "📌", "📍", "🧷", "🧵", "🧶", "🔊", "🔈", "🔇", "📢", "📣", "📯"
//     ];


//     return (
//         <div className={styles.emojis_div}>
//             <div>
//                 <b className='d-block'>Smileys</b>
//                 {smileys?.map((emoji, index) => (
//                     <span
//                         key={index}
//                         className={styles.emojis_icon_css}
//                         onClick={() => onSelect(emoji)}
//                     >
//                         {emoji}
//                     </span>
//                 ))}
//             </div>
//             <div className='mt-2'>
//                 <b className='d-block'>Travel And Places</b>
//                 {travelAndPlaces?.map((emoji, index) => (
//                     <span
//                         key={index}
//                         className={styles.emojis_icon_css}
//                         onClick={() => onSelect(emoji)}
//                     >
//                         {emoji}
//                     </span>
//                 ))}
//             </div>
//             <div className='mt-2'>
//                 <b className='d-block'>Object</b>
//                 {object?.map((emoji, index) => (
//                     <span
//                         key={index}
//                         className={styles.emojis_icon_css}
//                         onClick={() => onSelect(emoji)}
//                     >
//                         {emoji}
//                     </span>
//                 ))}
//             </div>
//         </div>
//     );
// };

// const PDFViewer = ({ url }) => {
//     return (
//         <>
//             <div className={styles.pdfView} >
//                 <object
//                     className={styles.pdf_container}
//                     data={`${url}#page=1`}
//                     type="application/pdf"
//                     width="50%"
//                     height="100%"
//                 >
//                     <p>This browser does not support PDFs. Please download the PDF to view it: <a href={url}>Download PDF</a></p>
//                 </object>
//             </div>
//         </>
//     );
// }


// const VideoThumbnail = ({ videoUrl }) => {
//     const videoRef = useRef(null);
//     // const [thumbnailUrl, setThumbnailUrl] = useState(null);

//     // const generateThumbnail = () => {
//     //     const canvas = document.createElement('canvas');
//     //     canvas.width = videoRef.current.videoWidth;
//     //     canvas.height = videoRef.current.videoHeight;
//     //     canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
//     //     const url = canvas.toDataURL();
//     //     setThumbnailUrl(url);
//     // };

//     return (
//         <div className={styles.pdfView}>
//             <video ref={videoRef} controls>
//                 <source src={videoUrl} type="video/mp4" />
//                 Your browser does not support the video tag.
//             </video>
//             {/* <button onClick={generateThumbnail}>Generate Thumbnail</button> */}
//             {/* {thumbnailUrl && <img src={thumbnailUrl} alt="Thumbnail" />} */}
//         </div>
//     );
// };