// import React, { useRef, useState, useEffect } from "react";
// import styles from "./FromComponent.module.css";
// import { BoldIcon, ItalicIcon } from "../../icons/Icon";
// import { useAuth } from "../../context/Auth";

// const Editer = ({ editorContent ,setEditorContent }) => {
//   const { mode } = useAuth();
//   const editorRef = useRef(null);
//   // const [editorContent, setEditorContent] = useState("");
//   const [activeFormats, setActiveFormats] = useState({
//     bold: false,
//     italic: false,
//     lineBreak: false,
//     unorderedList: false,
//   });

//   const formatText = (command, event) => {
//     event.preventDefault();
//     document.execCommand(command, false, null);
//     updateActiveFormats();
//   };

//   const updateActiveFormats = () => {
//     setActiveFormats({
//       bold: document.queryCommandState("bold"),
//       italic: document.queryCommandState("italic"),
//       lineBreak: activeFormats.lineBreak,
//       unorderedList: document.queryCommandState("insertUnorderedList"),
//     });
//   };

//   const getEditorContent = () => {
//     const content = editorRef.current.innerHTML; 
//     setEditorContent(content);
//     console.log({ __html: content });
//     console.log("Active Formats:", activeFormats);
//   };

//   useEffect(() => {
//     updateActiveFormats();
//   }, [editorContent]);

//   const buttonStyle = (isActive) => ({
//     borderBottom: isActive ? (mode ? "1px solid #fff" : "1px solid #000") : "none",
//     backgroundColor: mode ? "#000" : "#fafafa",
//     color: mode ? "#fff" : "#000",
//   });
  

//   return (
//     <div>
//       <div>
//         <span style={mode ? {color:"#fff" , marginLeft:"10px"} :{color:"#000" , marginLeft:"10px"}}>Body <span style={{ color: "red" }}>*</span></span>
//       </div>
//       <div
//         className={styles.editor}
//         ref={editorRef}
//         contentEditable
//         suppressContentEditableWarning={true}
//         onInput={getEditorContent}
//         style={mode ? {backgroundColor:"#1B1D21" , border:"1px solid #333", color:"#E4E7EB"}:{backgroundColor:"#fff" ,border:"1px solid #E4E7EB", color:"#000 "}}
//       />
      
//       <div className={styles.toolbar}>
//         <button onClick={(e) => formatText("bold", e)} style={buttonStyle(activeFormats.bold)}>
//         B
//         </button>
//         <button onClick={(e) => formatText("italic", e)} style={buttonStyle(activeFormats.italic)}>
//         I
//         </button>
//         <button onClick={(e) => formatText("insertLineBreak", e)} style={buttonStyle(activeFormats.lineBreak)}>
//           &#129031;
//         </button>
//         <button onClick={(e) => formatText("insertUnorderedList", e)} style={buttonStyle(activeFormats.unorderedList)}>
//           U
//         </button>
//       </div>

//       {/* <div>
//         <div dangerouslySetInnerHTML={{ __html: editorContent }} />
//       </div> */}
//     </div>
//   );
// };

// export default Editer;










// import React, { useRef, useState, useEffect } from "react";
// import styles from "./FromComponent.module.css";
// import { BoldIcon, ItalicIcon } from "../../icons/Icon";
// import { useAuth } from "../../context/Auth";

// const Editer = ({ editorContent, setEditorContent }) => {
//   const { mode } = useAuth();
//   const editorRef = useRef(null);
//   const [activeFormats, setActiveFormats] = useState({
//     bold: false,
//     italic: false,
//     lineBreak: false,
//     unorderedList: false,
//   });
//   const [isContentValid, setIsContentValid] = useState(true); // State for validation

//   const formatText = (command, event) => {
//     event.preventDefault();
//     document.execCommand(command, false, null);
//     updateActiveFormats();
//   };

//   const updateActiveFormats = () => {
//     setActiveFormats({
//       bold: document.queryCommandState("bold"),
//       italic: document.queryCommandState("italic"),
//       lineBreak: activeFormats.lineBreak,
//       unorderedList: document.queryCommandState("insertUnorderedList"),
//     });
//   };

//   const getEditorContent = () => {
//     const content = editorRef.current.innerHTML; 
//     setEditorContent(content);
//     validateContent(content); // Validate content on input
//     // console.log({ __html: content });
//     // console.log("Active Formats:", activeFormats);
//   };

//   const validateContent = (content) => {
//     setIsContentValid(content.trim().length > 0); // Check if content is not empty
//   };

//   useEffect(() => {
//     updateActiveFormats();
//   }, [editorContent]);

//   const buttonStyle = (isActive) => ({
//     borderBottom: isActive ? (mode ? "1px solid #fff" : "1px solid #000") : "none",
//     backgroundColor: mode ? "#000" : "#fafafa",
//     color: mode ? "#fff" : "#000",
//   });
  
//   return (
//     <div>
//       <div>
//         <span style={mode ? { color: "#fff", marginLeft: "10px" } : { color: "#000", marginLeft: "10px" }}>
//           Body <span style={{ color: "red" }}>*</span>
//         </span>
//       </div>
//       <div
//         className={styles.editor}
//         ref={editorRef}
//         contentEditable
//         suppressContentEditableWarning={true}
//         onInput={getEditorContent}
//         style={mode ? { backgroundColor: "#1B1D21", border: "1px solid #333", color: "#E4E7EB" } : { backgroundColor: "#fff", border: "1px solid #E4E7EB", color: "#000" }}
//       />
      
//       { !isContentValid && <div style={{ color: 'red' }}>Content is required.</div> } {/* Feedback message */}

//       <div className={styles.toolbar}>
//         <button onClick={(e) => formatText("bold", e)} style={buttonStyle(activeFormats.bold)}>
//           B
//         </button>
//         <button onClick={(e) => formatText("italic", e)} style={buttonStyle(activeFormats.italic)}>
//           I
//         </button>
//         <button onClick={(e) => formatText("insertLineBreak", e)} style={buttonStyle(activeFormats.lineBreak)}>
//           &#129031;
//         </button>
//       </div>

//       {/* <div>
//         <div dangerouslySetInnerHTML={{ __html: editorContent }} />
//       </div> */}
//     </div>
//   );
// };

// export default Editer;





/* ==================== old code ================== */


// import React, { useRef, useState, useEffect } from "react";
// import styles from "./FromComponent.module.css";
// import { useAuth } from "../../context/Auth";
// import { BoldIcon, EmojisIcon, ItalicIcon } from "../../icons/Icon";

// const Editer = ({ editorContent, setEditorContent }) => {
//   const { mode } = useAuth();
//   const [bodyText, setBodyText] = useState('')
//   const [selectionStart, setSelectionStart] = useState(0);
//   const [selectionEnd, setSelectionEnd] = useState(0);
//   const [showEmojis, setShowEmojis] = useState(false);

//   const applyBold = () => {
//     const newText = bodyText && `${bodyText.slice(0, selectionStart)}*${bodyText.slice(selectionStart, selectionEnd)}*${bodyText.slice(selectionEnd)}`;
//     setBodyText(newText);
// };

// const handleSelectionChange = event => {
//   setSelectionStart(event.target.selectionStart);
//   setSelectionEnd(event.target.selectionEnd);
// };
// console.log(bodyText);

// const handleEmojiSelect = emoji => {
//   setBodyText(prevContent => prevContent + emoji);
// };

// const applyItalic = () => {
//   const newText = bodyText && `${bodyText.slice(0, selectionStart)}_${bodyText.slice(selectionStart, selectionEnd)}_${bodyText.slice(selectionEnd)}`;
//   setBodyText(newText);
// };

// const applyLineBreak = () => {
//   const newText = bodyText &&
//       bodyText.slice(0, selectionStart) +
//       "\n" +
//       bodyText.slice(selectionEnd);
//   setBodyText(newText);
// };

// const emojisRef = useRef(null);
// useEffect(() => {
//     const handleOutsideClick = (event) => {
//         if (emojisRef.current && !emojisRef.current.contains(event.target)) {
//             setShowEmojis(false);
//         }
//     };

//     document.addEventListener('mousedown', handleOutsideClick);

//     return () => {
//         document.removeEventListener('mousedown', handleOutsideClick);
//     };
// }, []);


//   return (
//     <div>
//        <div>
//            <p className='mt-4 mb-1'>Body&nbsp;<span className='text-danger'>*</span></p>
//               <textarea
//                   placeholder='Enter Message Here'
//                   rows={4}
//                     className={styles.templateBody}
//                       value={bodyText}
//                     onChange={(e) => setBodyText(e.target.value)}
//                    onSelect={handleSelectionChange}
//                       required
//                             />
//                         </div>
//                         <div className='d-flex justify-content-between'>
//                             {/* <EmojiPicker onSelect={handleEmojiSelect} /> */}
//                             <div className={styles.templateBody_count}>Characters: {bodyText?.length} / 1024</div>
//                             <div className='d-flex justify-content-left align-items-center'>
//                                 <div className={styles.txtEditor_btn}>
//                                     <div className={styles.icon_css} onClick={() => setShowEmojis(true)}><EmojisIcon /></div>
//                                     {showEmojis &&
//                                         // ref={divEmojis}
//                                         <div className={styles.emojis_list} ref={emojisRef}>
//                                             <div className='bg-light'> <EmojiPicker onSelect={handleEmojiSelect} /></div>
//                                         </div>
//                                     }
//                                     <div className={styles.icon_css} onClick={applyBold}><BoldIcon /></div>
//                                     <div className={styles.icon_css} onClick={applyItalic}><ItalicIcon /></div>
//                                     <div className={styles.icon_css} onClick={applyLineBreak}>&#129031;</div>
//                                 </div>
//                             </div>
//                             {/* <div className='bg-light'> <EmojiPicker onSelect={handleEmojiSelect} /></div> */}
//                         </div>

     
  
//     </div>
//   );
// };

// export default Editer;















import React, { useRef, useState, useEffect } from "react";
import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";
import { BoldIcon, EmojisIcon, ItalicIcon } from "../../icons/Icon";
import { Arro } from "../../icons/Icon2";

const Editer = ({ bodyText, setBodyText }) => {
  const { mode } = useAuth();
  // const [bodyText, setBodyText] = useState('');
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const [showEmojis, setShowEmojis] = useState(false);
// console.log(bodyText);

useEffect(()=>{

}, [bodyText])

  const applyBold = () => {
    const newText =
      bodyText &&
      `${bodyText.slice(0, selectionStart)}*${bodyText.slice(
        selectionStart,
        selectionEnd
      )}*${bodyText.slice(selectionEnd)}`;
    setBodyText(newText);
  };

  const applyItalic = () => {
    const newText =
      bodyText &&
      `${bodyText.slice(0, selectionStart)}_${bodyText.slice(
        selectionStart,
        selectionEnd
      )}_${bodyText.slice(selectionEnd)}`;
    setBodyText(newText);
  };

  const applyLineBreak = () => {
    const newText =
      bodyText.slice(0, selectionStart) + "\n" + bodyText.slice(selectionEnd);
    setBodyText(newText);
  };

  const handleSelectionChange = (event) => {
    setSelectionStart(event.target.selectionStart);
    setSelectionEnd(event.target.selectionEnd);
  };

  const handleEmojiSelect = (emoji) => {
    setBodyText((prevContent) => prevContent + emoji);
  };

  const emojisRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (emojisRef.current && !emojisRef.current.contains(event.target)) {
        setShowEmojis(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={styles.editerCointner}>
      <div className={styles.editerCointner1} >
        <p className={styles.para}>
          Body&nbsp;<span className={styles.star}>*</span>
        </p>
        <textarea
          placeholder="Enter Message Here"
          rows={4}
          className={styles.templateBody}
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
          onSelect={handleSelectionChange}
          required
          style={mode ?{ backgroundColor:"#1B1D21" ,  border: "1px solid #1B1D21" , color:"#fff"}:
          {backgroundColor:"#fff" ,  border: "1px solid #cecccc", color:"#000"}}
        />
      </div>
      <div className={styles.btnCon}>
        <div className={styles.templateBody_count} style={{color: mode ? "#fff":"#000"}} >
          Characters: {bodyText?.length} / 1024
        </div>
        <div className={styles.btnContiner}>
          <div className={styles.txtEditor_btn}>
            <div
              className={styles.icon_css1}
              onClick={() => setShowEmojis(!showEmojis)}
            >
              <div className={styles.po}>
              {showEmojis && <EmojiPicker onSelect={handleEmojiSelect}  /> }
              </div>
              <EmojisIcon mode={mode}/>
            </div>
            <div className={styles.icon_css} onClick={applyBold}>
              <BoldIcon mode={mode} />
            </div>
            <div className={styles.icon_css} onClick={applyItalic}>
              <ItalicIcon  mode={mode}  />
            </div>
            <div className={styles.icon_css} onClick={applyLineBreak}>
            <Arro   mode={mode}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editer;

const EmojiPicker = ({ onSelect }) => {

  const {mode} = useAuth()
  const smileys = [
      "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍",
      "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩",
      "🥳", "😏", "😒", "😞", "😔", "😟", "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢",
      "😭", "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓", "🤗",
      "🤔", "🤭", "🤫", "🤥", "😶", "😐", "😑", "😬", "🙄", "😯", "😦", "😧", "😮", "😲", "🥱",
      "😴", "🤤", "😪", "😵", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈",
      "👿", "👹", "👺", "🤡", "💩", "👻", "💀", "☠️", "👽", "👾", "🤖", "🎃", "😺", "😸", "😹",
      "😻", "😼", "😽", "🙀", "😿", "😾", "🤲", "👐", "🙌", "👏", "🤝", "👍", "👎", "👊", "✊",
      "🤛", "🤜", "🤞", "✌️", "🤟", "🤘", "👌", "👈", "👉", "👆", "👇", "☝️", "✋", "🤚", "🖐️",
      "🖖", "👋", "🤙", "💪", "🦾", "🖕", "✍️", "🙏", "🦶", "🦵", "🦿", "🦷", "🦴", "👀", "👁️‍🗨️",
      "👅", "👄", "💋", "🩸", "👂", "🦻", "👃", "🫁", "👣", "🦰", "🦱", "🦲", "🦳", "🐵", "🐒",
      "🦍", "🦧", "🐶", "🐕", "🦮", "🐕‍🦺", "🐩", "🐺", "🦊", "🦝", "🐱", "🐈", "🐈‍⬛", "🦁", "🐯",
      "🐅", "🐆", "🐴", "🐎", "🦄", "🦓", "🦌", "🦬", "🐮", "🐂", "🐃", "🐄", "🐷", "🐖", "🐗",
      "🐽", "🐏", "🐑", "🐐", "🐪", "🐫", "🦙", "🦒", "🐘", "🦣", "🦏", "🦛", "🐭", "🐁", "🐀",
      "🐹", "🐰", "🐇", "🐿️", "🦫", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨", "🦘", "🦡",
      "🐾", "🦃", "🐔", "🐓", "🐣", "🐤", "🐥", "🐦", "🐧", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦩",
      "🦚", "🦜", "🐸", "🐊", "🐢", "🦎", "🐍", "🐲", "🐉", "🦕", "🦖", "🐳", "🐋", "🐬", "🦭",
      "🐟", "🐠", "🐡", "🦈", "🐙", "🐚", "🐌", "🦋", "🐛", "🐜", "🐝", "🪰", "🐞", "🦗", "🪳",
      "🕷️", "🕸️", "🦂", "🦟", "🪲", "🦠", "💐", "🌸", "💮", "🏵️", "🌹", "🥀", "🌺", "🌻", "🌼",
      "🌷", "🌱", "🌲", "🌳", "🌴", "🌵", "🌾", "🌿", "☘️", "🍀", "🍁", "🍂", "🍃", "🍄", "🌰",
      "🦀", "🦞", "🦐", "🦑", "🌍", "🌎", "🌏", "🌐", "🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗",
      "🌘", "🌙", "🌚", "🌛", "🌜", "☀️", "🌝", "🌞", "🪐", "⭐", "🌟", "🌠", "🌌", "☁️", "⛅",
      "⛈️", "🌤️", "🌥️", "🌦️", "🌧️", "🌨️", "🌩️", "🌪️", "🌫️", "🌬️", "🌀", "🌈", "🌂", "☂️", "☔",
      "⚡", "❄️", "☃️", "⛄", "☄️", "🔥", "💧", "🌊"
  ];

  const travelAndPlaces = [
      "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", "🚚", "🚛", "🚜", "🛴", "🚲",
      "🛵", "🏍️", "🛺", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚋", "🚞", "🚝", "🚄", "🚅",
      "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🛸", "🛫", "🛬", "🛩️", "💺", "🚁", "🛶", "⛵", "🚤",
      "🛥️", "🚢", "🗼", "🏰", "🏯", "🏟️", "🎡", "🎢", "🎠", "⛲", "⛺", "🌁", "🏔️", "⛰️", "🌋",
      "🗻", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🏗️", "🌇", "🌆", "🏙️", "🌃", "🌉", "🌌", "🌠", "🎇",
      "🎆", "🌈", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
      "🏛️", "⛪", "🕌", "🕍", "🕋", "⛩️", "🛕", "🌄", "🌅", "🏠", "🏡", "🏥", "🏦", "🏤", "🏨",
      "🏪", "🏬", "🌆", "🌇", "🌃", "🏙️", "🌉", "🌌", "🌠", "🎇", "🎆", "🌈",
  ];

  const object = [
      "⌚", "📱", "💻", "⌨️", "🖥️", "🖱️", "🕹️", "🗜️", "💽", "💾", "💿", "📀", "🧮", "🎥", "📷",
      "📸", "📹", "📼", "🔍", "🔎", "🔬", "🔭", "📡", "📠", "📺", "📻", "🎙️", "🎚️", "🎛️", "⏱️",
      "⏲️", "⏰", "🕰️", "⌛", "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️", "🪔", "🧯", "🛢️", "🔧",
      "🔨", "⚒️", "🛠️", "⛏️", "🔩", "⚙️", "🗜️", "⚖️", "🦯", "🔗", "⛓️", "🧰", "🧲", "🔫", "🛡️",
      "🚬", "⚰️", "⚱️", "🗺️", "🧭", "🧱", "🔮", "🧿", "🏮", "🪑", "🧼", "🧽", "🧴", "🛁", "🚿",
      "🪒", "🧻", "🧺", "🧹", "🧺", "🧹", "🧼", "🧽", "🧯", "🛢️", "🪣", "🧰", "🔧", "🔩", "⚙️",
      "🛠️", "🧲", "🔫", "🚬", "🔪", "🧫", "🧬", "🔬", "🔭", "📡", "🛎️", "🔑", "🗝️", "🚪", "🛋️",
      "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔑", "🗝️", "🛋️",
      "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔖", "🚬", "🔗",
      "📎", "🖇️", "📌", "📍", "🧷", "🧵", "🧶", "🔊", "🔈", "🔇", "📢", "📣", "📯"
  ];


  return (
      <div className={styles.emojis_div} style={{backgroundColor: mode ? "#000":"#F8F9FC"}} >
          <div className={styles.emojis_div1}>
              <b style={{color:mode?"#fff":"#000"}} >Smileys</b>
              <div className={styles.imogy} >
              {smileys?.map((emoji, index) => (
                    <span
                        key={index}
                        className={styles.emojis_icon_css}
                        onClick={() => onSelect(emoji)} >

                        {emoji}
                    </span>
                ))}
              </div>
          </div>
           <div className={styles.emojis_div1}>
              <b style={{color:mode?"#fff":"#000"}}  >Travel And Places</b>
              <div className={styles.imogy} >
              {travelAndPlaces?.map((emoji, index) => (
                    <span
                        key={index}
                        className={styles.emojis_icon_css}
                        onClick={() => onSelect(emoji)}
                    >
                        {emoji}
                    </span>
                ))}
              </div>
          </div>
         <div className={styles.emojis_div1}>
              <b style={{color:mode?"#fff":"#000"}}  >Object</b>
              <div className={styles.imogy} >
              {object?.map((emoji, index) => (
                    <span
                        key={index}
                        className={styles.emojis_icon_css}
                        onClick={() => onSelect(emoji)}
                    >
                        {emoji}
                    </span>
                ))}
              </div>
          </div>
          
         
      </div>
  );
};












// import React, { useRef, useState, useEffect } from "react";
// import styles from "./FromComponent.module.css";
// import { BoldIcon, EmojisIcon, ItalicIcon } from "../../icons/Icon";
// import { useAuth } from "../../context/Auth";

// const Editer = ({ editorContent, setEditorContent }) => {
//   const { mode } = useAuth();
//   const [bodyText , setBodyText] = useState('')
//   const [selectionStart, setSelectionStart] = useState(0);
//   const [selectionEnd, setSelectionEnd] = useState(0);
//   const [showEmojis, setShowEmojis] = useState(false);
//   console.log(bodyText);

//   const handleSelectionChange = event => {
//     setSelectionStart(event.target.selectionStart);
//     setSelectionEnd(event.target.selectionEnd);
// };
//   const applyBold = () => {
//     const newText = bodyText && `${bodyText.slice(0, selectionStart)}**${bodyText.slice(selectionStart, selectionEnd)}**${bodyText.slice(selectionEnd)}`;
//     setBodyText(newText);
// };

// const applyItalic = () => {
//     const newText = bodyText && `${bodyText.slice(0, selectionStart)}_${bodyText.slice(selectionStart, selectionEnd)}_${bodyText.slice(selectionEnd)}`;
//     setBodyText(newText);
// };

// const applyLineBreak = () => {
//     const newText = bodyText &&
//         bodyText.slice(0, selectionStart) +
//         '<br />' +
//         bodyText.slice(selectionEnd);
//     setBodyText(newText);
// };

// const emojisRef = useRef(null);
// useEffect(() => {
//     const handleOutsideClick = (event) => {
//         if (emojisRef.current && !emojisRef.current.contains(event.target)) {
//             setShowEmojis(false);
//         }
//     };

//     document.addEventListener('mousedown', handleOutsideClick);

//     return () => {
//         document.removeEventListener('mousedown', handleOutsideClick);
//     };
// }, []);

// const handleEmojiSelect = emoji => {
//   setBodyText(prevContent => prevContent + emoji);
// };



  
//   return (
//     <div>
//       <div>
//         <span style={mode ? { color: "#fff", marginLeft: "10px" } : { color: "#000", marginLeft: "10px" }}>
//           Body <span style={{ color: "red" }}>*</span>
//         </span>
//       </div>
//       <div>
//       <textarea
//                                 placeholder='Enter Message Here'
//                                 rows={4}
//                                 className={styles.templateBody}
//                                 value={bodyText}
//                                 onChange={(e) => setBodyText(e.target.value)}
//                                 onSelect={handleSelectionChange}
//                                 required
//                             />
//       </div>
//       <div className='d-flex justify-content-between'>
//                             {/* <EmojiPicker onSelect={handleEmojiSelect} /> */}
//                             <div className={styles.templateBody_count}>Characters: {bodyText?.length} / 1024</div>
//                             <div className='d-flex justify-content-left align-items-center'>
//                                 <div className={styles.txtEditor_btn}>
//                                     <div className={styles.icon_css} onClick={() => setShowEmojis(true)}><EmojisIcon /></div>
//                                     {showEmojis &&
//                                         // ref={divEmojis}
//                                         <div className={styles.emojis_list} ref={emojisRef}>
//                                             <div className='bg-light'> <EmojisIcon onSelect={handleEmojiSelect} /></div>
//                                         </div>
//                                     }
//                                     <div className={styles.icon_css} onClick={applyBold}><BoldIcon /></div>
//                                     <div className={styles.icon_css} onClick={applyItalic}><ItalicIcon /></div>
//                                     <div className={styles.icon_css} onClick={applyLineBreak}>&#129031;</div>
//                                 </div>
//                             </div>
//                             {/* <div className='bg-light'> <EmojiPicker onSelect={handleEmojiSelect} /></div> */}
//                         </div>
     
      


   
//     </div>
//   );
// };

// export default Editer;

// const EmojiPicker = ({ onSelect }) => {
//   const smileys = [
//       "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍",
//       "🥰", "😘", "😗", "😙", "😚", "😋", "😛", "😝", "😜", "🤪", "🤨", "🧐", "🤓", "😎", "🤩",
//       "🥳", "😏", "😒", "😞", "😔", "😟", "😕", "🙁", "☹️", "😣", "😖", "😫", "😩", "🥺", "😢",
//       "😭", "😤", "😠", "😡", "🤬", "🤯", "😳", "🥵", "🥶", "😱", "😨", "😰", "😥", "😓", "🤗",
//       "🤔", "🤭", "🤫", "🤥", "😶", "😐", "😑", "😬", "🙄", "😯", "😦", "😧", "😮", "😲", "🥱",
//       "😴", "🤤", "😪", "😵", "🤐", "🥴", "🤢", "🤮", "🤧", "😷", "🤒", "🤕", "🤑", "🤠", "😈",
//       "👿", "👹", "👺", "🤡", "💩", "👻", "💀", "☠️", "👽", "👾", "🤖", "🎃", "😺", "😸", "😹",
//       "😻", "😼", "😽", "🙀", "😿", "😾", "🤲", "👐", "🙌", "👏", "🤝", "👍", "👎", "👊", "✊",
//       "🤛", "🤜", "🤞", "✌️", "🤟", "🤘", "👌", "👈", "👉", "👆", "👇", "☝️", "✋", "🤚", "🖐️",
//       "🖖", "👋", "🤙", "💪", "🦾", "🖕", "✍️", "🙏", "🦶", "🦵", "🦿", "🦷", "🦴", "👀", "👁️‍🗨️",
//       "👅", "👄", "💋", "🩸", "👂", "🦻", "👃", "🫁", "👣", "🦰", "🦱", "🦲", "🦳", "🐵", "🐒",
//       "🦍", "🦧", "🐶", "🐕", "🦮", "🐕‍🦺", "🐩", "🐺", "🦊", "🦝", "🐱", "🐈", "🐈‍⬛", "🦁", "🐯",
//       "🐅", "🐆", "🐴", "🐎", "🦄", "🦓", "🦌", "🦬", "🐮", "🐂", "🐃", "🐄", "🐷", "🐖", "🐗",
//       "🐽", "🐏", "🐑", "🐐", "🐪", "🐫", "🦙", "🦒", "🐘", "🦣", "🦏", "🦛", "🐭", "🐁", "🐀",
//       "🐹", "🐰", "🐇", "🐿️", "🦫", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨", "🦘", "🦡",
//       "🐾", "🦃", "🐔", "🐓", "🐣", "🐤", "🐥", "🐦", "🐧", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦩",
//       "🦚", "🦜", "🐸", "🐊", "🐢", "🦎", "🐍", "🐲", "🐉", "🦕", "🦖", "🐳", "🐋", "🐬", "🦭",
//       "🐟", "🐠", "🐡", "🦈", "🐙", "🐚", "🐌", "🦋", "🐛", "🐜", "🐝", "🪰", "🐞", "🦗", "🪳",
//       "🕷️", "🕸️", "🦂", "🦟", "🪲", "🦠", "💐", "🌸", "💮", "🏵️", "🌹", "🥀", "🌺", "🌻", "🌼",
//       "🌷", "🌱", "🌲", "🌳", "🌴", "🌵", "🌾", "🌿", "☘️", "🍀", "🍁", "🍂", "🍃", "🍄", "🌰",
//       "🦀", "🦞", "🦐", "🦑", "🌍", "🌎", "🌏", "🌐", "🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗",
//       "🌘", "🌙", "🌚", "🌛", "🌜", "☀️", "🌝", "🌞", "🪐", "⭐", "🌟", "🌠", "🌌", "☁️", "⛅",
//       "⛈️", "🌤️", "🌥️", "🌦️", "🌧️", "🌨️", "🌩️", "🌪️", "🌫️", "🌬️", "🌀", "🌈", "🌂", "☂️", "☔",
//       "⚡", "❄️", "☃️", "⛄", "☄️", "🔥", "💧", "🌊"
//   ];

//   const travelAndPlaces = [
//       "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚐", "🚚", "🚛", "🚜", "🛴", "🚲",
//       "🛵", "🏍️", "🛺", "🚍", "🚘", "🚖", "🚡", "🚠", "🚟", "🚃", "🚋", "🚞", "🚝", "🚄", "🚅",
//       "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🛸", "🛫", "🛬", "🛩️", "💺", "🚁", "🛶", "⛵", "🚤",
//       "🛥️", "🚢", "🗼", "🏰", "🏯", "🏟️", "🎡", "🎢", "🎠", "⛲", "⛺", "🌁", "🏔️", "⛰️", "🌋",
//       "🗻", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🏗️", "🌇", "🌆", "🏙️", "🌃", "🌉", "🌌", "🌠", "🎇",
//       "🎆", "🌈", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
//       "🏛️", "⛪", "🕌", "🕍", "🕋", "⛩️", "🛕", "🌄", "🌅", "🏠", "🏡", "🏥", "🏦", "🏤", "🏨",
//       "🏪", "🏬", "🌆", "🌇", "🌃", "🏙️", "🌉", "🌌", "🌠", "🎇", "🎆", "🌈",
//   ];

//   const object = [
//       "⌚", "📱", "💻", "⌨️", "🖥️", "🖱️", "🕹️", "🗜️", "💽", "💾", "💿", "📀", "🧮", "🎥", "📷",
//       "📸", "📹", "📼", "🔍", "🔎", "🔬", "🔭", "📡", "📠", "📺", "📻", "🎙️", "🎚️", "🎛️", "⏱️",
//       "⏲️", "⏰", "🕰️", "⌛", "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️", "🪔", "🧯", "🛢️", "🔧",
//       "🔨", "⚒️", "🛠️", "⛏️", "🔩", "⚙️", "🗜️", "⚖️", "🦯", "🔗", "⛓️", "🧰", "🧲", "🔫", "🛡️",
//       "🚬", "⚰️", "⚱️", "🗺️", "🧭", "🧱", "🔮", "🧿", "🏮", "🪑", "🧼", "🧽", "🧴", "🛁", "🚿",
//       "🪒", "🧻", "🧺", "🧹", "🧺", "🧹", "🧼", "🧽", "🧯", "🛢️", "🪣", "🧰", "🔧", "🔩", "⚙️",
//       "🛠️", "🧲", "🔫", "🚬", "🔪", "🧫", "🧬", "🔬", "🔭", "📡", "🛎️", "🔑", "🗝️", "🚪", "🛋️",
//       "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔑", "🗝️", "🛋️",
//       "🪑", "🚽", "🚿", "🛁", "🪒", "🧻", "🧼", "🧽", "🧴", "🪥", "🪦", "🏺", "🔖", "🚬", "🔗",
//       "📎", "🖇️", "📌", "📍", "🧷", "🧵", "🧶", "🔊", "🔈", "🔇", "📢", "📣", "📯"
//   ];


//   return (
//       <div className={styles.emojis_div}>
//           <div>
//               <b className='d-block'>Smileys</b>
//               {smileys?.map((emoji, index) => (
//                   <span
//                       key={index}
//                       className={styles.emojis_icon_css}
//                       onClick={() => onSelect(emoji)}
//                   >
//                       {emoji}
//                   </span>
//               ))}
//           </div>
//           <div className='mt-2'>
//               <b className='d-block'>Travel And Places</b>
//               {travelAndPlaces?.map((emoji, index) => (
//                   <span
//                       key={index}
//                       className={styles.emojis_icon_css}
//                       onClick={() => onSelect(emoji)}
//                   >
//                       {emoji}
//                   </span>
//               ))}
//           </div>
//           <div className='mt-2'>
//               <b className='d-block'>Object</b>
//               {object?.map((emoji, index) => (
//                   <span
//                       key={index}
//                       className={styles.emojis_icon_css}
//                       onClick={() => onSelect(emoji)}
//                   >
//                       {emoji}
//                   </span>
//               ))}
//           </div>
//       </div>
//   );
// };


































// import React, { useRef, useState } from "react";
// import styles from "./FromComponent.module.css";

// const Editer = () => {
//     const editorRef = useRef(null);
//     const [editorContent, setEditorContent] = useState("");

//     // Function to handle text formatting
//     const formatText = (command, event) => {
//         event.preventDefault();
//         document.execCommand(command, false, null);
//         console.log("jjj");
        
//     };

//     // Function to get editor content and send to API
//     const getEditorContent = async (event) => {
//         event.preventDefault();
//         if (editorRef.current) {
//             const content = editorRef.current.innerText; // Get plain text
//             setEditorContent(content);
//             console.log("Helll", { text: content });

//             // Call the API with the ed
//         }
//     };


//     return (
//         <div>
//             <div className={styles.toolbar}>
//                 <button onClick={(e) => formatText("bold", e)}>Bold</button>
//                 <button onClick={(e) => formatText("italic", e)}>Italic</button>
//                 <button onClick={(e) => formatText("underline", e)}>Underline</button>
//                 <button onClick={(e) => formatText("strikeThrough", e)}>Strike</button>
//                 <button onClick={(e) => formatText("insertOrderedList", e)}>OL</button>
//                 <button onClick={(e) => formatText("insertUnorderedList", e)}>UL</button>
//             </div>

//             <div
//                 className={styles.editor}
//                 ref={editorRef}
//                 contentEditable
//                 suppressContentEditableWarning={true}
//             >
//             </div>

//             <button onClick={getEditorContent}>Save</button>

//             <div>
//                 <div>{editorContent}</div>
//             </div>
//         </div>
//     );
// };

// export default Editer;
