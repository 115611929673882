import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SideBarDashBoardIcon,
  SideBarDealersIcon,
  SideBarDispatchIcon,
  SideBarInventoryIcon,
  SideBarOrdersIcon,
  SideBarOutsideInventoryIcon,
  SideBarPackingOrderIcon,
  SideBarProductIcon,
  SideBarReceiptIcon,
  SideBarWalliconLogo,
} from "../components/icons/Icon";
import styles from "./Menus.module.css";
import { useAuth } from "../components/context/Auth";

const AdminMenu = ({ isClosed, menuItems }) => {
  const navigate = useNavigate();
  const { designation, mode } = useAuth();
  const { pathname } = useLocation();
  const [hoveredPath, setHoveredPath] = useState(null);

  const marketingMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "CONTACTS", path: "/contacts", Icon: SideBarDealersIcon },
    { label: "LEADS", path: "/leads", Icon: SideBarOrdersIcon },
  ];

  const contentManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "PRODUCTS", path: "/products", Icon: SideBarProductIcon },
    { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon },
  ];

  const orderManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "ORDER", path: "/orders", Icon: SideBarInventoryIcon },
    // { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon },
    { label: "DEALERS", path: "/orders/dealers", Icon: SideBarDealersIcon },
  ];

  const inventoryManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "INVENTORY", path: "/inventory", Icon: SideBarDashBoardIcon },
    {
      label: "OUTSIDE INVENTORY",
      path: "/outsideinventory",
      Icon: SideBarOutsideInventoryIcon,
    },
    { label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon },
  ];

  const dispatchManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "DISPATCH", path: "/dispatchorders", Icon: SideBarInventoryIcon },
    { label: "PACKERS", path: "/packers", Icon: SideBarInventoryIcon },
  ];

  const packingManagerMenuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
    { label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon },
  ];

  const paymentManagerMenuItems = [
    { label: "RECEIPTS", path: "/receipts", Icon: SideBarReceiptIcon },
  ];

  let filteredMenuItems = menuItems;
  if (designation === "marketing") {
    filteredMenuItems = marketingMenuItems;
  } else if (designation === "contentmanager") {
    filteredMenuItems = contentManagerMenuItems;
  } else if (designation === "ordermanager") {
    filteredMenuItems = orderManagerMenuItems;
  } else if (designation === "inventorymanager") {
    filteredMenuItems = inventoryManagerMenuItems;
  } else if (designation === "dispatchmanager") {
    filteredMenuItems = dispatchManagerMenuItems;
  } else if (designation === "packingmanager") {
    filteredMenuItems = packingManagerMenuItems;
  } else if (designation === "paymentmanager") {
    filteredMenuItems = paymentManagerMenuItems;
  } else if (designation === "superadmin") {
    filteredMenuItems = menuItems;
  }

  return (
    <React.Fragment>
      <div
        className={`${styles.logo_Blue_Bg_Div} ${
          mode ? styles.logo_Blue_Bg_Div_Dark : ""
        }`}
      >
        <SideBarWalliconLogo
          backgroundColor={mode ? "#232529" : "#F5F6FA"}
          textColor={mode ? "#FFFFFF" : "#231F20"}
        />
      </div>

      {filteredMenuItems.map(({ label, path, Icon }) => (
        <div
          key={path}
          className={`${styles.menuItem_div} ${
            pathname === path
              ? mode
                ? `${styles.active_div_Dark}`
                : `${styles.active_div}`
              : ""
          } ${isClosed ? styles.menuItem_div_toggled : ""}`}
          onClick={() => navigate(path)}
          onMouseEnter={() => setHoveredPath(path)}
          onMouseLeave={() => setHoveredPath(null)}
          style={{
            backgroundColor:
              pathname === path
                ? mode
                  ? "#232529"
                  : "#f5f6fa"
                : mode
                ? hoveredPath === path
                  ? "#232529"
                  : "#2C2E33"
                : hoveredPath === path
                ? "#f5f6fa"
                : "#ffffff",
          }}
        >
          <Icon css={styles.iconImage} color={mode ? "#ffffff" : "#646B88"} />
          <span
            className={isClosed ? styles.hide_span : ""}
            style={mode ? { color: "#fff" } : { color: "#646B88" }}
          >
            {label}
          </span>
        </div>
      ))}
    </React.Fragment>
  );
};

export default AdminMenu;
