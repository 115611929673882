// import React from 'react'
// import styles from "./FromComponent.module.css"
// import { useAuth } from '../../context/Auth'
// const InputTypedate = ({ text, onChange }) => {
//     const { mode } = useAuth()
//     return (
//         <div>
//             <span style={mode ? { color: "#fafafa" } : { color: "#1B1D21" }} >  {text}</span>
//             <input type="date" 
//             className={styles.input} 
//             onChange={onChange} 
//             style={mode ? { backgroundColor: "#1B1D21" , color:"#ffff" } :
//              {backgroundColor: "#ffff" , color:"#1B1D21" }} />
//         </div>
//     )
// }

// export default InputTypedate



import React from 'react';
// import styles from "./FromComponent.module.css";
import { useAuth } from '../../context/Auth';

const InputTypedate = ({ text, onChange, value }) => {
    const { mode } = useAuth();
    const today = new Date().toISOString().split('T')[0]

    console.log("Date changed" , today);
    

    const inputStyle = {
        padding: '12px 6px',
        color: mode ? '#ffff' : '#1B1D21',
        fontSize: '13px',
        border: 'none',
        outline: 'none',
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: mode ? '#1B1D21' : '#ffff',
        backgroundImage: "url('./assets/updatedAssets/date.png')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 3px center',
        cursor: "pointer",
        // Uncomment below to set the size of the background image
        // backgroundSize: '18px 18px',
        appearance: 'none',
        '-webkit-appearance': 'none',
    };

    return (
        <div>
            <span style={{ color: mode ? "#fafafa" : "#1B1D21", marginRight:"10px"}}>{text}</span>
            <input
                type="date"
                // className={styles.input} 
                onChange={onChange}
                style={inputStyle}
                value={value}
                max={today}
            />
        </div>
    );
}

export default InputTypedate;
