import React, { useState } from "react";
import styles from "./DealerStyles.module.css";
import { useAuth } from "../../context/Auth";

const SlidingFilter = ({ itemList, bgcolor, setFilter }) => {
  const { mode } = useAuth();
  const [selectedItem, setSelectedItem] = useState(itemList[0]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === "All") {
      setFilter("");
    } else {
      setFilter(item);
    }
  };

  return (
    <div
      className={mode ? styles.sliding_filter_dark : styles.sliding_filter}
      style={
        mode ? { backgroundColor: "#1B1D21" } : { backgroundColor: "#fafafa" }
      }
    >
      <ul className={styles.sliding_filter_list}>
        {itemList.map((item, index) => (
          <li
            key={index}
            className={`${styles.sliding_filter_item} ${
              item === selectedItem ? styles.sliding_selected_item : ""
            }`}
            onClick={() => handleItemClick(item)}
            style={
              item === selectedItem
                ? bgcolor
                  ? { backgroundColor: bgcolor }
                  : mode
                  ? { backgroundColor: "#000", color: "#fff" }
                  : { backgroundColor: "#000", color: "#fff" }
                : { backgroundColor: "transparent" }
            }
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SlidingFilter;
