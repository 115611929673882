import { useAuth } from "../../context/Auth";
import {
  DealerInfoCardActiveStatusIcon,
  DealerInfoCardBlackListedStatusIcon,
  DealerInfoCardNewStatusIcon,
  DealerInfoCardPendingStatusIcon,
  DealerInfoCardRejectedStatusIcon,
} from "../../icons/Icon";
import { AllCount, Deleted, Verified } from "../../icons/Icon2";
import styles from "./DealerStyles.module.css";

const DealerStatusCard = ({
  statusText,
  statusItemCount,
  dealerStatus,
  manager,
}) => {
  // console.log(dealerStatus);

  const renderStatusIcon = () => {
    switch (dealerStatus) {
      case "Blacklisted":
        return <DealerInfoCardBlackListedStatusIcon />;
      case "New":
        return <DealerInfoCardNewStatusIcon />;
      case "Pending":
        return <DealerInfoCardPendingStatusIcon />;
      case "Rejected":
        return <DealerInfoCardRejectedStatusIcon />;
      case "All":
        return <AllCount />;
      case "Verified":
        return <Verified />;
      case "Update":
        return <DealerInfoCardActiveStatusIcon />;
      case "Deleted":
        return <Deleted />;
      default:
        return null;
    }
  };

  const { mode } = useAuth();
  return (
    <div
      className={styles.dealer_status_card}
      style={
        mode
          ? { backgroundColor: "#1B1D21", color: "#fff", border: "none" }
          : {}
      }
    >
      {manager ? (
        <>
          {statusText === "Active" && <DealerInfoCardActiveStatusIcon />}

          {statusText === "Disabled" && (
            <DealerInfoCardBlackListedStatusIcon fill={mode && "#1B1D21"} />
          )}
        </>
      ) : (
        <> {renderStatusIcon()} </>
      )}

      <p>{statusText}</p>
      <p>{statusItemCount}</p>
    </div>
  );
};

export default DealerStatusCard;
