import { useNavigate } from "react-router-dom";
import {
  CrossIcon,
  NewManagerCancelIcon,
  SaveTaxRateIcon,
  TestTopAddDealerIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
} from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import styles from "./Inventory.module.css";
import { useAuth } from "../../components/context/Auth";
import { useState } from "react";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";

const TaxRate = () => {
  const { mode } = useAuth();
  const navigate = useNavigate();
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <div
              className={styles.inventory_top_left_go_back}
              onClick={() => navigate(-1)}
            >
              <TestTopBackArrowIcon color={mode ? "#ffffff" : "#0100E4"} />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin: 0,
                  paddingTop: "0.21rem",
                  color: mode ? "#FFFFFF" : "#0100E4",
                }}
              >
                Go Back
              </p>
            </div>
            <TestTopDotIcon color={mode ? "#E4E7EB" : "#646B88"} />
            <p
              className={styles.inventory_top_left_path_text}
              style={{
                color: mode ? "#9C9797" : "#646B88",
              }}
            >
              Product/Tax Rate
            </p>
          </div>

          <div className={styles.tax_rate_btn}>
            <Button
              buttonIcon={<TestTopAddDealerIcon />}
              buttonText={"Create Tax Rate"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                handleOpenModal(
                  <AddTaxRateModal
                    handleCloseModal={handleCloseModal}
                    mode={mode}
                  />
                );
              }}
            />
          </div>
          <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal}>
            {modalContent}
          </ModalWrapper>
        </div>
        <div className={styles.inventory_search_bar}>
          <SearchBar />
        </div>
        <div className={styles.inventory_table_container}>
          <ul className={styles.responsiveTable}>
            <li
              className={styles.tableHeader}
              style={
                mode
                  ? {
                      backgroundColor: "#232529",
                      color: "#fafafa",
                      border: "1px solid #646B88",
                    }
                  : { backgroundColor: "#D9DCE5", color: "#646B88" }
              }
            >
              <div className="col">Status</div>
              <div className="col">Tax ID</div>
              <div className="col">Name</div>
              <div className="col">Rate</div>
            </li>

            <div className={styles.inventory_data_container}>
              {Array(30)
                .fill()
                .map((_, index) => (
                  <li
                    key={index}
                    className={styles.tableHeader}
                    style={
                      mode
                        ? {
                            backgroundColor: "#1B1D21",
                            color: "#fafafa",
                            border: "1px solid #646B88",
                            cursor: "pointer",
                          }
                        : { backgroundColor: "#FFFFFF", cursor: "pointer" }
                    }
                  >
                    <div className="col">Active</div>
                    <div className="col">WSH45</div>
                    <div className="col">CGST</div>
                    <div className="col">18%</div>
                  </li>
                ))}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

const AddTaxRateModal = ({ handleCloseModal, mode }) => {
  const [taxName, setTaxName] = useState("");
  const [taxRate, setTaxRate] = useState("");
  return (
    <>
      <div
        className={styles.add_tax_rate}
        style={mode ? { backgroundColor: "#2c2e33", color: "#fff" } : {}}
      >
        <div className={styles.add_tax_rate_top}>
          <p style={mode ? { color: "#fff" } : {}}>ID: AIDI</p>
          <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
            <CrossIcon color={mode ? "#FFFFFF" : "#1B1D21"} />
          </div>
        </div>
        <div className={styles.add_tax_rate_form}>
          <div className={styles.add_tax_rate_form_element}>
            <label htmlFor="">
              Name<span style={{ color: "red" }}>*</span>
            </label>

            <input
              type="text"
              required
              value={taxName}
              placeholder="Enter Tax Name"
              onChange={(e) => setTaxName(e.target.value)}
              maxLength={10}
              style={
                mode
                  ? {
                      backgroundColor: "#1B1D21",
                      border: "1px solid #646B88",
                      color: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>
          <div className={styles.add_tax_rate_form_element}>
            <label htmlFor="">Value</label>
            <input
              type="number"
              required
              value={taxRate}
              placeholder="Enter Tax Rate in %"
              onChange={(e) => setTaxRate(e.target.value)}
              maxLength={3}
              style={
                mode
                  ? {
                      backgroundColor: "#1B1D21",
                      border: "1px solid #646B88",
                      color: "#FFFFFF",
                    }
                  : {}
              }
            />
          </div>
          <p>{taxName && taxName + " " + taxRate + "%"}</p>
          <div>
            <Button
              buttonIcon={<NewManagerCancelIcon />}
              buttonText={"CANCEL"}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={handleCloseModal}
            />
            <Button
              buttonIcon={<SaveTaxRateIcon />}
              buttonText={"SAVE"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxRate;
