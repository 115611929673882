// ErrorBoundary.js
import React, { Component } from 'react';
import { useAuth  } from '../../context/Auth';

class ErrorBoundary extends Component {
  static contextType = useAuth ;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    const { mode } = this.context; // Access context value

    if (hasError) {
      // Fallback UI
      return <div style={{}}>
        <h1>Something went wrong.</h1>;
      </div>
    }

    return children;
  }
}

export default ErrorBoundary;