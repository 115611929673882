import React from 'react'
import styles from "../../../pages/template/Templates.module.css"
import { useAuth } from '../../context/Auth'
const TopTabBar = ({ text }) => {
    const { mode } = useAuth();
    return (
        <div className={styles.navigate_Sub_menu} >
            <span className={styles.navigate_span1} > {"<"}</span>
            <span className={styles.navigate_span2} >Go back </span>
            <span className={styles.navigate_span3} style={mode ? { backgroundColor: "#fff" } : {}} ></span>
            <span className={styles.navigate_span4} style={mode ? { color: "#fff" } : {}}>{text}</span>
        </div>
    )
}

export default TopTabBar