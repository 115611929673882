import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GreenButton, GreenOutButton, HelpButton, RedOutButton, WhiteOutButton } from '../../components/Buttons';
import { useAuth } from '../../components/context/Auth';
import { CheckGreenIcon, CopyColorIcon, CrossCircleIcon, DeleteIcon, DemoImageIcon, EmailColorIcon, MessageColorIcon, Search, Spinner, UpLoadFile, WhatsAppIcon, WhatsappIcon, } from '../../components/icons/Icon';
import Modal from '../../components/Modal';
import PaymentModal from '../../components/PaymentModal';
import VouchersTab from '../../components/VouchersTab';
import DealerSearch from './DealerSearch';
import LiveSearch from './LiveSearch';
import styles from './Order.module.css'
import Alert from '../../components/Alert';

const SaleOrder = () => {
    const { managerId } = useAuth()
    const formRef = useRef(null);
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const orderId = searchParams.get('orderId') ? searchParams.get('orderId') : ""
    const method = searchParams.get('method') ? searchParams.get('method') : ""
    const [sendPaymnetLinkNumber, setSendPaymnetLinkNumber] = useState('')
    const [results, setResults] = useState()
    const [searchItem, setSearchItem] = useState("")
    const [addDealer, setAddDealer] = useState(false)
    const [showCODModal, setShowCODModal] = useState(false)
    const [shippingInfo, setShippingInfo] = useState("")
    const [generalInfo, setGeneralInfo] = useState("")
    const [showAddItem, setShowAddItem] = useState(false)
    const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
    const [showRequestForEdit, setShowRequestForEdit] = useState(false)
    const [showRequestForCancel, setShowRequestForCancel] = useState(false)
    const [reqIssue, setReqIssue] = useState('')
    const [editKey, setEditKey] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)
    const [loadingReceipt, setLoadingReceipt] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [reload, setReload] = useState(false)
    const [reciptsInfo, setReciptsInfo] = useState([])
    const [accounts, setAccounts] = useState([])
    const [nextBtn, setNextBtn] = useState(false)
    const [methodPartners, setMethodPartners] = useState([])
    // const [methodChange, setMethodChange] = useState(false)
    const [reciptData, setReciptData] = useState({
        paymentMethod: 'Cash',
        paymentType: '',
        paymentAmount: '',
        transactionId: '',
        accountNumber: '',
    })
    const [paymentLink, setPaymentLink] = useState('');
    const [helpData, setHelpData] = useState("");
    const itemFocus = useRef()
    const qtyFocus = useRef()
    const uClipRef = useRef()
    // const lClipRef = useRef()
    const [itemsdetails, setItemsdetails] = useState([
        {
            accessories: [],
            itemNo: "",
            product_name: "",
            collection_name: "",
            warehouse: "",
            cartType: "",
            qty: "",
            dp: "",
            unit: "",
            amount: "",
            totalQty: "",
            desc: "",
            descKey: ""
        }
    ]);

    const [error] = useState({
        dp: "",
        index: ""
    })

    // const [error, setErrors] = useState({
    //     dp: "",
    //     index: ""
    // })

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...itemsdetails];
        list[index][name] = value;
        setItemsdetails(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...itemsdetails];
        list.splice(index, 1);
        setItemsdetails(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        if (error.dp) {
            alert(error.dp)
        } else {
            setSearchItem("");
            setItemsdetails([...itemsdetails, { itemNo: "", product_name: "", collection_name: "", qty: "", price: "", unit: "", amount: "" }]);
        }
    };

    useEffect(() => {
        if (!searchItem) {
            setResults([])
        }
        if (searchItem) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "keyword": searchItem,
                // "cartType": generalInfo.cartType,
                // "warehousename": wareHouseWise,
                "orderType": generalInfo.orderType,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/searchitems?page=", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setResults(result.data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [searchItem, managerId, generalInfo.orderType])

    function enforce_maxlength(e) {
        var t = e.target;
        if (t.hasAttribute('maxlength')) {
            t.value = t.value.slice(0, t.getAttribute('maxlength'));
        }
    }

    // const unitRef = useRef()
    const priceRef = useRef()
    const descRef = useRef()

    // const searchItems = results && results.filter(item => !itemsdetails.some(obj => item.itemNo === obj.itemNo && item.warehouse === obj.warehouse))
    // const billingAddress = generalInfo.street && generalInfo.street + " " + generalInfo.city && generalInfo.street + " " + generalInfo.city + " " +
    //     generalInfo.state && generalInfo.street + " " + generalInfo.city + " " + generalInfo.state + " " + generalInfo.pincode &&
    //     generalInfo.street + " " + generalInfo.city + " " + generalInfo.state + " " + generalInfo.pincode
    const [warehouses, setWarehouses] = useState([])
    const [dispatchManagers, setDispatchManagers] = useState([])
    useEffect(() => {
        if (!orderId) {
            setGeneralInfo("")
        }
    }, [orderId])

    useEffect(() => {
        if (managerId) {
            const fetchOrder = async () => {
                setLoading(true)
                var myHeaders = new Headers();
                myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "managerId": managerId,
                    "orderId": orderId,
                    "warehousename": ""
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                await fetch(process.env.REACT_APP_URL + "order/order", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status === "success") {
                            if (result.data.order) {
                                setGeneralInfo(prevState => ({
                                    ...prevState,
                                    dealerId: result.data.order.dealerId,
                                    storeName: result.data.order.storeName,
                                    dealerName: result.data.order.name,
                                    billingAddress: result.data.order.billingAddress,

                                    shippingType: result.data.order.shippingInfo.shippingType,
                                    shippingCharge: result.data.order.shippingInfo.shippingCharge,
                                    courierName: result.data.order.shippingInfo.courierName,
                                    partnerName: result.data.order.shippingInfo.methodName ? result.data.order.shippingInfo.methodName : result.data.order.shippingInfo.partnerName,
                                    deliveryPartner: result.data.order.shippingInfo.methodName ? result.data.order.shippingInfo.methodName : result.data.order.shippingInfo.partnerName,
                                    whatsapp: result.data.order.DealerInfo.whatsapp ? result.data.order.DealerInfo.whatsapp : result.data.order.shippingInfo.mobile,

                                    warehousename: result.data.order.warehousename ? result.data.order.warehousename : "",
                                    dmId: result.data.order.dmId,
                                    dmName: result.data.order.dmName,
                                    cartType: result.data.order.cartType === "Cart" ? 'ocart' : 'pcart',
                                    orderDate: result.data.order.orderDate,
                                    orderId: result.data.order.orderId,

                                    transactionScreenshot: result.data.order.transactionScreenshot,
                                    paymentMethod: result.data.order.paymentMethod,
                                    paymentType: result.data.order.paymentType,
                                    paymentStatus: result.data.order.paymentStatus,
                                    paymentAmount: result.data.order.paymentAmount,
                                    transferTransId: result.data.order.transferTransId,

                                    status: result.data.order.status,
                                    manageName: result.data.order.manageName,
                                    manageValue: result.data.order.manageValue,
                                    orderType: result.data.order.orderType,
                                    toPay: result.data.order.toPay,
                                    dispatchStatus: result.data.order.dispatchStatus,
                                    reqStatus: result.data.order.reqStatus,
                                    reqType: result.data.order.reqType,
                                }))
                                setShippingInfo(result.data.order.shippingInfo)
                                setItemsdetails(result.data.order.items)
                                setReciptsInfo(result.data.receipts)
                                setHelpData(prevState => ({
                                    ...prevState,
                                    reqType: result.data.order.reqType,
                                    reqIssue: result.data.order.reqIssue,
                                    reqStatus: result.data.order.reqStatus,
                                    reqHelpNumber: result.data.order.reqHelpNumber,
                                }))
                            }
                        }
                    })
                    .catch(error => console.log('error', error))
                    .finally(() => setLoading(false))
            }
            fetchOrder()
        }

    }, [orderId, managerId, reload])

    useEffect(() => {
        if (managerId) {
            // setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "warehousename": generalInfo.warehousename
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/order", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.data.warehouses) {
                            setWarehouses(result.data.warehouses)
                        }
                        if (result.data.dispatchmanagers.length > 0) {
                            setDispatchManagers(result.data.dispatchmanagers)
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                dmId: result.data.dispatchmanagers[0].managerId
                            }))
                        }
                        setAccounts(result.data.accounts)
                    }
                })
                .catch(error => console.log('error', error))
            // .finally(() => setLoading(false))
        }

    }, [orderId, managerId, generalInfo.warehousename,])

    useEffect(() => {
        if ((generalInfo.dispatchStatus === '' || generalInfo.dispatchStatus === null || generalInfo.dispatchStatus === undefined) && shippingInfo.pincode) {
            setLoadingLocation(true)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "keyword": shippingInfo.pincode,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setShippingInfo(prevState => ({
                            ...prevState,
                            city: result.SubDistName[0] ? result.SubDistName[0].toUpperCase() : "",
                            district: result.districts[0] ? result.districts[0] : "",
                            state: result.states[0] ? result.states[0] : "",
                        }))
                        setLoadingLocation(false)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [shippingInfo.pincode, generalInfo.dispatchStatus])

    // const [couriers, setCouriers] = useState([])

    const orderSubMethod = (selectedValue) => {
        // alert(selectedValue)
        if (shippingInfo.pincode && selectedValue && selectedValue !== 'Self') {
            // setMethodChange(false)
            setMethodPartners([])
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "courierId": '',
                "courierName": '',
                "shipPincode": shippingInfo.pincode,
                "warehousename": generalInfo.warehousename,
                "methodName": selectedValue,
                "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
                "selectedItems": itemsdetails
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.methodSelection === 'Active') {
                            setMethodPartners(result.deliveryMethod)
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                                courierName: result.courierName ? result.courierName : '',
                                deliveryPartner: result.data.methodName ? result.data.methodName : '',
                                deliveryCharge: result.data.deliveryCharge,
                            }))
                        }
                        else if (result.methodSelection === "Disable") {
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                shippingType: "Standard",
                                cartType: "ocart",
                                courierName: result.data.courierName,
                                partnerName: result.data.methodName,
                                deliveryCharge: result.data.deliveryCharge,
                                deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                            }))
                        }
                        setNextBtn(true)
                        setLoadingBtn(false)
                    } else {
                        setMethodPartners(result.deliveryMethod)
                        setGeneralInfo(prevState => ({
                            ...prevState,
                            methodSelection: result.methodSelection ? result.methodSelection : '',
                            courierName: result.courierName ? result.courierName : '',
                            deliveryPartner: '',
                            deliveryCharge: '',
                        }))
                        alert(result.msg)
                        // setMethodChange(true)
                    }
                })
                .catch(error => console.log('error', error))
                .finally(() => setLoadingBtn(false))
        }
    }

    // const deliveryMethodClear = () => {
    //     // setMethodChange(false)
    //     setGeneralInfo(prevState => ({
    //         ...prevState,
    //         // courierName: '',
    //         deliveryPartner: '',
    //         partnerName: '',
    //     }))

    //     var myHeaders = new Headers();
    //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "courierId": '',
    //         "courierName": '',
    //         "shipPincode": shippingInfo.pincode,
    //         "warehousename": generalInfo.warehousename,
    //         "methodName": '',
    //         "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
    //         "selectedItems": itemsdetails
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === "success") {
    //                 if (result.methodSelection === 'Active') {
    //                     setMethodPartners(result.deliveryMethod)
    //                     setGeneralInfo(prevState => ({
    //                         ...prevState,
    //                         methodSelection: result.methodSelection ? result.methodSelection : '',
    //                         courierName: result.courierName ? result.courierName : '',
    //                         deliveryPartner: result.data.methodName ? result.data.methodName : '',
    //                         deliveryCharge: result.data.deliveryCharge,
    //                     }))
    //                 }
    //                 else if (result.methodSelection === "Disable") {
    //                     setGeneralInfo(prevState => ({
    //                         ...prevState,
    //                         shippingType: "Standard",
    //                         cartType: "ocart",
    //                         courierName: result.data.courierName,
    //                         partnerName: result.data.methodName,
    //                         deliveryCharge: result.data.deliveryCharge,
    //                         deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
    //                         methodSelection: result.methodSelection ? result.methodSelection : '',
    //                     }))
    //                 }
    //                 setNextBtn(true)
    //                 setLoadingBtn(false)
    //             } else {
    //                 setMethodPartners(result.deliveryMethod)
    //                 setGeneralInfo(prevState => ({
    //                     ...prevState,
    //                     methodSelection: result.methodSelection ? result.methodSelection : '',
    //                     courierName: result.courierName ? result.courierName : '',
    //                     deliveryPartner: '',
    //                     deliveryCharge: '',
    //                 }))
    //                 alert(result.msg)
    //                 // setMethodChange(true)
    //             }
    //         })
    //         .catch(error => console.log('error', error))
    //         .finally(() => setLoadingBtn(false))
    //     // }
    // }

    const orderDeliveryMethod = () => {
        if (generalInfo.warehousename.trim() === '') {
            alert('Please select a warehouse before next button.');
            return;
        }
        setLoadingBtn(true)
        // setMethodChange(false)
        if (shippingInfo.pincode) {
            setMethodPartners([])
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "courierId": '',
                "courierName": '',
                "shipPincode": shippingInfo.pincode,
                "warehousename": generalInfo.warehousename,
                "methodName": generalInfo.deliveryPartner,
                "streetAddress": shippingInfo.street + ", " + shippingInfo.city + ", " + shippingInfo.district + ", " + shippingInfo.state,
                "selectedItems": itemsdetails
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "deliverymethod/orderDeliveryMethod", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        if (result.methodSelection === 'Active') {
                            setMethodPartners(result.deliveryMethod)
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                                courierName: result.courierName ? result.courierName : '',
                                deliveryPartner: result.data.methodName ? result.data.methodName : '',
                                deliveryCharge: result.data.deliveryCharge,
                            }))
                        }
                        else if (result.methodSelection === "Disable") {
                            setGeneralInfo(prevState => ({
                                ...prevState,
                                shippingType: "Standard",
                                cartType: "ocart",
                                courierName: result.data.courierName,
                                partnerName: result.data.methodName,
                                deliveryCharge: result.data.deliveryCharge,
                                deliveryPartner: result.data.methodName ? result.data.methodName : 'Self',
                                methodSelection: result.methodSelection ? result.methodSelection : '',
                            }))
                        }
                        setNextBtn(true)
                        setLoadingBtn(false)
                    } else {
                        alert(result.msg)
                        // setMethodChange(true)
                    }
                })
                .catch(error => console.log('error', error))
                .finally(() => setLoadingBtn(false))
        }
    }

    const cartTypeRef = useRef(null)
    const itemseacrhRef = useRef(null)
    const paymentRef = useRef(null)

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleSaleOrder("success")
        }
    }

    // const qty = itemsdetails.map(obj => parseFloat(obj.qty)).reduce((a, b) => a + b)
    // const totalItems = itemsdetails.length
    let cartPriceSum = itemsdetails && itemsdetails.reduce((sum, currentItem) => {
        return sum + (parseFloat(currentItem.price ? currentItem.price : 0) * parseFloat(currentItem.qty ? currentItem.qty : 0));
    }, 0)

    var cartTotal = itemsdetails && itemsdetails.map(x => !x.accessories ? 0 : x.accessories.map(y => (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)
    let subtotal = (cartPriceSum ? cartPriceSum : 0) + (cartTotal ? cartTotal : 0)
    let gettotalAmount = subtotal + parseFloat((generalInfo.toPay ? 0 : (generalInfo.deliveryPartner === 'Self' ? 0 : generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0)))) + parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0)
    // let gettotalAmount = subtotal + parseFloat(generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.toPay ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0)))) + parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0)

    let totalAmount = Math.round(gettotalAmount)
    let roundedAmt = totalAmount ? (totalAmount - gettotalAmount).toFixed(2) : 0

    const handleSaleOrder = (status) => {
        if (error.dp) {
            alert(error.dp)
        }
        else if (totalAmount < 0) {
            alert("Total amount must be greater than zero")
        }
        // else if (status === "success" && (parseFloat(totalAmount).toFixed(2) !== parseFloat(generalInfo.paymentAmount).toFixed(2))) {
        //     alert("Payment amount is not matching with total amount")
        // }
        else {
            // setLoading(true)
            setLoadingBtn(true)
            // setLoading(false)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "warehousename": generalInfo.warehousename,
                "dmId": generalInfo.dmId,
                "cartType": generalInfo.cartType,
                "dealerId": generalInfo.dealerId,
                "name": shippingInfo.name,
                "street": shippingInfo.street,
                "mobile": shippingInfo.mobile,
                "pincode": shippingInfo.pincode,
                "city": shippingInfo.city,
                "district": shippingInfo.district,
                "state": shippingInfo.state,
                "shippingType": generalInfo.shippingType,
                "shippingCharge": generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge),
                // "shippingCharge": generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge),
                "toPay": generalInfo.toPay,
                "courierName": generalInfo.deliveryPartner === 'Self' ? 'Pick Up' : generalInfo.courierName,
                "methodName": generalInfo.courierName === 'Pick Up' ? 'Self' : (generalInfo.methodName ? generalInfo.methodName : generalInfo.deliveryPartner),
                "partnerName": generalInfo.deliveryPartner ? generalInfo.deliveryPartner : generalInfo.partnerName,
                "manageName": generalInfo.manageName,
                "manageValue": generalInfo.manageValue,
                "saleItems": itemsdetails,
                "status": status,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/saleOrder", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setReload(true)
                        alert(result.msg)
                        if (status === "success") {
                            if (!orderId) {
                                navigate("/orders/saleorder?orderId=" + result.data.orderId)
                                setShowPaymentModal(true);

                            }
                            // handleOfllinePayment({ orderId: result.data.orderId, status })
                        }
                        if (status === "draft") {
                            navigate("/orders")
                        }
                    } else {
                        alert(result.msg)
                    }
                })
                .catch(error => console.log('error', error))
                .finally(() => {
                    setLoading(false);
                    setLoadingBtn(false)
                })
        }
    }

    // const handleOfllinePayment = ({ orderId, status }) => {
    //     // if (parseFloat(totalAmount).toFixed(2) !== parseFloat(generalInfo.paymentAmount).toFixed(2)) {
    //     //     alert("Payment amount is not matching with total amount")
    //     // } else {
    //     var myHeaders = new Headers();
    //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    //     var formdata = new FormData();
    //     formdata.append("transactionScreenshot", generalInfo.transactionScreenshot);
    //     formdata.append("paymentMethod", generalInfo.paymentMethod);
    //     formdata.append("paymentType", generalInfo.paymentType);
    //     formdata.append("paymentStatus", generalInfo.paymentStatus);
    //     formdata.append("paymentAmount", generalInfo.paymentAmount);
    //     formdata.append("transferTransId", generalInfo.transferTransId);

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: formdata,
    //         redirect: 'follow'
    //     };

    //     fetch(process.env.REACT_APP_URL + "order/offlinePayment/" + orderId, requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === "success") {
    //                 if (status === "success") {
    //                     navigate("/orders/saleinvoice?orderId=" + orderId)
    //                 } else {
    //                     navigate("/orders")
    //                 }
    //             }
    //         })
    //         .catch(error => console.log('error', error));
    //     // }
    // }
    let typeStatus = !generalInfo.status ? true : generalInfo.status === "In Progress" ? true : generalInfo.status === "Draft" ? true : generalInfo.status === "Payment Pending" ? true : false

    const resultArray = [];

    itemsdetails.length > 0 && itemsdetails.forEach(item => {
        //for each item in arrayOfObjects check if the object exists in the resulting array
        if (resultArray.find(object => {
            if (object.warehouse === item.warehouse) {
                //if the object exists iterate times
                object.times++;
                return true;
                //if it does not return false
            } else {
                return false;
            }
        })) {
        } else {
            //if the object does not exists push it to the resulting array and set the times count to 1
            item.times = 1;
            resultArray.push(item);
        }
    })

    // useEffect(()=>{
    //     if(resultArray.length > 0){
    //         resultArray.forEach(obj=>{
    //             if(obj.times > obj.times){
    //                 console.log(obj.times)
    //             }
    //         })
    //     }
    // }, [resultArray])
    // const closest = resultArray.length > 0 ? resultArray.reduce(
    //     (acc, loc) =>
    //         acc.times > loc.times
    //             ? acc
    //             : loc
    // ) : []

    const closest =
        resultArray.length > 0
            ? resultArray.reduce((acc, loc) =>
                acc.times > loc.times ? acc : loc
            )
            : null;
    // You can use null or another appropriate default value

    useEffect(() => {
        setGeneralInfo(prevState => ({
            ...prevState,
            warehousename: closest.warehouse ? closest.warehouse : (itemsdetails && itemsdetails.length !== 0) && itemsdetails[0].warehouse
        }))
    }, [closest.warehouse, itemsdetails])

    const handleCancelOrder = () => {
        // setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "managerId": managerId,
            "orderId": orderId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/cancelOrder", requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.msg)
                if (result.status === 'success') {
                    setShowCancelOrderModal(false)
                    navigate("/orders")
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleRequestForEdit = () => {
        // setMsgShow("")
        if (reqIssue) {
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "reqType": "Request For Edit",
                "reqIssue": reqIssue,
                "editReqType": editKey
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(process.env.REACT_APP_URL + "order/orderhelp", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === 'success') {
                        setShowRequestForEdit(false)
                        alert(result.msg)
                        window.location.reload();
                    } else {
                        alert(result.msg)
                    }
                })
                .catch((error) => console.error(error));
        } else {
            alert("Please Enter Issue")
        }
    }

    const handleRequestForCancel = () => {
        // setMsgShow("")
        if (reqIssue) {
            const myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "managerId": managerId,
                "orderId": orderId,
                "reqType": "Request For Cancel",
                "reqIssue": reqIssue
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(process.env.REACT_APP_URL + "order/orderhelp", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === 'success') {
                        setShowRequestForCancel(false)
                        alert(result.msg)
                        window.location.reload();
                    } else {
                        alert(result.msg)
                    }
                })
                .catch((error) => console.error(error));
        } else {
            alert("Please Enter Issue")
        }
    }

    // const [ImgData, setImgData] = useState()
    const [isImage, setisImage] = useState();

    const handleRecipt = () => {
        setLoadingReceipt(true)
        setReload(false)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("transactionScreenshot", isImage ? [...isImage][0] : '');
        formdata.append("paymentMethod", reciptData.paymentMethod ? reciptData.paymentMethod : '');
        formdata.append("paymentType", reciptData.paymentType ? reciptData.paymentType : '');
        formdata.append("paymentAmount", reciptData.paymentAmount ? parseFloat(reciptData.paymentAmount).toFixed(2) : '');
        formdata.append("transactionId", reciptData.transactionId ? reciptData.transactionId : '');
        formdata.append("accountNumber", reciptData.accountNumber ? reciptData.accountNumber : '');
        formdata.append("totalAmount", totalAmount ? totalAmount.toFixed(2) : '');
        formdata.append("cartType", generalInfo.cartType);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/offlinePay/" + managerId + "/" + orderId + "?receiptId=" + reciptData.receiptId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setShowPaymentModal(false)
                    setReload(true)
                }
                alert(result.msg)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoadingReceipt(false))
    }
    let payStatus = !generalInfo.status ? false : generalInfo.status === "In Progress" ? false : generalInfo.status === "Cancelled" ? false : true
    // console.log('ImgData', payStatus)
    // console.log('paymentAmount', reciptData.paymentAmount);\

    useEffect(() => {
        if (totalAmount < 0) {
            alert("Total amount must be greater than zero")
            setGeneralInfo(prevState => ({
                ...prevState,
                manageValue: ""
            }))
        }
    }, [totalAmount])

    const [showPayment, setShowPayment] = useState(false)

    const [showElement, setShowElement] = useState(false)

    const handleSendLink = () => {
        setPaymentLink('')
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "orderId": orderId,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    setPaymentLink(result.shortUrl)
                    setShowPayment(true)
                }
            })
            .catch(error => console.log('error', error));
    }


    const sendLink = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": sendPaymnetLinkNumber,
            "templateName": "order_payment",
            "headerData": "https://dealer.wallicon.in/assets/paymentlink.jpg",
            "buttonDataPayload": paymentLink
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (result.messages[0].message_status === 'accepted') {
                    alert('Payment Link Sent Successfully')
                    setShowPayment(false)
                }
            })
            .catch((error) => console.error(error));
    }
    const sendPaymentLink = () => {
        const myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "managerId": managerId,
            "phoneNumber": generalInfo.whatsapp,
            "templateName": "order_payment",
            "headerData": "https://dealer.wallicon.in/assets/paymentlink.jpg",
            "buttonDataPayload": paymentLink
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (result.messages[0].message_status === 'accepted') {
                    alert('Payment Link Sent Successfully')
                    setShowPayment(false)
                }
            })
            .catch((error) => console.error(error));
    }

    const [warehouseName, setWarehouseName] = useState()

    const handleNextButtonClick = () => {
        console.log("handleNextButtonClick called");
        console.log("generalInfo.warehousename", generalInfo.warehousename);
        console.log("warehouseName", warehouseName);

        if (!generalInfo.warehousename) {
            if (formRef.current) {
                if (formRef.current.checkValidity()) {
                    // Proceed to the next step
                    if (generalInfo.deliveryPartner !== 'Self' && generalInfo.partnerName !== 'Self') {
                        console.log("Ordering delivery method1");
                        setGeneralInfo(prevState => ({
                            ...prevState,
                            warehousename: warehouseName
                        }))
                        orderDeliveryMethod();
                    } else {
                        console.log("Setting nextBtn to true");
                        setNextBtn(true);
                    }
                } else {
                    // If form is invalid, trigger form validation
                    console.log("Form is invalid");
                    formRef.current.reportValidity();
                }
            } else {
                console.log("formRef.current is null");
            }
        } else {
            if (generalInfo.deliveryPartner !== 'Self' && generalInfo.partnerName !== 'Self') {
                console.log("Ordering delivery method2");
                orderDeliveryMethod();
            } else {
                console.log("Setting nextBtn to true");
                setNextBtn(true);
            }
        }
    }

    return (
        <React.Fragment>

            <Alert
                showElement={showElement}
                setShowElement={() => setShowElement(false)}
                msg={'Copied'}
            />

            <Modal
                show={showPayment}
                close={setShowPayment}
                closeBtn={true}
                heading="Payment Link"
                content={
                    <div className={styles.text_center}>
                        <div className='d-flex justify-content-between w-75 mx-auto my-4 px-4'>
                            <span role='button' className={styles.link_icon}>
                                <span onClick={() => sendPaymentLink()}><WhatsAppIcon /></span>
                                {/* <span onClick={() => window.open(`https://wa.me/91${shippingInfo.mobile}?text=` + paymentLink, '_blank')}><WhatsAppIcon /></span> */}
                            </span>

                            <span role='button' className={styles.link_icon}><MessageColorIcon /></span>
                            <span role='button' className={styles.link_icon}><EmailColorIcon /> </span>
                            <span role='button' title='Copy command to clip board' onClick={() => { navigator.clipboard.writeText('https://dealer.wallicon.in/' + paymentLink); setShowElement(true) }} className={styles.link_icon}><CopyColorIcon /></span>
                        </div>

                        <div className='w-75 d-flex flex-row mx-auto mb-4'>
                            <input type='text' className={styles.paymentLink_input} value={'https://dealer.wallicon.in/' + paymentLink} />
                        </div>

                        <div className='position-relative w-75 d-flex justify-centent-between mx-auto mb-4'>
                            <div className={styles.country_plus}>&#43;</div>
                            <div className={styles.country_code}>
                                <select className={styles.country_code_select}>
                                    <option>91</option>
                                    <option>92</option>
                                </select>
                            </div>
                            <div className={styles.country_slash}>|</div>
                            <input type='number' className={styles.whatsapp_input} value={sendPaymnetLinkNumber} onChange={(e) => setSendPaymnetLinkNumber(e.target.value)} maxLength="10"
                                onInput={(e) => e.target.value = e.target.value.slice(0, 10)} />
                            <span className={sendPaymnetLinkNumber.length !== 10 ? styles.copyLinkBtn : styles.copyLink} onClick={() => { sendPaymnetLinkNumber.length === 10 && sendLink() }}><WhatsappIcon />Send Link</span>
                            {/* <span className={styles.copyLink} onClick={() => window.open(`https://wa.me/91${sendPaymnetLinkNumber}?text=` + paymentLink, '_blank')}><WhatsappIcon />Send Link</span> */}
                        </div>

                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowPayment(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowPayment(false) }} />
                        </div>
                    </div>
                }>
            </Modal>

            {addDealer && <AddDealerModal setGeneralInfo={setGeneralInfo} />}
            <AddItemModal
                showAddItem={showAddItem}
                setShowAddItem={setShowAddItem}
                setItemsdetails={setItemsdetails}
                index={itemsdetails.length - 1}
            />

            <div className={styles.main_div_dark}>
                <div className={styles.main_container}>

                    <div className={styles.tabs_div}>
                        <h2 className={styles.orderHeading}>Sale Order</h2>
                        <div className={styles.orderHeading + " d-flex"}>
                            <li>{generalInfo.reqStatus === "Pending" ? generalInfo.reqType : generalInfo.status}</li>
                            <li className='ms-3'>{generalInfo.orderType}</li>
                        </div>
                        <VouchersTab styles={styles} />
                    </div>

                    {
                        loading ?
                            <div className={'order_loadingMain'}>
                                <img src='/wallicon.gif' alt='walliconGIF' />
                            </div> :

                            <form onSubmit={handleSubmit} ref={formRef} className={styles.ordersScroll}>
                                <div className={styles.d_flex}>
                                    <div className={styles.orderContent_div}>
                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Company/Dealer Name</label>
                                            <DealerSearch
                                                name={generalInfo.storeName && generalInfo.dealerName && generalInfo.storeName + " / " + generalInfo.dealerName}
                                                setAddDealer={setAddDealer}
                                                setGeneralInfo={setGeneralInfo}
                                                onSelect={item => {
                                                    if (item.name === "Cash") {
                                                        setGeneralInfo(prevState => ({
                                                            ...prevState,
                                                            dealerId: item.dealerId,
                                                            dealerName: item.name,
                                                            storeName: item.storeName,
                                                            shippingType: "Standard",
                                                            courierName: "Pick Up",
                                                            partnerName: "Self",
                                                        }))
                                                        itemFocus.current.focus()
                                                    }
                                                    else {
                                                        setGeneralInfo(prevState => ({
                                                            ...prevState,
                                                            dealerId: item.dealerId,
                                                            dealerName: item.name,
                                                            storeName: item.storeName,
                                                            billingAddress: (item.street || item.city || item.district || item.state || item.pincode) && item.street + ' ' + item.city + ' ' + item.district + ' ' + item.state + ' ' + item.pincode
                                                            // item.street && item.street + " " + item.city && item.city + " " + item.city + " " +
                                                            //     item.state && item.street + " " + item.city + " " + item.state + " " + item.pincode &&
                                                            //     item.street + " " + item.city + " " + item.state + " " + item.pincode,
                                                        }))
                                                        setShippingInfo(prevState => ({
                                                            ...prevState, mobile: item.mobile,
                                                            name: item.storeName,
                                                            pincode: item.pincode,
                                                            street: item.street,
                                                            city: item.city,
                                                            district: item.district,
                                                            state: item.state,
                                                        }))
                                                        itemFocus.current.focus()
                                                    }
                                                }}
                                            />
                                            <span className={styles.search_icon}><Search color="#FFFFFF" /></span>
                                        </div>
                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Billing Address</label>
                                            <textarea
                                                type="text"
                                                placeholder='Enter Billing Address'
                                                className={styles.inputField_textArea}
                                                value={generalInfo.billingAddress}
                                                required
                                                readOnly
                                            />
                                        </div>

                                        <div className={styles.orderInput_div}>
                                            <label className={styles.devliveryAddress}>Delivery Address</label>
                                        </div>
                                        <div className={styles.orderInput_div}>
                                            <input
                                                type="text"
                                                placeholder='Enter Name'
                                                className={styles.inputField}
                                                value={shippingInfo.name}
                                                onChange={(e) => setShippingInfo(prevState => ({
                                                    ...prevState,
                                                    name: e.target.value
                                                }))}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={generalInfo.dealerName === "Cash" ? false : true}
                                            />
                                            <label className={styles.lable91}>+91</label>
                                            <input
                                                type="text"
                                                placeholder='Enter Mobile'
                                                className={styles.inputField + " " + styles.lable91input}
                                                value={shippingInfo.mobile}
                                                onChange={(e) => setShippingInfo(prevState => ({
                                                    ...prevState,
                                                    mobile: e.target.value
                                                }))}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                                required={generalInfo.dealerName === "Cash" ? false : true}
                                            />
                                        </div>

                                        <div className={styles.orderInput_div}>
                                            <input
                                                type="text"
                                                placeholder='Enter Street'
                                                className={styles.inputField + " " + styles.w100}
                                                value={shippingInfo.street}
                                                onChange={(e) => setShippingInfo(prevState => ({
                                                    ...prevState,
                                                    street: e.target.value
                                                }))}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={generalInfo.dealerName === "Cash" ? false : true}
                                            />
                                        </div>
                                        <div className={styles.orderInput_div}>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                placeholder='Enter Pincode'
                                                className={styles.inputField}
                                                value={shippingInfo.pincode}
                                                onChange={(e) => setShippingInfo(prevState => ({
                                                    ...prevState,
                                                    pincode: e.target.value
                                                }))}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={generalInfo.dealerName === "Cash" ? false : true}
                                            />
                                            <input
                                                type="text"
                                                placeholder='Enter City'
                                                className={styles.inputField + " " + styles.mx_10}
                                                value={loadingLocation ? 'loading...' : shippingInfo.city}
                                                onChange={(e) => setShippingInfo(prevState => ({
                                                    ...prevState,
                                                    city: e.target.value
                                                }))}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={generalInfo.dealerName === "Cash" ? false : true}
                                            />
                                            <input
                                                type="text"
                                                placeholder='Enter District'
                                                className={styles.inputField + ' me-2'}
                                                value={loadingLocation ? 'loading...' : shippingInfo.district}
                                                onChange={(e) => setShippingInfo(prevState => ({
                                                    ...prevState,
                                                    district: e.target.value
                                                }))}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            // required={generalInfo.dealerName === "Cash" ? false : true}
                                            />
                                            <input
                                                type="text"
                                                placeholder='Enter State'
                                                className={styles.inputField}
                                                value={loadingLocation ? 'loading...' : shippingInfo.state}
                                                onChange={(e) => setShippingInfo(prevState => ({
                                                    ...prevState,
                                                    state: e.target.value
                                                }))}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                required={generalInfo.dealerName === "Cash" ? false : true}
                                            />
                                        </div>


                                    </div>
                                    <div className={styles.orderContent_div}>
                                        <div className={styles.orderInput_div}>
                                            {/* <label className={styles.order_txt}>Voucher No.</label> */}
                                            <div className={styles.voucherDate_div + ' w-100'}>
                                                <span>Voucher No.</span>
                                                <span><b>{generalInfo.orderId}</b></span>
                                                <span>Voucher Date</span>
                                                <span><b>{generalInfo.orderDate}</b></span>
                                                <span>Source</span>
                                                <span><b>{generalInfo.orderType}</b></span>
                                            </div>
                                        </div>

                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Dispatch Warehouse</label>
                                            <select
                                                className={styles.whereHouse_select_box}
                                                value={generalInfo.warehousename}
                                                onChange={(e) => {
                                                    setGeneralInfo(prevState => ({
                                                        ...prevState,
                                                        warehousename: e.target.value
                                                    })); setWarehouseName(e.target.value)
                                                }}
                                                required
                                            >
                                                <option value="">All Warehouse</option>
                                                {warehouses && warehouses.map((item, index) => (
                                                    <option key={index} value={item.warehousename}>{item.warehousename}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Dispatch Manager</label>
                                            <select
                                                className={styles.whereHouse_select_box}
                                                value={generalInfo.dmId}
                                                onChange={(e) => setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    dmId: e.target.value
                                                }))}
                                                required
                                            >
                                                <option value="" >Select Dispatch Manager</option>
                                                {dispatchManagers && dispatchManagers.map((item, index) => (
                                                    <option key={index} value={item.managerId}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Delivery Type</label>
                                            <select
                                                className={styles.whereHouse_select_box}
                                                value={generalInfo.shippingType}
                                                onChange={(e) => setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    shippingType: e.target.value
                                                }))}
                                                required
                                            >
                                                <option value="" hidden>Choose Delivery Type</option>
                                                <option>Standard</option>
                                                <option>Express</option>
                                            </select>
                                        </div>
                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Delivery Method</label>
                                            <input type="text"
                                                className={styles.whereHouse_select_box}
                                                value={generalInfo.deliveryPartner === 'Self' ? 'Pick Up' : generalInfo.courierName}
                                                disabled
                                                placeholder='Delivery Method Name'
                                            />
                                            {/* <select
                                                className={styles.whereHouse_select_box}
                                                value={generalInfo.deliveryPartner === 'Self' ? 'Pick Up' : generalInfo.courierName}
                                                onChange={(e) => setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    courierName: e.target.value,
                                                    deliveryPartner: '',
                                                }))}
                                                required
                                            >
                                                {generalInfo.deliveryPartner === 'Self' ?
                                                    <>
                                                        <option value='Pick Up'>Pick Up</option>
                                                        {couriers.length > 0 && couriers.map((obj, index) =>
                                                            <option key={index} value={obj.courierName}>{obj.courierName}</option>
                                                        )}
                                                    </>
                                                    :
                                                    <>
                                                        <option value="" hidden>Choose Delivery Method</option>
                                                        {
                                                            couriers.length > 0 ? couriers.map((obj, index) =>
                                                                <option key={index} value={obj.courierName}>{obj.courierName}</option>
                                                            )
                                                                :
                                                                <option value={generalInfo.courierName}>{generalInfo.courierName}</option>
                                                        }
                                                    </>
                                                }
                                            </select> */}
                                        </div>
                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Sub-Method</label>
                                            <select
                                                className={styles.whereHouse_select_box}
                                                value={generalInfo.deliveryPartner}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setGeneralInfo(prevState => ({
                                                        ...prevState,
                                                        deliveryPartner: selectedValue
                                                    }));

                                                    // Check if the selected value is what you need to trigger orderSubMethod
                                                    if (selectedValue) {
                                                        orderSubMethod(selectedValue);
                                                    }
                                                }}
                                                // onClick={() => {
                                                //     (orderId && generalInfo.methodSelection +== 'Disable') &&
                                                //         deliveryMethodClear()
                                                // }}   
                                                required
                                            >
                                                <option value="" hidden>Choose Sub-Method</option>
                                                <option value='Self'>Self</option>
                                                {methodPartners && methodPartners.length !== 0 ? methodPartners.map((obj, index) =>
                                                    <option key={index} value={obj.partnerName || obj.methodName}>{obj.partnerName || obj.methodName}</option>
                                                )
                                                    :
                                                    <>
                                                        <option value={generalInfo.partnerName}>{generalInfo.partnerName}</option>
                                                        {/* <option value='Self'>Self</option> */}
                                                    </>
                                                }
                                            </select>
                                        </div>
                                        <div className={styles.orderInput_div}>
                                            <label className={styles.order_txt}>Product Type</label>
                                            <select className={styles.whereHouse_select_box}
                                                value={generalInfo.cartType}
                                                onChange={(e) => {
                                                    setGeneralInfo(prevState => ({
                                                        ...prevState,
                                                        cartType: e.target.value
                                                    }));
                                                    itemseacrhRef.current.focus()
                                                }}
                                                // required
                                                ref={cartTypeRef}
                                                disabled={itemsdetails[0].itemNo && itemsdetails.length >= 0 && generalInfo.cartType ? true : false}
                                            >
                                                <option value="" hidden>Choose Product Type</option>
                                                <option value="ocart">Cart</option>
                                                <option value="pcart">Partner Cart</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>

                                <table className={styles.table_main} >
                                    <thead>
                                        <tr>
                                            <th className={styles.table_th_Sno}>S. No</th>
                                            <th className={styles.table_th}>
                                                <div className={styles.orderInput_div}>
                                                    <div className={styles.order_txt}>Item Description</div>
                                                    <div className={styles.product_warehouse_txt}>
                                                        <div className={styles.product_txt}>Product</div>
                                                        <div className={styles.item_warehouse_txt}>Warehouse</div>
                                                    </div>
                                                    {/* <select
                                                        className={styles.whereHouse_select_box}
                                                        value={wareHouseWise}
                                                        onChange={(e) => setWareHouseWise(e.target.value)}
                                                    >
                                                        <option value="">All Warehouse</option>
                                                        {warehouses && warehouses.map((item, index) => (
                                                            <option key={index} value={item.warehousename}>{item.warehousename}</option>
                                                        ))}
                                                    </select> */}
                                                </div>
                                            </th>
                                            <th className={styles.table_th_details}>Qty </th>
                                            <th className={styles.table_th_details}>Per</th>
                                            <th className={styles.table_th_details}>Rate</th>
                                            <th className={styles.table_th_details}>Amount</th>
                                        </tr>
                                    </thead>
                                    {itemsdetails && itemsdetails.map((x, index) =>
                                        <tbody key={index}>

                                            <tr onKeyDown={(e) => {
                                                // console.log(e.keyCode)
                                                if (e.ctrlKey && e.keyCode === 88) {
                                                    handleRemoveClick(index + 1);
                                                    paymentRef.current.focus()
                                                }
                                            }}>
                                                <td className={styles.table_th_Sno}>{index + 1}</td>
                                                <td className={styles.table_th + " " + styles.w_50}>
                                                    <div className={styles.table_th_div1}>
                                                        <LiveSearch
                                                            results={results}
                                                            itemsdetails={itemsdetails}
                                                            cartType={generalInfo.cartType}
                                                            value={x.itemNo ? x.itemNo : searchItem}
                                                            renderItem={item => <>{item.name}</>}
                                                            onChange={(e) => setSearchItem(e.target.value)}
                                                            setSearchItem={setSearchItem}
                                                            itemseacrhRef={itemseacrhRef}
                                                            cartTypeRef={cartTypeRef}
                                                            handleRemoveClick={() => handleRemoveClick(index + 1)}
                                                            removeIndex={index + 1}
                                                            setShowAddItem={setShowAddItem}
                                                            itemFocus={itemFocus}
                                                            onSelect={item => {
                                                                if (!generalInfo.cartType) {
                                                                    setGeneralInfo(prevState => ({ ...prevState, cartType: item.cartType, shippingType: "Standard", }))
                                                                    setItemsdetails([
                                                                        ...itemsdetails.slice(0, index),
                                                                        item,
                                                                        ...itemsdetails.slice(index + 1)
                                                                    ])

                                                                    qtyFocus.current.focus()
                                                                }
                                                                // if (generalInfo.cartType &&(generalInfo.cartType !== item.cartType)) {
                                                                //     alert("You cann't select anthor product type")
                                                                // }
                                                                else {
                                                                    setItemsdetails([
                                                                        ...itemsdetails.slice(0, index),
                                                                        item,
                                                                        ...itemsdetails.slice(index + 1)
                                                                    ])
                                                                    qtyFocus.current.focus()
                                                                }

                                                                // if (item.accessories.length > 0) {
                                                                //     setItemsdetails([
                                                                //         ...itemsdetails.slice(0, index),
                                                                //         {
                                                                //             itemNo: item.itemNo,
                                                                //             product_name: item.product_name,
                                                                //             qty: "",
                                                                //             unit: item.unit,
                                                                //             price: item.price,
                                                                //         },
                                                                //         ...itemsdetails.slice(index + 1),

                                                                //         ...item.accessories.map((xj, ix) =>
                                                                //         ({
                                                                //             itemNo: xj.accessoriesName,
                                                                //             qty: parseFloat(xj.accessoriesQty),
                                                                //             price: parseFloat(xj.accessoriesPrice),
                                                                //         })
                                                                //         )
                                                                //     ],

                                                                //     )
                                                                //     // setItemsdetails(
                                                                //     //     ...itemsdetails.slice(0, index),

                                                                //     //     item.accessories.map((xj, ix) =>

                                                                //     //     ({
                                                                //     //         itemNo: xj.accessoriesName,
                                                                //     //         qty: parseFloat(xj.accessoriesQty),
                                                                //     //         price: parseFloat(xj.accessoriesPrice),
                                                                //     //     })
                                                                //     //     ),
                                                                //     //     ...itemsdetails.slice(index + 2)
                                                                //     // )
                                                                // }
                                                                // // else {
                                                                // //     itemsArr.push({
                                                                // //         itemNo: item.itemNo,
                                                                // //         product_name: item.product_name,
                                                                // //         qty: item.qty,
                                                                // //         unit: item.unit,
                                                                // //         price: item.price,
                                                                // //     })
                                                                // // }



                                                            }}
                                                        />

                                                        <div className={styles.productandwarehouse_name}>
                                                            <span className={styles.product_name}>{x.product_name}</span>
                                                            <span className={styles.ms_5}>{x.warehouse}</span>
                                                        </div>
                                                    </div>
                                                    {x.accessories && x.accessories.length > 0 && x.accessories.map((item, idx) =>
                                                        <div key={idx} className={styles.table_th_div1}>
                                                            <input
                                                                type="text"
                                                                defaultValue={item.accessoriesName}
                                                                readOnly
                                                                className={styles.acc_div_input}
                                                            />
                                                            <input
                                                                type="number"
                                                                defaultValue={item.accessoriesPrice}
                                                                readOnly
                                                                className={styles.acc_div_input}
                                                            />
                                                            <input
                                                                ref={uClipRef}
                                                                type="number"
                                                                placeholder='Enter Qty'
                                                                onWheel={(e) => e.target.blur()}
                                                                className={styles.acc_div_input}
                                                                value={item.accessoriesQty}
                                                                onChange={e => {
                                                                    setItemsdetails([...itemsdetails.slice(0, index),
                                                                    {
                                                                        ...itemsdetails[index],
                                                                        accessories: [...itemsdetails[index].accessories.slice(0, idx),
                                                                        {
                                                                            ...itemsdetails[index].accessories[idx],
                                                                            accessoriesQty: e.target.value,
                                                                        },
                                                                        ...itemsdetails[index].accessories.slice(idx + 1)]
                                                                    },
                                                                    ...itemsdetails.slice(index + 1)]);
                                                                }}
                                                                onClick={e => {
                                                                    setItemsdetails([...itemsdetails.slice(0, index),
                                                                    {
                                                                        ...itemsdetails[index],
                                                                        accessories: [...itemsdetails[index].accessories.slice(0, idx),
                                                                        {
                                                                            ...itemsdetails[index].accessories[idx],
                                                                            accessoriesQty: (e.target.value > 0 ? e.target.value : ''),
                                                                        },
                                                                        ...itemsdetails[index].accessories.slice(idx + 1)]
                                                                    },
                                                                    ...itemsdetails.slice(index + 1)]);
                                                                }}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault()
                                                                        const list = [...itemsdetails];
                                                                        list[index]["descKey"] = index;
                                                                        setItemsdetails(list);
                                                                        descRef.current.focus()
                                                                    }
                                                                }}
                                                            />
                                                            <input
                                                                type="number"
                                                                readOnly
                                                                className={styles.acc_div_input}
                                                                value={item.accessoriesPrice * parseFloat(item.accessoriesQty)}
                                                            />
                                                        </div>
                                                    )}
                                                    {/* <div className={styles.item_div}>
                                                    <span>U-Clip</span>
                                                    <input
                                                        type="number"
                                                        defaultValue={20}
                                                        disabled
                                                    />
                                                    <input
                                                        type="number"
                                                        placeholder='Enter Qty'
                                                    />
                                                    <input
                                                        type="number"

                                                        disabled
                                                    />
                                                </div> 
                                            */}
                                                </td>
                                                <td className={styles.table_th_details} >

                                                    <input
                                                        type="number"
                                                        ref={qtyFocus}
                                                        placeholder="Enter Qty"
                                                        name='qty'
                                                        value={x.qty}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={e => {
                                                            if (!x.cartType) {
                                                                enforce_maxlength(e, index);
                                                                handleInputChange(e, index);
                                                                const list = [...itemsdetails];
                                                                list[index]["amount"] = x.qty * x.price;
                                                                setItemsdetails(list);
                                                            }
                                                            else if (!e.target.value || (parseFloat(e.target.value) <= parseFloat(x.totalQty))) {
                                                                enforce_maxlength(e, index);
                                                                handleInputChange(e, index);
                                                                const list = [...itemsdetails];
                                                                list[index]["amount"] = x.qty * x.price;
                                                                setItemsdetails(list);
                                                            } else {
                                                                alert("Please Enter Valid Quantity");
                                                            }
                                                            setGeneralInfo(prevState => ({
                                                                ...prevState,
                                                                deliveryPartner: "",
                                                            }))
                                                        }}
                                                        onClick={() => setNextBtn(false)}

                                                        // onKeyPress={(e) => { e.key === 'Enter' return (unitRef.current.focus() && e.preventDefault()) }}
                                                        onKeyPress={(e) => {

                                                            if (e.key === 'Enter') {
                                                                e.preventDefault()
                                                                const list = [...itemsdetails];
                                                                list[index]["descKey"] = index;
                                                                setItemsdetails(list);
                                                                descRef.current.focus()
                                                            }
                                                        }}
                                                        onInput={(e) => {
                                                            if ((x.unit === 'Roll' || x.unit === 'Pcs' || x.unit === 'Box') && x.totalQty) {
                                                                e.target.value = e.target.value.slice(0, x.totalQty.length)
                                                            }
                                                            //  else if (x.unit === 'Roll' || x.unit === 'Pcs' || x.unit === 'Box') {
                                                            //     e.target.value = e.target.value.slice(x.qty)
                                                            // }
                                                        }}
                                                        required
                                                        className={styles.inputFocus}
                                                    />
                                                </td>
                                                <td className={styles.table_th_details}>
                                                    <input
                                                        type="text"
                                                        value={x.unit}
                                                        onWheel={(e) => e.target.blur()}
                                                        name="per"
                                                        disabled
                                                        required
                                                    />
                                                    {/* <select
                                                        name='unit'
                                                        value={x.unit}
                                                        onChange={e => { handleInputChange(e, index); }}
                                                        ref={unitRef}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault()
                                                                priceRef.current.focus()
                                                            }
                                                        }}
                                                        required
                                                        className={styles.inputFocus}
                                                    >
                                                        <option value="" hidden>Select</option>
                                                        <option value="Roll">Roll</option>
                                                        <option value="Pcs">Pcs</option>
                                                        <option value="Box">Box</option>
                                                        <option value="Sq Meter">Sq Meter</option>
                                                        <option value="Meter">Meter</option>
                                                        <option value="Sq Feet">Sq Feet</option>
                                                        <option value="Feet">Feet</option>
                                                        <option value="Kg">Kg</option>
                                                    </select> */}
                                                </td>
                                                <td className={styles.table_th_details} >
                                                    <input
                                                        type="number"
                                                        value={x.price}
                                                        onWheel={(e) => e.target.blur()}
                                                        // readOnly={x.price ? true : false}
                                                        name="price"
                                                        // onChange={e => {
                                                        //     if (x.dp === parseFloat(e.target.value ? e.target.value : 0)) {
                                                        //         setErrors(prevState => ({ ...prevState, dp: "Please Enter Price Above " + x.dp, index: index }))
                                                        //     } else {
                                                        //         setErrors(prevState => ({ ...prevState, dp: "", index: "" }))
                                                        //     }

                                                        //     handleInputChange(e, index);
                                                        // }}
                                                        ref={priceRef}
                                                        // onKeyPress={(e) => {

                                                        //     if (e.key === 'Enter') {
                                                        //         e.preventDefault()
                                                        //         const list = [...itemsdetails];
                                                        //         list[index]["descKey"] = index;
                                                        //         setItemsdetails(list);
                                                        //         descRef.current.focus()
                                                        //     }
                                                        // }}
                                                        className={styles.inputFocus}
                                                        disabled
                                                        required
                                                    />
                                                    {error.index === index && <div className='fs_10 text_danger'>{error.dp}</div>}
                                                </td>
                                                <td className={styles.table_th_details}>
                                                    <input
                                                        type="number"
                                                        value={(x.qty * x.price).toFixed(2)}
                                                        disabled
                                                        readOnly
                                                        className={styles.inputFocus}
                                                    />
                                                </td>
                                                <td className={styles.crossIcon} onClick={() => { itemsdetails && (itemsdetails.length > 1) && handleRemoveClick(index) }}><CrossCircleIcon /></td>

                                            </tr>
                                            {(index === x.descKey || x.desc) && <tr>
                                                <td colSpan={6} className={styles.table_th_details}>
                                                    <input
                                                        type={"text"}
                                                        className={styles.inputFocus}
                                                        style={{ height: "30px", textAlign: "left" }}
                                                        ref={descRef}
                                                        name="desc"
                                                        autoFocus
                                                        value={x.desc}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                if (!x.desc) {
                                                                    const list = [...itemsdetails];
                                                                    list[index]["descKey"] = "";
                                                                    setItemsdetails(list);
                                                                }
                                                                e.preventDefault()
                                                                handleAddClick()
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            }

                                        </tbody>
                                    )}
                                </table>

                                <div className='text_center'>
                                    <WhiteOutButton btnType="button" handleSubmit={() => handleAddClick()} title="Add More Item" />
                                </div>

                                <div className={styles.d_flex}>
                                    <div className={styles.addRecipt_div}>
                                        <div className={styles.addRecipt_btn_div}>
                                            {payStatus &&
                                                <button
                                                    type='button'
                                                    className={styles.addRecipt_btn}
                                                    onClick={() => {
                                                        if (!orderId) {
                                                            alert("Please Create Voucher Before")
                                                        } else {
                                                            setShowPaymentModal(true)
                                                            setReciptData(prevState => ({
                                                                ...prevState,
                                                                paymentMethod: 'Cash',
                                                                paymentType: '',
                                                                paymentAmount: '',
                                                                transactionId: '',
                                                                accountNumber: '',
                                                                transactionScreenshot: '',
                                                                receiptId: '',
                                                            }))
                                                        }
                                                    }}
                                                >
                                                    Add Receipts
                                                </button>
                                            }
                                        </div>
                                        <div className={styles.receipt_scroll}>
                                            {
                                                reciptsInfo && reciptsInfo.map((item, index) =>
                                                    <div key={index} className={styles.payment_detailsDiv} onClick={() => {
                                                        setReciptData(item); setShowPaymentModal(true)
                                                    }}>
                                                        <span>Receipt Id : <b>{item.receiptId}</b></span>
                                                        <span>Method : <b>{item.paymentMethod}</b></span>
                                                        <span>Amount : <b>{item.paymentAmount}</b></span>
                                                        <span
                                                            className={styles.recipt_statusWidth}>
                                                            Status :
                                                            <b role='button'
                                                                className={item.status.includes('Pending') ? styles.recipt_status_pending :
                                                                    item.status === 'Cancelled' ? styles.recipt_status_red :
                                                                        styles.recipt_status_green}>
                                                                <u>{item.status}</u>
                                                            </b>
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {/* <div className={styles.payment_detailsDiv}>
                                                        <span>Recipt Id : <b>111</b></span>
                                                        <span>Method : <b>Pay Now</b></span>
                                                        <span>Amount : <b>1100</b></span>
                                                        <span className={styles.recipt_statusWidth}>Status : <b className={styles.recipt_status_red}><u>Pending for Verification</u></b></span>
                                                    </div>
                                                    <div className={styles.payment_detailsDiv}>
                                                        <span>Recipt Id : <b>111</b></span>
                                                        <span>Method : <b>Pay Now</b></span>
                                                        <span>Amount : <b>1100</b></span>
                                                        <span className={styles.recipt_statusWidth}>Status : <b className={styles.recipt_status_red}><u>COD Pending</u></b></span>
                                                    </div>
                                                    <div className={styles.payment_detailsDiv}>
                                                        <span>Recipt Id : <b>111</b></span>
                                                        <span>Method : <b>Pay Now</b></span>
                                                        <span>Amount : <b>1100</b></span>
                                                        <span className={styles.recipt_statusWidth}>Status : <b className={styles.recipt_status_red}><u>Cancel</u></b></span>
                                                    </div>
                                                    <div className={styles.payment_detailsDiv}>
                                                        <span>Recipt Id : <b>111</b></span>
                                                        <span>Method : <b>Pay Now</b></span>
                                                        <span>Amount : <b>1100</b></span>
                                                        <span className={styles.recipt_statusWidth}>Status : <b className={styles.recipt_status_green}><u>Received & Refund</u></b></span>
                                                    </div> */}
                                        {/* <div className={styles.paymentMethod_cod_div}>
                                                        <div className={styles.payment_cod}>
                                                            <span className={styles.payment}>Payment Method</span>

                                                            <select
                                                                className={styles.cod}
                                                                ref={paymentRef}
                                                                required
                                                                value={generalInfo.paymentMethod}
                                                                onChange={(e) => {
                                                                    setGeneralInfo(prevState =>
                                                                    ({
                                                                        ...prevState,
                                                                        paymentMethod: e.target.value
                                                                    }));
                                                                    if (e.target.value === "COD") {
                                                                        setGeneralInfo(prevState =>
                                                                        ({
                                                                            ...prevState,
                                                                            paymentType: "Transfer",
                                                                        }))
                                                                    }
                                                                    else if (e.target.value === "Any Transfer Photo or Text") {
                                                                        setGeneralInfo(prevState =>
                                                                        ({
                                                                            ...prevState,
                                                                            paymentType: "Screenshot",
                                                                        }))
                                                                    }
                                                                    else if (e.target.value === "Cash") {
                                                                        setGeneralInfo(prevState =>
                                                                        ({
                                                                            ...prevState,
                                                                            paymentType: "Cash",
                                                                        }))
                                                                    }
                                                                }}
                                                            >
                                                                <option value="" hidden>Select</option>
                                                                <option value="Pay Now">Pay Now</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="COD">COD</option>
                                                                <option value="Any Transfer Photo or Text">Any Transfer Photo or Text</option>
                                                            </select>
                                                        </div>
                                                        <div className={styles.status_received}>
                                                            <span className={styles.status}>Status</span>
                                                            <span
                                                                className={generalInfo.paymentStatus === "Pending For Verification" ?
                                                                    styles.paymentPending : styles.recieved
                                                                }
                                                                onClick={() => {
                                                                    setGeneralInfo(prevState =>
                                                                    ({
                                                                        ...prevState,
                                                                        paymentMethod: "Pending For Verification"
                                                                    }));
                                                                    setShowCODModal(true)
                                                                }}
                                                            >
                                                                {generalInfo.paymentStatus}
                                                            </span>
                                                        </div>
                                                    </div> */}
                                        {/* <div className={styles.addPayment_div}>
                                                        <button
                                                            type='button'
                                                            className={styles.addPament_btn}
                                                            onClick={() => {
                                                                if (generalInfo.paymentMethod !== "Pay Now") {
                                                                    setShowCODModal(true)
                                                                }
                                                            }}
                                                        >Add Payment</button>
                                                    </div> */}
                                    </div>

                                    <div className={styles.subTotal_flex_col}>
                                        <div className={styles.subTotal_div}>
                                            <span>Sub Total</span>
                                            <span>{subtotal.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                        </div>
                                        <div className={styles.subTotal_div}>
                                            <span>Shipping Charges</span>
                                            <div className='w-50 d-flex justify-content-between align-items-center'>
                                                <input
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    className={styles.shipingCharges_input}
                                                    value={generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge)}
                                                    // value={generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : generalInfo.shippingCharge)}
                                                    onChange={(e) => setGeneralInfo(prevState => ({
                                                        ...prevState,
                                                        shippingCharge: e.target.value
                                                    }))}
                                                    maxLength="10"
                                                    onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                                />
                                                <span className='d-flex justify-content-between align-items-center'>
                                                    To Pay&nbsp;&nbsp;
                                                    <input
                                                        type='checkbox'
                                                        className={styles.toPay_checkbox}
                                                        value={generalInfo.toPay}
                                                        onChange={(e) => setGeneralInfo(prevState => ({
                                                            ...prevState,
                                                            toPay: e.target.checked
                                                        }))}
                                                        checked={generalInfo.toPay}
                                                        disabled={generalInfo.deliveryPartner === 'Self' ? true : false}
                                                    />
                                                </span>

                                            </div>
                                            <span className={styles.fixSize_input}>{generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0))}</span>
                                            {/* <span className={styles.fixSize_input}>{generalInfo.deliveryPartner === 'Self' ? 0 : (generalInfo.deliveryCharge ? generalInfo.deliveryCharge : (generalInfo.shippingCharge ? generalInfo.shippingCharge : 0))}</span> */}
                                        </div>
                                        <div className={styles.subTotal_div}>
                                            <input
                                                type="text"
                                                className={styles.adjustment}
                                                value={generalInfo.manageName}
                                                placeholder="Enter Manage"
                                                onChange={(e) => setGeneralInfo(prevState => ({
                                                    ...prevState,
                                                    manageName: e.target.value,
                                                }))}
                                                required={generalInfo.manageValue ? true : false}
                                            />
                                            <div className='w-50'>
                                                <input
                                                    type="number"
                                                    className={styles.shipingCharges_input}
                                                    value={generalInfo.manageValue}
                                                    name='manageValue'
                                                    onChange={(e) => {
                                                        setGeneralInfo(prevState => ({
                                                            ...prevState,
                                                            manageValue: e.target.value
                                                        }))
                                                    }}
                                                    maxLength="10"
                                                    onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                                    required={generalInfo.manageName ? true : false}
                                                    onWheel={(e) => e.target.blur()}
                                                />
                                            </div>
                                            <span className={styles.subTotal_txtRed}>
                                                {generalInfo.manageValue ? generalInfo.manageValue : 0}
                                            </span>
                                        </div>

                                        <div className={styles.subTotal_div}>
                                            <span>Round Off</span>
                                            <span className={styles.fixSize_input}>
                                                {roundedAmt}</span>
                                        </div>

                                        <div className={styles.totalINR_div}>
                                            <span>Total (INR)</span>
                                            <span>{totalAmount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</span>
                                        </div>
                                    </div>

                                </div>

                                <div className={styles.orders_btn_div}>
                                    <RedOutButton btnType="button" title={'BACK'} handleSubmit={() => window.history.back()} />
                                    {typeStatus &&
                                        <GreenOutButton
                                            btnType="button"
                                            title="SAVE AS DRAFT"
                                            handleSubmit={() => handleSaleOrder("draft")}
                                        />
                                    }
                                    {/* {orderId && generalInfo.status !== "Cancelled" && (generalInfo.dispatchStatus === "In Progress" || generalInfo.dispatchStatus === "Packed") && generalInfo.reqStatus !== "Pending" && generalInfo.reqStatus !== "Decline" &&
                                        <GreenOutButton
                                            btnType="button"
                                            css={styles.editRequestButton}
                                            title="REQUEST FOR EDIT"
                                            handleSubmit={() => { setShowRequestForEdit(true) }}
                                        />
                                    } */}
                                    {orderId && generalInfo.reqType !== "Request For Cancel" && (generalInfo.dispatchStatus !== "New Order") && generalInfo.dispatchStatus !== null ?
                                        <RedOutButton
                                            btnType="button"
                                            css='px-2'
                                            title="REQUEST FOR CANCEL"
                                            handleSubmit={() => { setShowRequestForCancel(true) }}
                                        />
                                        :
                                        orderId && !method && generalInfo.status !== "Cancelled" && generalInfo.reqType !== "Request For Cancel" && generalInfo.status !== "Dispatched" &&
                                        <GreenOutButton
                                            btnType="button"
                                            title="CANCEL ORDER"
                                            css={styles.editRequestButton}
                                            handleSubmit={() => { setShowCancelOrderModal(true) }}
                                        />
                                    }
                                    {/* {orderId && !method && generalInfo.status !== "Cancelled" && generalInfo.status !== "Dispatched" && <GreenOutButton
                                        btnType="button"
                                        title="CANCEL ORDER"
                                        handleSubmit={() => { setShowCancelOrderModal(true) }}
                                    />
                                    } */}
                                    {!typeStatus ?
                                        <>
                                            {(generalInfo.status !== "Edit request Approved") &&
                                                <GreenButton
                                                    btnType="button"
                                                    title="VIEW INOVICE"
                                                    handleSubmit={() => navigate("/orders/saleinvoice?orderId=" + orderId)}
                                                />}
                                        </>
                                        :
                                        <>
                                            {(!orderId && nextBtn) ?
                                                <GreenButton
                                                    disabled={loadingBtn ? true : false}
                                                    btnType="submit"
                                                    title={loadingBtn ?
                                                        <Spinner size="15" /> :
                                                        generalInfo.status === "Payment Pending"
                                                            ? "UPDATE & SEND" : "SAVE & SEND"}
                                                />
                                                :
                                                (orderId && !nextBtn) ?
                                                    <GreenButton
                                                        disabled={loadingBtn ? true : false}
                                                        btnType="button"
                                                        title={loadingBtn ? <Spinner size="15" /> : "UPDATE & NEXT"}
                                                        handleSubmit={() => handleNextButtonClick()}
                                                    // {if (generalInfo.deliveryPartner !== 'Self' && generalInfo.partnerName !== 'Self') { orderDeliveryMethod()} else { setNextBtn(true) }}
                                                    />

                                                    : (orderId && nextBtn) ?

                                                        <GreenButton
                                                            disabled={loadingBtn ? true : false}
                                                            btnType="submit"
                                                            title={loadingBtn ?
                                                                <Spinner size="15" /> :
                                                                generalInfo.status === "Payment Pending"
                                                                    ? "UPDATE & SEND" : "SAVE & SEND"}
                                                        />
                                                        :
                                                        <GreenButton
                                                            disabled={loadingBtn ? true : false}
                                                            btnType="button"
                                                            title={loadingBtn ? <Spinner size="15" /> : "NEXT"}
                                                            handleSubmit={() => handleNextButtonClick()}
                                                        />
                                            }
                                        </>
                                    }
                                    {/* {console.log('nextBtn', generalInfo.status)} */}

                                    {((generalInfo.status === "Edit request Approved") && !nextBtn) ?
                                        <GreenButton
                                            disabled={loadingBtn ? true : false}
                                            btnType="button"
                                            title={loadingBtn ? <Spinner size="15" /> : "UPDATE & NEXT"}
                                            handleSubmit={() => handleNextButtonClick()}
                                        // {if (generalInfo.deliveryPartner !== 'Self' && generalInfo.partnerName !== 'Self') { orderDeliveryMethod()} else { setNextBtn(true) }}
                                        />
                                        :
                                        ((generalInfo.status === "Edit request Approved") && nextBtn) ?
                                            <GreenButton
                                                disabled={loadingBtn ? true : false}
                                                btnType="submit"
                                                title={loadingBtn ?
                                                    <Spinner size="15" /> :
                                                    generalInfo.status === "Payment Pending"
                                                        ? "UPDATE & SEND" : "SAVE & SEND"}
                                            />
                                            : ''
                                    }

                                    {orderId && generalInfo.status !== 'Cancelled' && generalInfo.reqType !== "Request For Cancel" &&
                                        <WhiteOutButton
                                            btnType="button"
                                            title='SEND LINK'
                                            handleSubmit={() => { handleSendLink() }}
                                        />}

                                    {generalInfo.status === 'Dispatched' && <Link to={'/viewdispatchdetails/' + orderId + '&saleorder'} className={styles.viewDetail_btn}>View Dispatch Details</Link>}

                                </div>

                            </form>
                    }
                    <HelpButton
                        helpData={helpData}
                        setHelpData={setHelpData}
                    />
                </div>
            </div>

            <Modal
                show={showCODModal}
                close={setShowCODModal}
                closeBtn={true}
                heading=" "
                content={
                    <div className={styles.text_center}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            setShowCODModal(false)
                        }} className={styles.addItem_Container}>

                            {generalInfo.paymentMethod !== "Pending For Verification" ?
                                <>
                                    <h2 className={styles.modalMain_heading}>Payment Method </h2>
                                    <div className={styles.uploadRecieve_div}>
                                        <label>Upload Recieving</label>
                                        <select
                                            className={styles.selectBox_payment}
                                            value={generalInfo.paymentType}
                                            onChange={(e) => {
                                                setGeneralInfo(prevState =>
                                                ({
                                                    ...prevState,
                                                    paymentType: e.target.value,
                                                }))
                                            }}
                                        >
                                            {generalInfo.paymentMethod === "COD" ?
                                                <>
                                                    <option value={""} hidden>Select</option>
                                                    <option >Transfer</option>
                                                    <option >Cash</option>
                                                </>
                                                : generalInfo.paymentMethod === "Cash" ?
                                                    <option hidden>Cash</option>
                                                    :
                                                    <>
                                                        <option value={""} hidden>Select</option>
                                                        <option  >Screenshot</option>
                                                        <option >Transaction Id</option>
                                                    </>

                                            }
                                        </select>

                                    </div>
                                    {generalInfo.paymentType === "Transfer" || generalInfo.paymentType === "Screenshot" ?
                                        <div className={styles.uploadImage_div}>
                                            {generalInfo.transactionScreenshot ?
                                                <div className={styles.demoImage_div}>
                                                    {generalInfo.transactionScreenshot.name ?

                                                        <img src={URL.createObjectURL(generalInfo.transactionScreenshot)}
                                                            alt="" className={styles.modalItem_Image}
                                                        />
                                                        :
                                                        <img src={process.env.REACT_APP_S3URL + generalInfo.transactionScreenshot}
                                                            alt="" className={styles.modalItem_Image}
                                                        />
                                                    }
                                                </div> :
                                                <div className={styles.demoImage_div}>
                                                    <div className={styles.demoImage_bgc}>
                                                        <DemoImageIcon />
                                                    </div>
                                                </div>
                                            }
                                            <label className={styles.uploadImage_btn} >
                                                <input
                                                    name=""
                                                    type="file"
                                                    hidden
                                                    onChange={(e) => {
                                                        setGeneralInfo(prevState => ({
                                                            ...prevState,
                                                            transactionScreenshot: e.target.files[0]
                                                        }))
                                                    }}
                                                />
                                                <UpLoadFile />&nbsp;&nbsp;Upload Screenshot
                                            </label>
                                            <div className={styles.delete_div} onClick={() => { }}>
                                                <span><DeleteIcon /></span>
                                                <span>Delete</span>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {generalInfo.paymentType === "Transaction Id" &&
                                                <input
                                                    type="text"
                                                    placeholder="Enter Text Message/Transaction Id of money transfer"
                                                    className={styles.inputField_enterId}
                                                    value={generalInfo.transferTransId}
                                                    onChange={e => setGeneralInfo(prevState => ({
                                                        ...prevState,
                                                        transferTransId: e.target.value
                                                    }))}
                                                />
                                            }
                                        </>
                                    }
                                </>
                                :
                                <div className={styles.text_center}>
                                    {
                                        generalInfo.viewScreenshot === "viewImage" ?
                                            <div className={styles.modalButton_cod}>
                                                <img src="/assets/viewImage.png" alt="payment_image" className={styles.viewImage} />

                                                <GreenOutButton btnType="button" title="BACK"
                                                    handleSubmit={() => {
                                                        setGeneralInfo(prevState => ({ ...prevState, viewScreenshot: "" }))
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className={styles.addItem_Container}>
                                                <h2 className={styles.modalMain_heading}>Verify Payment</h2>
                                                <div className={styles.payment_image_div}
                                                    onClick={() => {
                                                        setGeneralInfo(prevState => ({
                                                            ...prevState,
                                                            viewScreenshot: "viewImage"
                                                        }))
                                                    }}>
                                                    <img src={process.env.REACT_APP_URL + generalInfo.transactionScreenshot} alt="payment_image" className={styles.payment_image} />
                                                    <label className={styles.viewScreenshot}>*Click to View Screenshot</label>
                                                </div>
                                            </div>
                                    }
                                </div>
                            }
                            {generalInfo.viewScreenshot !== "viewImage" &&
                                <>
                                    <input
                                        type="text"
                                        placeholder="Enter Full Amount (in Rs)"
                                        className={styles.inputField_enterAmount}
                                        value={generalInfo.paymentAmount}
                                        onChange={e =>
                                            setGeneralInfo(prevState => ({
                                                ...prevState,
                                                paymentAmount: e.target.value
                                            }))
                                        }
                                        required
                                    />
                                    <p className={styles.amountErr}>{parseFloat(totalAmount).toFixed(2) !== parseFloat(generalInfo.paymentAmount).toFixed(2) ? "Please Enter Same Amount Of Total Amount" : ""}</p>
                                    <div className={styles.modalButton_cod}>
                                        <GreenButton btnType="submit" title="APPROVE" handleSubmit={() => {

                                        }} />
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                }
            />

            <Modal
                show={showCancelOrderModal}
                close={setShowCancelOrderModal}
                closeBtn={true}
                heading="CANCEL ORDER"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>Are You Sure Want To Cancel This Order</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="NO" handleSubmit={() => setShowCancelOrderModal(false)} />
                            <GreenButton title="YES" handleSubmit={() => { handleCancelOrder(); }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showRequestForEdit}
                close={setShowRequestForEdit}
                closeBtn={true}
                heading="REQUEST FOR EDIT"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>Are You Sure Want To Edit This Order</p>
                        <div className='d-flex justify-content-around w-100 mx-auto mb-3'>
                            <div className='d-flex'>
                                <div
                                    role='button'
                                    className='border rounded p-1 d-flex justify-content-center align-items-center me-2'
                                    onClick={() => { setEditKey('Add On') }}
                                >
                                    {editKey === 'Add On' ? (
                                        <CheckGreenIcon />
                                    ) : (
                                        <span className='p-2'></span>
                                    )}
                                </div>
                                <label>Add On</label>
                            </div>
                            <div className='d-flex'>
                                <div
                                    role='button'
                                    className='border rounded p-1 d-flex justify-content-center align-items-center me-2'
                                    onClick={() => { setEditKey('Remove') }}
                                >
                                    {editKey === 'Remove' ? (
                                        <CheckGreenIcon />
                                    ) : (
                                        <span className='p-2'></span>
                                    )}
                                </div>
                                <label>Remove</label>
                            </div>
                            <div className='d-flex'>
                                <div
                                    role='button'
                                    className='border rounded p-1 d-flex justify-content-center align-items-center me-2'
                                    onClick={() => { setEditKey('Add and Remove') }}
                                >
                                    {editKey === 'Add and Remove' ? (
                                        <CheckGreenIcon />
                                    ) : (
                                        <span className='p-2'></span>
                                    )}
                                </div>
                                <label>Add & Remove</label>
                            </div>
                        </div>
                        <p className='text-center text-danger'>{editKey === 'Add On' ? 'Add On' : editKey === 'Remove' ? 'Remove' : editKey === 'Add and Remove' ? 'Add and Remove' : ''}</p>
                        <textarea type="text" placeholder='Enter Issue' className={styles.reqIssue} value={reqIssue} onChange={(e) => setReqIssue(e.target.value)} />
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowRequestForEdit(false)} />
                            <GreenButton title="SUBMIT" handleSubmit={() => { handleRequestForEdit() }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showRequestForCancel}
                close={setShowRequestForCancel}
                closeBtn={true}
                heading="REQUEST FOR CANCEL"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>Are You Sure Want To Cancel This Order</p>
                        <textarea type="text" placeholder='Enter Issue' className={styles.reqIssue} value={reqIssue} onChange={(e) => setReqIssue(e.target.value)} />
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowRequestForCancel(false)} />
                            <GreenButton title="SUBMIT" handleSubmit={() => { handleRequestForCancel() }} />
                        </div>
                    </div>
                }
            />

            <PaymentModal
                show={showPaymentModal}
                close={setShowPaymentModal}
                handleRecipt={handleRecipt}
                setReciptData={setReciptData}
                reciptData={reciptData}
                isImage={isImage}
                setisImage={setisImage}
                totalAmount={totalAmount}
                accounts={accounts}
                setLoadingReceipt={setLoadingReceipt}
                loadingReceipt={loadingReceipt}
                reciptsInfo={reciptsInfo}
            />

        </React.Fragment>
    )
}

export default SaleOrder;


export const AddDealerModal = ({ setGeneralInfo }) => {
    const { managerId } = useAuth()
    const [showAddDealer, setShowAddDealer] = useState(true)
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [msgShow, setMsgShow] = useState("");
    const [addNewDealerData, setAddNewDealerData] = useState({
        name: '',
        designation: '',
        storeName: '',
        mobile: '',
        pincode: '',
        address: '',
        city: '',
        state: '',
        gst: '',
    })
    const handleSubmitAddDealer = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleAddDealerApi()
        }
    };

    useEffect(() => {
        if (addNewDealerData.pincode.length === 6) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "keyword": addNewDealerData.pincode,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setAddNewDealerData(prevState => ({
                            ...prevState,
                            city: result.districts[0] ? result.districts[0] : "",
                            state: result.states[0] ? result.states[0] : "",
                        }))
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [addNewDealerData.pincode])

    const handleAddDealerApi = () => {
        setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "mobile": addNewDealerData.mobile,
            "refcode": managerId,
            "name": addNewDealerData.name,
            "street": addNewDealerData.address,
            "pincode": addNewDealerData.pincode,
            "city": addNewDealerData.city,
            "state": addNewDealerData.state,
            "designation": addNewDealerData.designation,
            "gstuin": addNewDealerData.gst,
            "storeName": addNewDealerData.storeName,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "order/addDealer", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setGeneralInfo(prevState => ({
                        ...prevState,
                        dealerId: result.data.dealerId,
                        mobile: result.data.mobile,
                        name: result.data.name,
                        pincode: result.data.pincode,
                        street: result.data.street,
                        city: result.data.city,
                        state: result.data.state,
                    }))
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                    setShowAddDealer(false)
                }
                else {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                }
            })
            .catch(error => console.log('error', error));
    }
    return (
        <React.Fragment>
            <Modal
                show={showAddDealer}
                close={setShowAddDealer}
                closeBtn={true}
                heading=" "
                content={
                    <div className={styles.text_center}>
                        <div className={styles.addItem_Container}>
                            <h2 className={styles.modalMain_heading}>Add Dealer</h2>
                            <form onSubmit={handleSubmitAddDealer}>
                                <div className={styles.nameAndDesignation}>
                                    <div className={styles.inputFieldName}>
                                        <label className={styles.inputFields_heading}>Name</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Name"
                                            value={addNewDealerData.name}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, name: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                                            required
                                        />
                                    </div>

                                    <div className={styles.DesignationFields_div}>
                                        <label className={styles.inputFields_heading}>Designation</label>
                                        <select
                                            className={styles.inputField_designation}
                                            value={addNewDealerData.designation}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, designation: e.target.value })}
                                            required
                                        >
                                            <option value="">Select Designation</option>
                                            <option value="Interior Designer">Interior Designer</option>
                                            <option value="Store Designer">Store Owner</option>
                                            <option value="Contractor">Contractor</option>
                                            <option value="Architect">Architect</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                </div>

                                <div className={styles.nameAndDesignation}>
                                    <div className={styles.inputFieldName}>
                                        <label className={styles.inputFields_heading}>Store Name</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Store Name"
                                            value={addNewDealerData.storeName}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, storeName: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                                            required
                                        />
                                    </div>
                                    <div className={styles.DesignationFields_div}>
                                        <label className={styles.inputFields_heading}>Mobile Number</label>
                                        <input
                                            type="Number"
                                            className={styles.inputField_name}
                                            placeholder="Enter Mobile Number"
                                            value={addNewDealerData.mobile}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, mobile: e.target.value })}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className={styles.nameAndDesignation}>
                                    <div className={styles.inputFieldName}>
                                        <label className={styles.inputFields_heading}>Street Address</label>
                                        <textarea
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Street Address"
                                            rows="2"
                                            value={addNewDealerData.address}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, address: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className={styles.nameAndDesignation}>
                                    <div className={styles.inputFieldName}>
                                        <label className={styles.inputFields_heading}>Pincode</label>
                                        <input
                                            type="Number"
                                            className={styles.inputField_name}
                                            placeholder="Enter Pincode"
                                            value={addNewDealerData.pincode}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, pincode: e.target.value })}
                                            maxLength="6"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                            required
                                        />
                                    </div>

                                    <div className={styles.DesignationFields_div}>
                                        <label className={styles.inputFields_heading}>City</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter City"
                                            value={addNewDealerData.city}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, city: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className={styles.nameAndDesignation}>
                                    <div className={styles.inputFieldName}>
                                        <label className={styles.inputFields_heading}>State</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter State"
                                            value={addNewDealerData.state}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, state: e.target.value.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) })}
                                            required
                                        />
                                    </div>
                                    <div className={styles.DesignationFields_div}>
                                        <label className={styles.inputFields_heading}>GSTUIN <span className={styles.unbold_text}>(optional)</span></label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter GSTUIN Number"
                                            value={addNewDealerData.gst}
                                            onChange={(e) => setAddNewDealerData({ ...addNewDealerData, gst: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.modalButton_div}>
                                    <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => { setShowAddDealer(false) }} />
                                    <GreenButton btnType="submit" title="ADD" />
                                </div>
                            </form>
                        </div>
                    </div >
                }
            />

            < Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading="ADD DEALER STATUS"
                content={
                    < div className={styles.text_center} >
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowAcceptModal(false) }} />
                        </div>
                    </div >
                }
            />
        </React.Fragment >
    )
}

export const AddItemModal = ({ showAddItem, setShowAddItem, setItemsdetails, index }) => {
    const { managerId } = useAuth()
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [msgShow, setMsgShow] = useState("");
    const [file, setFile] = useState();
    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    // const [products, setProducts] = useState([])
    // const [collections, setCollections] = useState([])
    const [addNewItemData, setAddNewItemData] = useState({
        product_name: '',
        product_Id: '',
        collection_name: '',
        collection_id: '',
        itemNo: '',
        unit: '',
        weight: '',
        vm: '',
        description: '',
        products: [],
        collections: [],
    })

    const handleSubmitAddItem = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleAddItemApi()
        }
    };

    const handleAddItemApi = () => {
        setMsgShow("")
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("image", file ? file : "");
        formdata.append("product_Id", addNewItemData.product_Id);
        formdata.append("collection_id", addNewItemData.collection_id);
        formdata.append("product_name", addNewItemData.product_name);
        formdata.append("collection_name", addNewItemData.collection_name);
        formdata.append("unit", addNewItemData.unit);
        formdata.append("price", addNewItemData.price);
        formdata.append("weight", addNewItemData.weight);
        formdata.append("vm", addNewItemData.vm);
        formdata.append("description", addNewItemData.description);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        // fetch(process.env.REACT_APP_URL + `order/addNewItem/${addNewItemData.itemNo}`, requestOptions)
        fetch(process.env.REACT_APP_URL + `inventory/addUpdateOutsideItem/${managerId}/${addNewItemData.itemNo}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                    setShowAddItem(false)
                    setItemsdetails(prevState => ([
                        ...prevState.slice(0, index),
                        {
                            itemNo: result.data.itemNo,
                            product_name: result.data.product_name,
                            unit: result.data.unit,
                            price: result.data.price,
                            productimg: result.data.productimg,
                        },
                        ...prevState.slice(index + 1)
                    ]))
                }
                else {
                    setMsgShow(result.msg)
                    setShowAcceptModal(true)
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if (managerId) {
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "product_name": addNewItemData.product_name.toUpperCase()
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "order/productsColl", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "success") {
                        setAddNewItemData(prevState => ({
                            ...prevState,
                            products: result.data.products ? result.data.products : [],
                            collections: result.data.collections ? result.data.collections : [],
                        }))
                    }
                    // setproducts(result.data.products)
                }
                )
                .catch(error => console.log('error', error));
        }
    }, [managerId, addNewItemData.product_name])

    return (
        <React.Fragment>
            <Modal
                show={showAddItem}
                close={setShowAddItem}
                closeBtn={true}
                heading=" "
                content={
                    <div className={styles.text_center}>
                        <div className={styles.addItem_Container}>
                            <h2 className={styles.modalMain_heading}>Add New Item</h2>
                            <form onSubmit={handleSubmitAddItem}>
                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Product Name</label>
                                        {/*<select
                                            className={styles.inputField_name}
                                            value={addNewItemData.product_id}
                                            onChange={(e) => setAddNewItemData({
                                                ...addNewItemData,
                                                product_id: e.target.value,
                                            })}
                                            required
                                        >
                                            <option value="" hidden>Select Your Product Name</option>
                                            {addNewItemData.products && addNewItemData.products.map((item, index) =>
                                                <option key={index} value={item._id} >{item.product_name}</option>
                                            )}
                                        </select> */}

                                        <input type="text" list="prodcuts"
                                            className={styles.inputField_name}
                                            value={addNewItemData.product_name}
                                            onChange={(e) => setAddNewItemData({
                                                ...addNewItemData,
                                                product_name: e.target.value.toUpperCase(),
                                            })}
                                            autoFocus
                                        />
                                        <datalist id="prodcuts">
                                            {addNewItemData.products && addNewItemData.products.map((item, index) =>
                                                <option key={index} >{item.product_name}</option>
                                            )}
                                        </datalist>
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Collection Name</label>
                                        {/* <select
                                            className={styles.inputField_name}
                                            value={addNewItemData.collection_id}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, collection_id: e.target.value })}
                                            required
                                        >
                                            <option value="" hidden>Enter Your Collection Name</option>
                                            {addNewItemData.collections && addNewItemData.collections.map((item, index) =>
                                                <option key={index} value={item._id}>{item.collection_name}</option>
                                            )}
                                        </select> */}
                                        <input type="text" list="collections"
                                            className={styles.inputField_name}
                                            value={addNewItemData.collection_name}
                                            onChange={(e) => setAddNewItemData({
                                                ...addNewItemData,
                                                collection_name: e.target.value.toUpperCase(),
                                            })}
                                            required
                                        />
                                        <datalist id="collections">
                                            {addNewItemData.collections && addNewItemData.collections.map((item, index) =>
                                                <option key={index} >{item.collection_name}</option>
                                            )}
                                        </datalist>
                                    </div>
                                </div>
                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Item Number</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Item Number"
                                            value={addNewItemData.itemNo}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, itemNo: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Unit</label>
                                        {/* <select
                                            className={styles.inputField_name}
                                            value={addNewItemData.unit}
                                            required
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, unit: e.target.value })}
                                        >
                                            <option value="" hidden>Select Unit</option>
                                            <option value="Roll" >Roll</option>
                                            <option value="Pcs">Pcs</option>
                                            <option value="Box">Box</option>
                                            <option value="Sq Meter">Sq Meter</option>
                                            <option value="Meter">Meter</option>
                                            <option value="Sq Feet">Sq Feet</option>
                                            <option value="Feet">Feet</option>
                                        </select> */}
                                        <input type="text" list="units"
                                            className={styles.inputField_name}
                                            value={addNewItemData.unit}
                                            onChange={(e) => setAddNewItemData({
                                                ...addNewItemData,
                                                unit: e.target.value,
                                            })}
                                            required
                                        />
                                        <datalist id="units">
                                            <option value="Roll" >Roll</option>
                                            <option value="Pcs">Pcs</option>
                                            <option value="Box">Box</option>
                                            <option value="Sq Meter">Sq Meter</option>
                                            <option value="Meter">Meter</option>
                                            <option value="Sq Feet">Sq Feet</option>
                                            <option value="Feet">Feet</option>
                                            <option value="Kg">Kg</option>
                                        </datalist>
                                    </div>
                                </div>
                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Price</label>
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            className={styles.inputField_name}
                                            placeholder="Enter Price"
                                            value={addNewItemData.price}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, price: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Weight</label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Weight"
                                            value={addNewItemData.weight}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, weight: e.target.value })}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className={styles.orderInput_div}>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Volume (CBM)</label>
                                        <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            className={styles.inputField_name}
                                            placeholder="Enter Volume"
                                            value={addNewItemData.vm}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, vm: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className={styles.inputFields_div}>
                                        <label className={styles.inputFields_heading}>Description&nbsp;<span className={styles.unbold_text}>(optional)</span></label>
                                        <input
                                            type="text"
                                            className={styles.inputField_name}
                                            placeholder="Enter Description"
                                            value={addNewItemData.description}
                                            onChange={(e) => setAddNewItemData({ ...addNewItemData, description: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className={styles.uploadImage_div}>
                                    <label onChange={handleChange} className={styles.uploadImage_btn}>
                                        <input name="" type="file" id="formId" hidden />
                                        <UpLoadFile />&nbsp;&nbsp;Upload Image
                                    </label>
                                    {file ?
                                        <div className={styles.demoImage_item}> <img src={file} alt="" className={styles.modalItem_Image} /></div> :
                                        <div className={styles.demoImage_item}>
                                            <div className={styles.demoImage_bgc}>
                                                <DemoImageIcon />
                                            </div>
                                        </div>
                                    }
                                    <div className={styles.delete_div} onClick={() => setFile()}>
                                        <span><DeleteIcon /></span>
                                        <span>Delete</span>
                                    </div>
                                </div>
                                <div className={styles.modalButton_div}>
                                    <RedOutButton title="CANCEL" handleSubmit={() => { setShowAddItem(false) }} />
                                    <GreenButton btnType="submit" title="ADD" handleSubmit={() => { }} />
                                </div>
                            </form>
                        </div>
                    </div>
                }
            />

            <Modal
                show={showAcceptModal}
                close={setShowAcceptModal}
                closeBtn={true}
                heading="OUTSIDE ITEM STATUS"
                content={
                    <div className={styles.text_center}>
                        <p className={styles.message}>{msgShow}</p>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
                            <GreenButton title="OK" handleSubmit={() => { setShowAcceptModal(false) }} />
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    )
}