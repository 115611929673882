import React, { createContext, useState } from "react";
import { ToastProvider } from "../updatedComponents/Toaster/Toaster";
const AuthContext = createContext({});

const AuthProvider = (props) => {
  const [switchDisplay, setSwitchDisplay] = useState(false);
  const [marketingRefresh, setMarketingRefresh] = useState(false);
  const [designation, setDesignation] = useState("");
  const [managerId, setManagerId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(0);
  const [totalUnreadChat, setTotalUnreadChat] = useState(0);
  const [mode, setMode] = useState(false);
  const [ isLoading , setIsLoading]= useState(false);

  const authContextValue = {
    switchDisplay,
    setSwitchDisplay,
    marketingRefresh,
    setMarketingRefresh,
    designation,
    setDesignation,
    managerId,
    setManagerId,
    managerName,
    setManagerName,
    loggedIn,
    setLoggedIn,
    updateDelete,
    setUpdateDelete,
    totalUnreadChat,
    setTotalUnreadChat,
    mode,
    setMode,
    isLoading,
    setIsLoading,
  };
  // return <AuthContext.Provider value={authContextValue} {...props} />;
  return (
    <ToastProvider>
      <AuthContext.Provider value={authContextValue} {...props} />
    </ToastProvider>
  );
};

const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };


