import React from "react";
import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";

const RadioButtonGroup = ({ options, selectedOption, onChange }) => {
  const { mode } = useAuth();

  const handleStatusChange = (option) => {
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div className={styles.radioGroup}>
      {options.map((option) => (
        <label key={option.value} className={styles.radioItem}>
          <input
            type="radio"
            name="customRadio"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={() => handleStatusChange(option.value)}
            className={styles.radioInput}
          />
          <div className={styles.radioCircle}></div>
          <span
            className={styles.radioLabel}
            style={{ color: mode ? "#ffffff" : "#646B88" }}
          >
            {option.label}
          </span>
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
