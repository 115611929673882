import { Spinner } from "../../icons/Icon";
import styles from "./DealerStyles.module.css";

const Button = ({
  buttonIcon,
  buttonText,
  buttonBgColor,
  buttonTextColor,
  buttonClick,
  buttonBorder,
  // type,
  buttonFontWeight,
  loading
}) => {
  return (
    <button
      className={styles.button}
      style={{
        backgroundColor: buttonBgColor,
        border: buttonBorder,
        // borderRadius: type === "manager" && "10px",
      }}
      onClick={buttonClick}
    >
      { loading ? null : buttonIcon}
      <p
        style={{
          color: buttonTextColor,
          fontWeight: buttonFontWeight,
        }}
      >
        {loading ? <Spinner size="20"  /> : buttonText}

      </p>
    </button>
  );
};

export default Button;
